* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

* {
    outline: none; }

body {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.01);
    background: white;
    font-size: 62.5%;
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    color: black;
    overflow-y: auto}
@media screen and (min-width: 1025px) {
    body {
        background: white url("../../public/img/bg.jpg") top center no-repeat; } }

a {
    text-decoration: none;
    display: inline-block;
    color: #d33b38;
    cursor: pointer; }
a:hover {
    color: #d74a50; }

button {
    background: none;
    border: none;
    padding: 0; }

.clear {
    clear: both; }
.clear:after {
    content: "";
    display: table;
    clear: both; }

.left {
    float: left; }

.right {
    float: right; }

.half {
    width: 50%; }
@media screen and (max-width: 480px) {
    .half {
        width: 100%; } }


@media screen and (max-width: 480px) {
    #tavel {
        margin-left: 0 !important;
        margin-top:0 !important;
    }
}



.image-responsive img {
    display: block;
    width: 100%; }

.image-responsive a {
    max-width: 100%; }

.visible-tablet {
    display: none; }
@media screen and (max-width: 1024px) {
    .visible-tablet {
        display: inline-block; } }

.visible-phone {
    display: none; }
@media screen and (max-width: 480px) {
    .visible-phone {
        display: inline-block; } }

@media screen and (max-width: 1024px) {
    .hidden-tablet {
        display: none !important; } }

@media screen and (max-width: 480px) {
    .hidden-phone {
        display: none !important; } }

.flex-direction-nav a {
    font-size: 0; }
.flex-direction-nav a:before {
    display: none; }

.slider {
    position: relative;}

.slider-hidden {
    overflow-y: hidden; height: 100px; opacity: 0;}

.slider .slides li {
    float: left; }

.slides {
    list-style: none;
    margin: 0;
    padding: 0; }
.slides .image-responsive a {
    display: block; }

.jspVerticalBar {
    width: 6px;
    border-radius: 2px; }

.jspVerticalBar .jspTrack {
    width: 6px;
    background: #eaecf1;
    border-radius: 2px; }

.jspHorizontalBar {
    height: 6px;
    border-radius: 2px; }

.jspHorizontalBar .jspTrack {
    height: 6px;
    background: #eaecf1;
    border-radius: 2px; }

.jspDrag {
    background: #a3a3a5;
    width: 4px;
    left: 1px; }

nav ul {
    margin: 0;
    padding: 0; }
nav ul li {
    display: inline-block; }
nav ul li a {
    display: inline-block; }

hr {
    border-top: none;
    border-bottom: 1px solid #d4d4d4;
    margin: 10px 0 20px; }

h1, h2, h3, h4, h5, h6 {
    margin: 0; }

input[type="search"] {
    -webkit-appearance: none;
    border-radius: 0; }

.close-btn, .toggle, .toggle-dropdown, .toggle-fade, .toggle-slide, .btn {
    cursor: pointer; }

.toggleable, .dropdownable {
    display: none;
    position: absolute; }
.toggleable.active, .dropdownable.active {
    display: block; }

.toggleable-single {
    display: none; }
.toggleable-single.active {
    display: block; }

.toggleable-hide {
    display: none; }

.wrapper {
    min-width: 1000px; }
@media screen and (max-width: 1024px) {
    .wrapper {
        min-width: 768px; } }
@media screen and (max-width: 480px) {
    .wrapper {
        min-width: 320px; } }

.container {
    width: 1000px;
    margin: auto;
    position: relative;
 }
.container:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 1024px) {
    .container {
        width: 768px; } }
@media screen and (max-width: 480px) {
    .container {
        width: 100%; } }

.block {
    display: block;
    margin-bottom: 40px; }
.block:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .block {
        margin-bottom: 20px; } }

.top-bn {
    text-align: center;
}

.banner-pub-top .pub_ad {
    padding-top: 18px;
}

.top-bn a, .top-bn img {
    max-width: 100%; }

.breadcrumb {
    font-size: 11px !important;
    margin-bottom: 0 !important;
    margin-top: 10px !important;
    padding: 10px 0 20px !important;
    background-color: #ffffff !important;
    margin-left: 1.5em;
}
.breadcrumb li a span {
	color: #333 !important;
}
.breadcrumb>li+li:before {
    color: #333 !important;
    content: "" !important;
}

.desktop-fixed-header {
    background: #252424;
    width: 100%;
    position: fixed;
    top: -70px;
    left: 0;
    z-index: 100;
    padding: 15px 0 10px;
    height: 70px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s; }
.desktop-fixed-header.active {
    top: 0; }
.desktop-fixed-header .container {
    background: none; }
.desktop-fixed-header .logo {
    float: left;
    width: 130px;
    margin-right: 10px; }
.desktop-fixed-header .logo img {
    width: 100%; }
.desktop-fixed-header .menu {
    float: left;
    padding: 10px 0; }
.desktop-fixed-header .menu li:last-child a {
    border: none; }
.desktop-fixed-header .menu a {
    color: #fefdfd;
    padding: 5px 10px;
    border-right: 1px solid #464343;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.1em;
    font-weight: 600; }
.desktop-fixed-header .action {
    float: right; }
.desktop-fixed-header .action a {
    display: block;
    float: right; }
.desktop-fixed-header .action .member {
    padding: 12px 15px 0;
    height: 40px;
    background-color: #4d4a4a;
    background-image: -webkit-linear-gradient(#4d4a4a, #312e2e);
    background-image: linear-gradient(#4d4a4a, #312e2e);
    border-radius: 3px;
    margin-right: 5px; }
.desktop-fixed-header .action .member:hover {
    background: #4d4a4a; }
.desktop-fixed-header .action .search {
    padding: 2px 10px 0 35px;
    height: 40px;
    background-color: #bc4348;
    background-image: -webkit-linear-gradient(#bc4348, #b91e22);
    background-image: linear-gradient(#bc4348, #b91e22);
    color: white;
    font-size: 2.6em;
    font-family: "Grand Hotel", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    border-radius: 3px;
    position: relative; }
.desktop-fixed-header .action .search:hover {
    background: #bc4348; }
.desktop-fixed-header .action .search .icon {
    position: absolute;
    top: 8px;
    left: 10px; }

.overlay-main-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 11;
    display: none; }
@media screen and (max-width: 1024px) {
    .overlay-main-menu.active {
        display: block; } }

.main-header .top-bar {
    background: #272424; }
.main-header .top-bar:after {
    content: "";
    display: table;
    clear: both; }
.main-header .top-bar .social {
    float: right;
    margin-right: 20px;
    padding-top: 3px; }
.main-header .top-bar .social a {
    padding: 5px; }
.main-header .top-bar .social a:hover .icon-facebook-gray {
    background-position: -130px -119px; }
.main-header .top-bar .social a:hover .icon-twitter-gray {
    background-position: -99px -108px; }
.main-header .top-bar .social a:hover .icon-gplus-gray {
    background-position: 0 -154px; }
.main-header .top-bar .social a:hover .icon-youtube-gray {
    background-position: -78px -221px; }
.main-header .top-bar .social .icon-twitter-gray {
    top: -1px; }
.main-header .top-bar .menu {
    float: right;
    margin-right: 10px; }
.main-header .top-bar .menu li {
    position: relative; }
.main-header .top-bar .menu li.active a, .main-header .top-bar .menu li:hover a {
    color: white; }
.main-header .top-bar .menu li:after {
    content: '';
    width: 2px;
    height: 2px;
    border-radius: 100%;
    background: #999;
    position: absolute;
    top: 15px;
    right: -3px; }
.main-header .top-bar .menu li:last-child:after {
    display: none; }
.main-header .top-bar .menu a {
    color: #999999;
    font-weight: bold;
    padding: 10px 5px;
    font-size: 1.1em;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.main-header .menu-bar {
    padding: 20px 14px;
    position: relative; }
.main-header .menu-bar:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (min-width: 481px) {
    .main-header .menu-bar .logo {
        float: left; }
    .main-header .menu-bar .main-menu {
        float: right;
        z-index: 10; }
    .main-header .menu-bar .main-menu li:last-child a {
        border-right: none; }
    .main-header .menu-bar .main-menu a {
        color: #3d3d3d;
        font-weight: bold;
        padding: 0 24px;
        font-size: 1.1em;
        font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        text-align: center;
        border-right: 1px solid #d9dbdc;
        position: relative; }
    .main-header .menu-bar .main-menu a .icon {
        margin-bottom: 8px; }
    .main-header .menu-bar .main-menu a.active {
        color: #d33b38; }
    .main-header .menu-bar .main-menu a.active:after {
        height: 0;
        width: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #272424;
        content: '';
        position: absolute;
        left: 43%;
        bottom: -22px; }
    .main-header .menu-bar .main-menu a.active .icon-3-bottle-black {
        background-position: 0 -208px; }
    .main-header .menu-bar .main-menu a.active .icon-meal-black {
        background-position: -120px -139px; }
    .main-header .menu-bar .main-menu a.active .icon-book-black {
        background-position: -39px -97px; }
    .main-header .menu-bar .main-menu a.active .icon-bag-black {
        background-position: -40px -63px; }
    .main-header .menu-bar .main-menu a.active .icon-grape-black {
        background-position: -126px -210px; }
    .main-header .menu-bar .main-menu a.active .icon-meat-black {
        background-position: -119px -163px; }
    .main-header .menu-bar .main-menu-toggle {
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        border-top: 3px solid #272424;
        z-index: 20;
        box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.5);
        overflow: hidden; }
    .main-header .menu-bar .main-menu-toggle:after {
        content: "";
        display: table;
        clear: both; }
    .main-header .menu-bar .main-menu-toggle:hover {
        display: block; }
    .main-header .menu-bar .main-menu-toggle .title {
        font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-weight: bold;
        font-size: 1.8em;
        color: #282222;
        margin-bottom: 10px;
        margin-top: 20px; }
    .main-header .menu-bar .main-menu-toggle .column {
        width: 33.3333%;
        float: left;
        padding-bottom: 9999px;
        margin-bottom: -9999px; }
    .main-header .menu-bar .main-menu-toggle .column li {
        display: block; }
    .main-header .menu-bar .main-menu-toggle .column-nav {
        background: #f4f4f4;
        border-right: 1px solid #c9c9c9; }
    .main-header .menu-bar .main-menu-toggle .column-nav .title {
        color: #d33b38;
        padding: 0 25px; }
    .main-header .menu-bar .main-menu-toggle .column-nav li.active a {
        background: #e2e1e1; }
    .main-header .menu-bar .main-menu-toggle .column-nav li a {
        font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-weight: bold;
        font-size: 1.4em;
        color: #252424;
        padding: 6px 25px;
        margin-bottom: 5px;
        width: 100%;
        min-height: 40px;
        display: -webkit-box;
        display: -moz-box;
        display: box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        box-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        align-items: center;
        -ms-flex-align: center; }
    .main-header .menu-bar .main-menu-toggle .column-nav li a:hover, .main-header .menu-bar .main-menu-toggle .column-nav li a.active {
        background: #e2e1e1; }
    .main-header .menu-bar .main-menu-toggle .column-info {
        border-right: 1px solid #c9c9c9; }
    .main-header .menu-bar .main-menu-toggle .column-info .title {
        padding: 0 20px; }
    .main-header .menu-bar .main-menu-toggle .column-info li.active a {
        color: #d33b38;
        font-weight: bold; }
    .main-header .menu-bar .main-menu-toggle .column-info li a {
        font-size: 1.5em;
        color: black;
        padding: 0 20px;
        margin-bottom: 10px; }
    .main-header .menu-bar .main-menu-toggle .column-info li a:hover {
        color: #d33b38; }
    .main-header .menu-bar .main-menu-toggle .column-news .title {
        padding: 0 10px; }
    .main-header .menu-bar .main-menu-toggle .column-news li {
        padding: 10px; }
    .main-header .menu-bar .main-menu-toggle .column-news li:after {
        content: "";
        display: table;
        clear: both; }
    .main-header .menu-bar .main-menu-toggle .column-news li .image-responsive {
        width: 100px;
        float: left; }
    .main-header .menu-bar .main-menu-toggle .column-news li .name {
        margin-left: 110px; }
    .main-header .menu-bar .main-menu-toggle .column-news li .name a {
        font-size: 1.6em;
        color: black; }
    .main-header .menu-bar .main-menu-toggle .column-news li .name a:hover {
        color: #d33b38; }
    .main-header .menu-bar .main-menu-toggle .action-info {
        padding: 20px; }
    .main-header .menu-bar .main-menu-toggle .action-info a {
        width: 100%;
        border-radius: 3px;
        background-color: #d94247;
        background-image: -webkit-linear-gradient(#d94247, #d6150c);
        background-image: linear-gradient(#d94247, #d6150c);
        padding: 10px 10px 10px 45px;
        position: relative;
        color: white;
        font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-size: 1.3em;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
    .main-header .menu-bar .main-menu-toggle .action-info a:hover {
        background: #d94247;
        box-shadow: none; }
    .main-header .menu-bar .main-menu-toggle .action-info a .icon {
        position: absolute;
        top: 10px;
        left: 5px; }
    .main-header .menu-bar .main-menu-toggle .action-news {
        padding: 10px;
        margin-top: 20px; }
    .main-header .menu-bar .main-menu-toggle .action-news .action-inner {
        position: relative;
        border-bottom: 1px solid #d4d4d4; }
    .main-header .menu-bar .main-menu-toggle .action-news a {
        position: absolute;
        padding: 0 10px;
        background: white;
        top: -10px;
        right: 0;
        font-weight: bold;
        font-style: italic;
        font-size: 1.2em;
        color: black; } }
@media screen and (max-width: 1024px) {
    .main-header .menu-bar {
        padding: 0; }
    .main-header .menu-bar .top-bar-tablet {
        background: #272424;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-width: 768px;
        z-index: 100; }
    .main-header .menu-bar .top-bar-tablet:after {
        content: "";
        display: table;
        clear: both; }
    .main-header .menu-bar .top-bar-tablet .container {
        background: none;
        padding: 10px 15px; }
    .main-header .menu-bar .top-bar-tablet a {
        color: white; }
    .main-header .menu-bar .top-bar-tablet .logo {
        display: inline-block;
        width: 120px;
        float: left; }
    .main-header .menu-bar .top-bar-tablet .nav {
        border-right: 1px solid #3a3838;
        height: 45px;
        float: left; }
    .main-header .menu-bar .top-bar-tablet .nav-menu {
        width: 150px;
        font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-size: 1.8em;
        font-weight: bold;
        padding: 10px 0 0 55px;
        position: relative; }
    .main-header .menu-bar .top-bar-tablet .nav-menu.active .icon {
        width: 19px;
        height: 19px;
        background-position: -100px -124px;
        left: 27px; }
    .main-header .menu-bar .top-bar-tablet .nav-menu .icon {
        position: absolute;
        top: 11px;
        left: 25px; }
    .main-header .menu-bar .top-bar-tablet .nav-member {
        width: 130px;
        font-weight: bold;
        font-size: 1.1em;
        padding: 10px; }
    .main-header .menu-bar .top-bar-tablet .nav-member .icon {
        top: 2px;
        margin-right: 5px; }
    .main-header .menu-bar .top-bar-tablet .nav-mail {
        width: 145px;
        font-weight: bold;
        font-size: 1.1em;
        padding: 8px 0 0 35px;
        position: relative; }
    .main-header .menu-bar .top-bar-tablet .nav-mail .icon {
        position: absolute;
        top: 15px;
        left: 15px; }
    .main-header .menu-bar .top-bar-tablet .social {
        float: right;
        width: 180px;
        padding-top: 5px; }
    .main-header .menu-bar .top-bar-tablet .social a {
        padding: 5px 10px; }
    .main-header .menu-bar .top-bar-tablet .social .icon-twitter-white-big {
        top: -2px; }
    .main-header .menu-bar .main-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        padding: 20px 0;
        height: 105px;
        display: none;
        z-index: 15;
        border-bottom: 1px solid #272424; }
    .main-header .menu-bar .main-menu.active {
        display: block; }
    .main-header .menu-bar .main-menu a {
        border-right: none;
        padding: 0 20px; }
    .main-header .menu-bar .main-menu-toggle {
        top: 163px; }
    .main-header .menu-bar .main-menu-toggle .column-nav {
        width: 300px; }
    .main-header .menu-bar .main-menu-toggle .column-nav .title {
        padding-left: 35px;
        font-size: 1.3em; }
    .main-header .menu-bar .main-menu-toggle .column-nav li a {
        font-size: 1.2em;
        padding-left: 35px; }
    .main-header .menu-bar .main-menu-toggle .column-news {
        width: 450px;
        padding-left: 20px; }
    .main-header .menu-bar .main-menu-toggle .action-info {
        width: 300px;
        padding-left: 10px; } }
.main-header .menu-bar .phone-nav {
    display: none; }
@media screen and (max-width: 480px) {
    .main-header .menu-bar {
        padding: 0; }
    .main-header .menu-bar .main-menu-toggle.active {
        display: none; }
    .main-header .menu-bar .top-bar-tablet {
        min-width: 320px; }
    .main-header .menu-bar .top-bar-tablet .logo {
        width: 140px; }
    .main-header .menu-bar .top-bar-tablet .nav {
        border: none;
        margin-top: 5px; }
    .main-header .menu-bar .top-bar-tablet .nav-menu {
        width: 110px;
        font-size: 1.2em;
        padding-top: 15px; }
    .main-header .menu-bar .top-bar-tablet .nav-menu .icon {
        width: 22px;
        height: 15px;
        background-position: -56px -202px;
        top: 13px; }
    .main-header .menu-bar .top-bar-tablet .nav-menu.active .icon {
        top: 10px; }
    .main-header .menu-bar .top-bar-tablet .nav-member {
        width: 40px; }
    .main-header .menu-bar .top-bar-tablet .nav-member .icon {
        width: 17px;
        height: 20px;
        background-position: -79px -200px; }
    .main-header .menu-bar .main-menu {
        padding: 10px;
        background: #eaeaea;
        height: auto; }
    .main-header .menu-bar .main-menu > ul {
        box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
        border-radius: 5px; }
    .main-header .menu-bar .main-menu > ul > li {
        width: 100%; }
    .main-header .menu-bar .main-menu > ul > li:first-child > a {
        padding-top: 15px;
        border-radius: 5px 5px 0 0; }
    .main-header .menu-bar .main-menu > ul > li:first-child > a:after {
        top: 24px; }
    .main-header .menu-bar .main-menu > ul > li:last-child > a {
        padding-bottom: 15px;
        border-radius: 0 0 5px 5px;
        border: none; }
    .main-header .menu-bar .main-menu a.toggle {
        width: 100%;
        position: relative;
        padding: 10px 40px 12px 60px;
        background: #272424;
        line-height: 10px;
        color: white;
        border-bottom: 1px solid rgba(187, 187, 187, 0.5);
        font-size: 1.2em;
        font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-weight: bold; }
    .main-header .menu-bar .main-menu a.toggle:after {
        content: '';
        position: absolute;
        top: 22px;
        right: 20px;
        height: 0;
        width: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #f2f2f2; }
    .main-header .menu-bar .main-menu a.toggle.active {
        background: white;
        color: #d33b38;
        border-bottom: 1px solid rgba(187, 187, 187, 0.5); }
    .main-header .menu-bar .main-menu a.toggle.active:after {
        height: 0;
        width: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #b7b7b7; }
    .main-header .menu-bar .main-menu a.toggle.active .icon-3-bottle-black {
        background-position: -24px 0; }
    .main-header .menu-bar .main-menu a.toggle.active .icon-meal-black {
        background-position: 0 -119px; }
    .main-header .menu-bar .main-menu a.toggle.active .icon-book-black {
        background-position: -22px -43px; }
    .main-header .menu-bar .main-menu a.toggle.active .icon-bag-black {
        background-position: -139px -61px; }
    .main-header .menu-bar .main-menu a.toggle.active .icon-grape-black {
        background-position: 0 -97px; }
    .main-header .menu-bar .main-menu a.toggle.active .icon-meat-black {
        background-position: -53px -43px; }
    .main-header .menu-bar .main-menu a.toggle .icon {
        position: absolute;
        top: 14px;
        left: 20px; }
    .main-header .menu-bar .main-menu a.toggle .icon-3-bottle-black {
        width: 22px;
        height: 22px;
        background-position: -56px -179px; }
    .main-header .menu-bar .main-menu a.toggle .icon-meal-black {
        width: 22px;
        height: 15px;
        background-position: -97px -194px; }
    .main-header .menu-bar .main-menu a.toggle .icon-book-black {
        width: 17px;
        height: 20px;
        background-position: -24px -152px;
        top: 12px; }
    .main-header .menu-bar .main-menu a.toggle .icon-bag-black {
        width: 14px;
        height: 22px;
        background-position: -23px -118px;
        top: 12px; }
    .main-header .menu-bar .main-menu a.toggle .icon-grape-black {
        width: 21px;
        height: 21px;
        background-position: -56px -218px;
        top: 12px; }
    .main-header .menu-bar .main-menu a.toggle .icon-meat-black {
        width: 21px;
        height: 16px;
        background-position: 0 -80px; }
    .main-header .menu-bar .phone-nav {
        position: static;
        background: white; }
    .main-header .menu-bar .phone-nav.active {
        display: block; }
    .main-header .menu-bar .phone-nav li {
        width: 100%; }
    .main-header .menu-bar .phone-nav li.active a {
        background: #d33b38;
        color: white; }
    .main-header .menu-bar .phone-nav li a {
        width: 100%;
        font-size: 1.4em;
        color: #252424;
        padding: 10px 20px;
        font-weight: bold; } }

.search-area {
    padding: 5px; }
@media screen and (max-width: 1024px) {
    .search-area {
        padding: 10px 15px; } }
@media screen and (max-width: 480px) {
    .search-area {
        padding: 0; } }
.search-area .bn-area {
    width: 100%;
    margin-bottom: 10px; }
.search-area .bn-area a {
    display: block; }
.search-area .search-bar {
    height: 45px;
    background-color: #db383d;
    background-image: -webkit-linear-gradient(#db383d, #d82125);
    background-image: linear-gradient(#db383d, #d82125); }
.search-area .search-bar .search-bar-toggle {
    width: 640px;
    color: white;
    font-size: 3em;
    font-family: "Grand Hotel", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    float: left;
    padding: 3px 0 0 15px;
    height: 100%;
    position: relative;
    cursor: pointer; }
@media screen and (max-width: 1024px) {
    .search-area .search-bar .search-bar-toggle {
        width: 380px; } }
@media screen and (max-width: 480px) {
    .search-area .search-bar .search-bar-toggle {
        width: 100%; }
    .search-area .search-bar .search-bar-toggle:before {
        content: '';
        background: url("../../public/img/sprites.png") no-repeat;
        background-position: -120px -76px;
        width: 18px;
        height: 21px;
        position: absolute;
        top: 12px;
        right: 20px; }
    .search-area .search-bar .search-bar-toggle:after {
        content: '';
        width: 48px;
        height: 29px;
        position: absolute;
        top: 7px;
        right: 5px;
        border: 1px solid #b11b1f;
        border-radius: 3px; }
    .search-area .search-bar .search-bar-toggle.active:before {
        background-position: -100px -124px;
        width: 19px;
        height: 19px; }
    .search-area .search-bar .search-bar-toggle.active:after {
        border: none;
        top: auto;
        right: auto; } }
.search-area .search-bar .search-bar-toggle.active .icon-arrow-down-white {
    display: none; }
.search-area .search-bar .search-bar-toggle.active:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 16px;
    height: 0;
    width: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #d82125;
    z-index: 2; }
.search-area .search-bar .search-bar-toggle .icon-water-white {
    margin-right: 5px;
    top: 2px; }
.search-area .search-bar .search-bar-toggle .icon-arrow-down-white {
    top: -2px;
    margin-left: 10px; }
.search-area .search-bar .search-form {
    width: 350px;
    float: right;
    padding-top: 6px;
    text-align: right;
    padding-right: 10px; }
@media screen and (max-width: 480px) {
    .search-area .search-bar .search-form {
        display: none; } }
.search-area .search-bar .search-form input {
    width: 300px;
    height: 30px;
    padding: 5px;
    font-size: 1.3em;
    border-radius: 3px;
    border: none;
    background: white; }
.search-area .search-bar .search-form button {
    position: relative;
    top: 7px;
    background: none;
    border: none;
    margin-left: 5px; }
@media screen and (max-width: 480px) {
    .search-area .search-bar .search-form button {
        border: 1px solid #b11b1f;
        padding: 4px 15px;
        border-radius: 3px; } }
.search-area .search-criteria {
    background: #dde1ea;
    padding: 10px 10px 0;
    position: relative;
    display: block; }
.search-area .search-criteria:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .search-area .search-criteria {
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5); } }
.search-area .search-criteria .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    cursor: pointer; }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .close {
        display: none; } }
.search-area .search-criteria .phone-search-input {
    color: #4b4b4b;
    position: relative;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    margin-top: 10px;
    background: white;
    border: 1px solid #cccccc;
    font-size: 1.3em; }
.search-area .search-criteria .items {
    width: 780px;
    float: left; }
@media screen and (max-width: 1024px) {
    .search-area .search-criteria .items {
        width: 570px; } }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .items {
        width: 100%;
        padding-top: 10px; } }
.search-area .search-criteria .items .item {
    width: 250px;
    float: left;
    margin: 0 10px 10px 0;
    color: #4b4b4b;
    position: relative; }
@media screen and (max-width: 1024px) {
    .search-area .search-criteria .items .item {
        width: 180px; } }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .items .item {
        width: 100%;
        margin: 0 0 10px; } }
.search-area .search-criteria .items .item .current {
    color: #4b4b4b;
    position: relative;
    width: 100%;
    padding: 10px 10px 10px 35px;
    border-radius: 3px;
    background: white;
    border: 1px solid #cccccc;
    font-size: 1.5em;
    font-weight: bold; }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .items .item .current {
        padding-left: 25px;
        font-size: 1.3em; } }
.search-area .search-criteria .items .item .current:after {
    content: '';
    width: 15px;
    height: 10px;
    background: url("../../public/img/sprites.png") -22px -141px no-repeat;
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 1;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }
.search-area .search-criteria .items .item .current.active:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
.search-area .search-criteria .items .item .current .icon {
    position: absolute;
    top: 10px;
    left: 10px; }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .items .item .current .icon {
        -webkit-transform: scale3d(0.8, 0.8, 0.8);
        -moz-transform: scale3d(0.8, 0.8, 0.8);
        -ms-transform: scale3d(0.8, 0.8, 0.8);
        -o-transform: scale3d(0.8, 0.8, 0.8);
        transform: scale3d(0.8, 0.8, 0.8);
        left: 4px;
        top: 9px; } }
.search-area .search-criteria .items .item .search-popup {
    list-style: none;
    margin: 0;
    padding: 0;
    background: white;
    border: 1px solid #d4d4d4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2; }
.search-area .search-criteria .items .item .search-popup li:last-child a {
    border: none; }
.search-area .search-criteria .items .item .search-popup a {
    color: #333333;
    font-size: 1.3em;
    padding: 10px 10px 10px 35px;
    border-bottom: 1px solid #ededed;
    width: 100%; }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .items .item .search-popup a {
        padding-left: 25px; } }
.search-area .search-criteria .items .action {
    width: 250px;
    float: left;
    font-weight: bold;
    font-style: italic;
    font-size: 1.3em;
    padding-top: 12px;
    text-align: center; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .search-area .search-criteria .items .action {
        font-size: 1.1em;
        width: 180px; } }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .items .action {
        width: 48%;
        margin: 0 1% 10px;
        font-size: 1.4em;
        padding-top: 10px;
        font-style: normal;
        display: none; } }
.search-area .search-criteria .items .action a {
    color: #4b4b4b; }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .items .action a {
        color: #d33b38;
        border-bottom: 1px solid #d33b38; } }
.search-area .search-criteria .items .action a:hover {
    color: #d33b38; }
.search-area .search-criteria .submit {
    width: 190px;
    float: right;
    padding-top: 25px;
    text-align: center; }
@media screen and (max-width: 1024px) {
    .search-area .search-criteria .submit {
        width: 130px; } }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .submit {
        width: 100%;
        padding-top: 0;
        margin-bottom: 10px; } }
.search-area .search-criteria .submit input {
    display: inline-block;
    padding: 10px 30px;
    border: 1px solid black;
    background-color: #313131;
    background-image: -webkit-linear-gradient(#313131, #1c1c1c);
    background-image: linear-gradient(#313131, #1c1c1c);
    color: white;
    font-size: 1.3em;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .search-area .search-criteria .submit input {
        padding: 10px; } }
@media screen and (max-width: 480px) {
    .search-area .search-criteria .submit input {
        width: 100%;
        text-transform: uppercase; } }
.search-area .search-criteria .submit input:hover {
    background: #313131; }

.main-sidebar {
    width: 320px;
    float: right;
    padding: 20px 20px 20px 0; }
.main-sidebar .block {
    margin-bottom: 30px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.main-sidebar .block .title {
    font-weight: bold;
    font-size: 1.8em;
    margin-bottom: 5px; }
.main-sidebar .block .sub {
    font-size: 1.5em;
    color: #3d3d3d;
    margin-bottom: 5px; }
.main-sidebar .block .strong {
    font-size: 1.4em;
    color: #d33b38;
    font-weight: 600;
    margin-bottom: 15px; }
.main-sidebar .block .content {
    font-size: 1.5em;
    color: #3d3d3d; }
.main-sidebar .block .action {
    margin-bottom: 15px; }
.main-sidebar .block-discover, .main-sidebar .block-newsletter {
    padding: 15px 8px;
    text-align: center;
    border: 1px solid #d7d8d8;
    background: #f4f4f4; }
.main-sidebar .block-discover .action a {
    background-color: #b2020c;
    background-image: -webkit-linear-gradient(#b2020c, #8a0206);
    background-image: linear-gradient(#b2020c, #8a0206);
    border-radius: 5px;
    border: 1px solid #8a0206;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.main-sidebar .block-discover .action a:hover {
    background: #b2020c; }
.main-sidebar .block-discover .action a img {
    display: block; }
.main-sidebar .block-discover .social a.connection {
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.main-sidebar .block-discover .social a.connection:hover {
    background: #323232;
    box-shadow: none; }
.main-sidebar .block-discover .social a.connection img {
    display: block; }
.main-sidebar .block-discover .social a.fb {
    background-color: #22599a;
    background-image: -webkit-linear-gradient(#22599a, #0c377c);
    background-image: linear-gradient(#22599a, #0c377c);
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.main-sidebar .block-discover .social a.fb:hover {
    background: #22599a; }
.main-sidebar .block-discover .social a.fb img {
    display: block; }
.main-sidebar .block-newsletter .strong {
    margin-bottom: 5px; }
.main-sidebar .block-newsletter form input {
    width: 220px;
    padding: 10px 8px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #d7d8d8;
    font-size: 1.3em; }
.main-sidebar .block-newsletter form button {
    position: relative;
    top: 16px; }
.main-sidebar .block-social a {
    margin-right: 1px;
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.main-sidebar .block-social a:last-child {
    margin: 0; }
.main-sidebar .block-social a img {
    display: block; }
.main-sidebar .block-social a.facebook {
    background-color: #235999;
    background-image: -webkit-linear-gradient(#235999, #0a377b);
    background-image: linear-gradient(#235999, #0a377b); }
.main-sidebar .block-social a.facebook:hover {
    background: #235999; }
.main-sidebar .block-social a.twitter {
    background-color: #00abe1;
    background-image: -webkit-linear-gradient(#00abe1, #008ed0);
    background-image: linear-gradient(#00abe1, #008ed0); }
.main-sidebar .block-social a.twitter:hover {
    background: #00abe1; }
.main-sidebar .block-social a.gplus {
    background-color: #fa4530;
    background-image: -webkit-linear-gradient(#fa4530, #e62b18);
    background-image: linear-gradient(#fa4530, #e62b18); }
.main-sidebar .block-social a.gplus:hover {
    background: #fa4530; }
.main-sidebar .block-social a.linkedin {
    background-color: #007cb8;
    background-image: -webkit-linear-gradient(#007cb8, #005ba1);
    background-image: linear-gradient(#007cb8, #005ba1); }
.main-sidebar .block-social a.linkedin:hover {
    background: #007cb8; }
.main-sidebar .block-club .top {
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 15px;
    margin-bottom: 15px; }
.main-sidebar .block-club .top:after {
    content: "";
    display: table;
    clear: both; }
.main-sidebar .block-club .top .image {
    width: 65px;
    float: left; }
.main-sidebar .block-club .top .text {
    margin-left: 66px;
    padding-top: 28px; }
.main-sidebar .block-club .top .text .title {
    font-weight: bold;
    font-size: 1.9em;
    color: #8e6a22; }
.main-sidebar .block-club .top .text .sub {
    font-size: 1.4em; }
.main-sidebar .block-club .bottom .title {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 2.4em;
    color: black; }
.main-sidebar .block-club .bottom .title a {
    color: black; }
.main-sidebar .block-club .bottom .title a:hover {
    color: #d33b38; }
.main-sidebar .block-club .action {
    margin-top: 20px;
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.main-sidebar .block-club .action .action-inner {
    position: relative;
    border-bottom: 1px solid #d4d4d4; }
.main-sidebar .block-club .action a {
    position: absolute;
    padding: 0 10px;
    background: white;
    top: -10px;
    right: 0;
    font-weight: bold;
    font-style: italic;
    font-size: 1.2em;
    color: black; }
.main-sidebar .block-club .action a:hover {
    color: #d33b38; }

/*.main-content {
    width: 680px;
    float: left;
    padding: 20px; }*/
@media screen and (max-width: 1024px) {
    .main-content {
        width: 100%; } }
@media screen and (max-width: 480px) {
    .main-content {
        /*padding: 20px 10px;*/ } }

.breadcrumbs {
    font-size: 11px;
    margin-bottom: 0px;
    color: #333333; }
@media screen and (max-width: 480px) {
    .breadcrumbs {
        display: none;
        padding: 0} }
.breadcrumbs a {
    color: #333333; }
.breadcrumbs .text {
    color: #d33b38; }

.block-title {
    font-weight: normal;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 2.2em;
    color: #3d3d3d;
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px; }
.block-title.no-icon {
    padding-left: 0; }
@media screen and (max-width: 480px) {
    .block-title {
        font-size: 2em; } }
.block-title a {
    color: #3d3d3d; }
.block-title strong {
    color: black; }
.block-title .icon {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%); }
.block-title .btn {
    color: white; }

.main-title {
    font-weight: normal;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 3.2em;
    color: #282222;
    margin-bottom: 15px;
    position: relative;
    padding-left: 35px; }
.main-title.no-icon {
    padding-left: 0; }
.main-title .icon {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%); }
@media screen and (max-width: 1024px) {
    .main-title {
        font-size: 2.8em; } }
@media screen and (max-width: 480px) {
    .main-title {
        font-size: 2.4em; } }

.home-slider .top-description {
    font-size: 1.6em;
    color: #333333;
    margin-bottom: 15px; }
.home-slider .info {
    padding: 10px 15px;
    background: #272424;
    color: white;
    position: relative; }
.home-slider .info a {
    color: white; }
.home-slider .info a:hover {
    text-decoration: underline; }
.home-slider .info .title {
    font-weight: bold;
    font-size: 1.8em;
    margin-bottom: 10px; }
@media screen and (max-width: 480px) {
    .home-slider .info .title {
        font-size: 1.2em;
        margin-bottom: 0; } }
.home-slider .info .excerpt {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #cccccc; }
@media screen and (max-width: 480px) {
    .home-slider .info .excerpt {
        font-size: 1.2em;
        margin-bottom: 0; } }
.home-slider .info .action {
    font-weight: bold;
    font-style: italic;
    font-size: 1.2em;
    display: block; }
@media screen and (max-width: 1024px) {
    .home-slider .info .action {
        text-align: right; } }
@media screen and (max-width: 480px) {
    .home-slider .info .action {
        font-size: 1.0em; } }
.home-slider .flex-prev, .home-slider .flex-next {
    width: 9px;
    height: 20px;
    top: 50%;
    opacity: 1;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
    background: url("../../public/img/sprites.png") no-repeat; }
@media screen and (max-width: 480px) {
    .home-slider .flex-prev, .home-slider .flex-next {
        display: none; } }
.home-slider .flex-prev {
    left: 15px;
    background-position: -120px -108px; }
.home-slider .flex-next {
    right: 15px;
    background-position: -120px -189px; }
.home-slider .flex-control-nav {
    left: 0;
    top: 250px;
    z-index: 10;
    bottom: auto;
    display: none; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .home-slider .flex-control-nav {
        top: 300px; } }
@media screen and (max-width: 480px) {
    .home-slider .flex-control-nav {
        top: 120px; } }
.home-slider .flex-control-nav a {
    background: white;
    width: 10px;
    height: 10px;
    border: 2px solid white;
    box-shadow: none; }
.home-slider .flex-control-nav a.flex-active {
    background: black; }
.home-slider.cuisine-page {
    width: 410px;
    float: left; }
@media screen and (max-width: 480px) {
    .home-slider.cuisine-page {
        width: 100%; } }
.home-slider.cuisine-page .flex-prev, .home-slider.cuisine-page .flex-next {
    display: none; }
.home-slider.cuisine-page .flex-control-nav {
    bottom: 80px; }

.region-slider {
    background: #f4f4f4; }
@media screen and (min-width: 1025px) {
    .region-slider {
        padding: 0 50px; } }
@media screen and (max-width: 480px) {
    .region-slider {
        margin-left: -10px;
        margin-right: -10px; } }
.region-slider .slides li {
    margin-bottom: -999px;
    padding-bottom: 999px; }
.region-slider .slides li:hover {
    background: #eaeaea; }
.region-slider .slides a {
    color: #3d3d3d;
    font-size: 1.2em;
    padding: 10px 0;
    width: 90px;
    text-align: center; }
.region-slider .slides a span {
    display: block;
    margin-bottom: 5px; }
.region-slider .slides a span img {
    width: auto;
    display: inline-block; }
.region-slider .flex-prev, .region-slider .flex-next {
    width: 9px;
    height: 20px;
    top: 55px;
    opacity: 1;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
    background: url("../../public/img/sprites.png") no-repeat; }
@media screen and (max-width: 1024px) {
    .region-slider .flex-prev, .region-slider .flex-next {
        display: none; } }
.region-slider .flex-prev {
    left: 15px;
    background-position: -130px -98px; }
.region-slider .flex-next {
    right: 15px;
    background-position: -79px -179px; }

.news-area.cuisine-page .items .image {
    margin-bottom: 0; }
.news-area .description {
    font-size: 1.6em;
    margin-bottom: 20px;
    color: #333333; }
.news-area .items {
    margin-left: -10px;
    margin-right: -10px; }
.news-area .items:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .news-area .items {
        margin: 0; } }
.news-area .items .item {
    width: 46%;
    padding: 0 10px;
    float: left;
    margin-bottom: 25px; }
@media screen and (max-width: 480px) {
    .news-area .items .item {
        width: 100%;
        padding: 0; } }
.news-area .items .item:nth-child(2n+1) {
    clear: both; }
.news-area .items .item.full {
    width: 100%; }
.news-area .items .image {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    min-height: 100px; }
.news-area .items .image:hover .overlay {
    display: block; }
.news-area .items .image .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    text-align: center;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.news-area .items .image .title span {
    display: inline-block;
    background: #272424;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 1.1em; }
.news-area .items .image .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: none;
    text-align: center; }
.news-area .items .image .overlay a {
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    top: 40%;
    left: 0;
    position: absolute;
    width: 100%; }
.news-area .items .image .overlay ul {
    list-style: none;
    text-align: left;
    padding-top: 5px; }
.news-area .items .image .overlay ul li {
    width: 33.3333%;
    padding: 5px 20px;
    font-style: italic;
    float: left; }
.news-area .items .image .overlay ul a {
    position: static;
    font-size: 1.2em; }
.news-area .items .info {
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 10px; }
.news-area .items .info a {
    color: #282222; }
.news-area .items .info a:hover {
    color: #d33b38; }
.news-area .items .info .title {
    font-size: 2.0em;
    margin-bottom: 15px;
    min-height: 52px; }
@media screen and (max-width: 480px) {
    .news-area .items .info .title {
        font-size: 1.8em;
        margin-bottom: 10px; } }
.news-area .items .info .excerpt {
    font-size: 1.6em;
    color: #666666;
    margin-bottom: 10px;
    line-height: 1em; }
.news-area .items .info .action {
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic; }
.news-area .items .bn-pub {
    margin: 0 -10px;
    width: 320px;
    background: #cdcdcd;
    color: #aeaeae;
    font-size: 2.3em;
    padding: 10px;
    text-align: center; }
.news-area .items .bn-pub.top {
    margin-bottom: 20px; }
.news-area .items .calendar {
    margin-bottom: 0; }
.news-area .items .calendar a {
    color: #3d3d3d; }
.news-area .items .calendar a:hover {
    color: #d33b38; }
.news-area .items .calendar .event {
    padding-bottom: 10px;
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 10px; }
.news-area .items .calendar .date {
    font-weight: bold;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.2em;
    padding: 5px 15px;
    background: #f4f4f4;
    display: inline-block;
    margin-bottom: 5px; }
.news-area .items .calendar .date .number {
    color: #4a4a4a;
    font-size: 2.2em; }
.news-area .items .calendar .title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 1.5em; }
.news-area .items .calendar .location {
    font-weight: bold;
    font-size: 1.2em;
    color: #747474;
    margin-bottom: 5px; }
.news-area .items .calendar .location .icon {
    top: 10px;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    -moz-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    -o-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
.news-area .items .calendar .content {
    font-size: 1.4em;
    color: #666666;
    margin-bottom: 10px; }
.news-area .items .calendar .action {
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic; }

.selection-area {
    clear: both;
    padding: 0 20px 20px; }
.selection-area:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .selection-area {
        padding: 0 10px 10px; } }
@media screen and (min-width: 1025px) {
    .selection-area.selection-area-full .badge {
        float: none;
        text-align: left; }
    .selection-area.selection-area-full .badge:after {
        right: 0;
        border: 25px solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-right-color: white;
        top: 50%;
        margin-top: -25px; } }
.selection-area.selection-area-full .selection-items {
    padding-bottom: 20px; }
@media screen and (min-width: 1025px) {
    .selection-area .main-title {
        float: left; } }
.selection-area .badge {
    padding: 7px;
    font-size: 2.6em;
    font-family: "Grand Hotel", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    color: white;
    background: #d17559;
    text-align: center;
    margin-bottom: 15px;
    position: relative; }
@media screen and (min-width: 1025px) {
    .selection-area .badge {
        min-height: 50px;
        max-width: 400px;
        position: relative;
        top: -5px;
        float: right;
        padding: 7px 45px; }
    .selection-area .badge:before {
        left: 0;
        border: 25px solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-left-color: white;
        top: 50%;
        margin-top: -25px; } }
@media screen and (max-width: 480px) {
    .selection-area .badge {
        font-size: 2.4em; } }
.selection-area .content {
    font-size: 1.6em;
    color: #666666;
    margin-bottom: 20px; }
@media screen and (max-width: 1024px) {
    .selection-area .content {
        font-size: 1.4em; } }
.selection-area .legend {
    margin-bottom: 10px;
    padding: 25px 0;
    border-top: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4; }
.selection-area .legend:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .selection-area .legend {
        padding: 15px 0; } }
.selection-area .legend .name {
    font-size: 1.4em;
    color: #666;
    width: 90px;
    float: left; }
@media screen and (max-width: 1024px) {
    .selection-area .legend .name {
        display: none; } }
.selection-area .legend .items {
    margin-left: 90px;
    font-size: 1.2em;
    font-weight: bold;
    color: #aaaaaa; }
@media screen and (max-width: 1024px) {
    .selection-area .legend .items {
        margin: 0; } }
.selection-area .legend .items .item {
    float: left;
    margin-right: 60px;
    position: relative;
    padding-left: 20px; }
@media screen and (max-width: 1024px) {
    .selection-area .legend .items .item {
        margin-right: 15px; } }
@media screen and (max-width: 480px) {
    .selection-area .legend .items .item {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0; }
    .selection-area .legend .items .item:last-child {
        margin-bottom: 0; } }
.selection-area .legend .items .item:last-child {
    margin: 0; }
.selection-area .legend .items .item .picto {
    position: absolute;
    top: -2px;
    left: 0;
    display: block; }
.selection-area .legend .items .item.item-1 .picto {
    top: -5px; }
.selection-area .legend .items .item.item-2 {
    padding-left: 25px; }
.selection-area .legend .items .item.item-4 {
    padding-left: 40px; }
.selection-area .legend .items .item.item-5 {
    padding-left: 60px; }

.selection-items {
    padding: 0 10px 40px; }
@media screen and (max-width: 1024px) {
    .selection-items {
        padding: 0 50px 40px; } }
@media screen and (max-width: 480px) {
    .selection-items {
        padding: 0 0 40px; } }
.selection-items .slides li {
    width: 313px; }
.selection-items .slides li:after {
    content: "";
    display: table;
    clear: both; }
.selection-items .left-panel {
    width: 80px;
    float: left;
    text-align: center; }
.selection-items .right-panel {
    width: 200px;
    float: left;
    margin-left: 15px; }
.selection-items .top {
    min-height: 100px;
    margin-bottom: 10px; }
.selection-items .top .info {
    padding-top: 3px; }
.selection-items .top .info .type {
    margin-bottom: 15px; }
.selection-items .top .title {
    margin-bottom: 5px; }
.selection-items .top .title a {
    font-size: 2em;
    font-weight: bold;
    color: black;
    line-height: 20px; }
.selection-items .top .title a:hover {
    color: #d33b38; }
.selection-items .top .meta {
    color: black;
    font-size: 1.6em; }
.selection-items .top .meta .year {
    font-weight: bold; }
.selection-items .bottom:after {
    content: "";
    display: table;
    clear: both; }
.selection-items .bottom .image img {
    max-width: 100%; }
.selection-items .bottom .info {
    min-height: 100px; }
.selection-items .bottom .meta {
    margin-bottom: 10px;
    padding-top: 10px; }
.selection-items .bottom .meta .year {
    font-weight: bold;
    font-size: 1.4em;
    color: #d33b38;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -2px; }
.selection-items .bottom .sub, .selection-items .bottom .content, .selection-items .bottom .notice {
    color: #666666;
    font-size: 1.3em;
    margin-bottom: 10px; }
.selection-items .bottom .content a, .selection-items .bottom .notice a {
    color: #666666; }
.selection-items .bottom .content a:hover, .selection-items .bottom .notice a:hover {
    color: #d33b38; }
.selection-items .bottom .content {
    font-weight: bold; }
.selection-items .bottom .type {
    display: inline-block;
    margin-left: 15px;
    font-weight: bold; }
.selection-items .bottom .price {
    font-size: 1.2em;
    font-weight: bold;
    color: #666666;
    margin-bottom: 10px; }
.selection-items .bottom .action {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.selection-items .bottom .action:hover {
    background: #d94247;
    box-shadow: none; }
.selection-items .flex-prev, .selection-items .flex-next {
    width: 9px;
    height: 20px;
    top: 50%;
    opacity: 1;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
    background: url("../../public/img/sprites.png") no-repeat; }
@media screen and (max-width: 480px) {
    .selection-items .flex-prev, .selection-items .flex-next {
        display: none; } }
.selection-items .flex-prev {
    left: -15px;
    background-position: -130px -98px; }
@media screen and (max-width: 1024px) {
    .selection-items .flex-prev {
        left: 15px; } }
.selection-items .flex-next {
    right: -15px;
    background-position: -79px -179px; }
@media screen and (max-width: 1024px) {
    .selection-items .flex-next {
        right: 15px; } }
.selection-items .flex-control-nav {
    left: 0;
    bottom: 0; }
.selection-items .flex-control-nav a {
    background: black;
    width: 10px;
    height: 10px;
    border: 2px solid black;
    box-shadow: none; }
.selection-items .flex-control-nav a.flex-active {
    background: white; }

@media screen and (min-width: 481px) and (max-width: 1024px) {
    .selection-area-full .selection-items {
        padding: 0 10px 40px; } }
@media screen and (min-width: 481px) {
    .selection-area-full .selection-items .slides li {
        padding: 0 5px;
        margin-bottom: 15px; }
    .selection-area-full .selection-items .slides li .bottom {
        border-bottom: 1px solid #d4d4d4;
        min-height: 250px;
        padding-bottom: 15px; } }
@media screen and (min-width: 1025px) {
    .selection-area-full .selection-items .slides li:nth-child(3n+1) {
        clear: both; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .selection-area-full .selection-items .slides li {
        width: 50%; }
    .selection-area-full .selection-items .slides li:nth-child(2n+1) {
        clear: both; } }

.main-footer {
    background: #272424 url("../../public/img/bg-footer.png") bottom center no-repeat; }
.main-footer .container {
    background: transparent; }
.main-footer .columns {
    padding: 20px; }
.main-footer .columns:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .main-footer .columns {
        padding: 0 0 30px; } }
.main-footer .nav {
    width: 16.66%;
    float: left;
    padding: 15px 8px 0 8px;
    border-left: 1px solid #302f2f;
    margin-bottom: 40px;
    min-height: 200px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .main-footer .nav {
        width: 25%; }
    .main-footer .nav:nth-child(4n+1) {
        clear: both; } }
@media screen and (max-width: 480px) {
    .main-footer .nav {
        width: 100%;
        min-height: initial;
        border-left: none;
        border-bottom: 1px solid #3b3a3a;
        margin-bottom: 0;
        float: none;
        padding: 15px 15px 10px; }
    .main-footer .nav ul {
        display: none;
        margin-top: 10px; }
    .main-footer .nav.active ul {
        display: block; }
    .main-footer .nav.active .title:after {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg); } }
.main-footer .nav .title {
    font-size: 1.1em;
    color: #f5f5f5;
    margin-bottom: 20px; }
@media screen and (max-width: 480px) {
    .main-footer .nav .title {
        margin-bottom: 0;
        position: relative; }
    .main-footer .nav .title:after {
        content: '';
        position: absolute;
        top: 2px;
        right: 5px;
        width: 12px;
        height: 7px;
        background: url("../../public/img/sprites.png");
        background-position: -40px -42px; } }
.main-footer .nav li {
    width: 100%;
    margin-bottom: 5px; }
.main-footer .nav li:last-child {
    margin-bottom: 0; }
.main-footer .nav li a {
    font-size: 1.2em;
    color: #7c7c7c; }
.main-footer .nav li a:hover {
    color: white; }
.main-footer .footer {
    clear: both; }
.main-footer .footer:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .main-footer .footer {
        width: 50%;
        float: left;
        clear: none; } }
@media screen and (max-width: 480px) {
    .main-footer .footer {
        margin-top: 20px; } }
.main-footer .footer .logo {
    width: 210px;
    float: left;
    margin-bottom: 15px; }
@media screen and (max-width: 480px) {
    .main-footer .footer .logo {
        width: 50%;
        padding-left: 10px; } }
.main-footer .footer .logo img {
    max-width: 100%; }
.main-footer .footer .column {
    float: left;
    border-top: 1px solid #3b3a3a;
    border-bottom: 1px solid #3b3a3a;
    height: 55px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .main-footer .footer .column {
        width: 100%; } }
.main-footer .footer .column label {
    font-weight: bold;
    font-size: 1.2em;
    color: #e7e7e7;
    display: inline-block;
    margin-right: 30px;
    position: relative; }
@media screen and (max-width: 1024px) {
    .main-footer .footer .column label {
        margin-right: 45px; } }
.main-footer .footer .social {
    padding-top: 15px; }
@media screen and (max-width: 1024px) {
    .main-footer .footer .social {
        border-bottom: none; } }
@media screen and (max-width: 480px) {
    .main-footer .footer .social {
        width: 50%;
        border: none;
        padding-left: 30px;
        padding-top: 0; } }
.main-footer .footer .social ul {
    display: inline-block;
    border-right: 1px solid #3b3a3a; }
@media screen and (max-width: 1024px) {
    .main-footer .footer .social ul {
        border: none; } }
.main-footer .footer .social ul li {
    margin-right: 30px; }
@media screen and (max-width: 1024px) {
    .main-footer .footer .social ul li {
        margin-right: 40px; }
    .main-footer .footer .social ul li:last-child {
        margin: 0; } }
@media screen and (max-width: 480px) {
    .main-footer .footer .social ul li {
        margin-right: 10px; } }
.main-footer .footer .social label {
    top: -4px; }
@media screen and (max-width: 480px) {
    .main-footer .footer .social label {
        top: 0;
        display: block;
        margin: 0;
        margin-bottom: 10px; } }
.main-footer .footer .social .icon-twitter-white {
    top: -1px; }
.main-footer .footer .newsletter {
    padding-left: 30px;
    padding-top: 10px; }
@media screen and (max-width: 1024px) {
    .main-footer .footer .newsletter {
        padding-left: 0; } }
@media screen and (max-width: 480px) {
    .main-footer .footer .newsletter {
        padding-left: 10px; } }
@media screen and (max-width: 1024px) {
    .main-footer .footer .newsletter label {
        margin-right: 20px; } }
@media screen and (max-width: 480px) {
    .main-footer .footer .newsletter label {
        margin-right: 5px; } }
.main-footer .footer .newsletter form {
    display: inline-block; }
.main-footer .footer .newsletter form input {
    height: 35px;
    border-radius: 3px;
    border: 1px solid black; }
.main-footer .footer .newsletter form input[type=email] {
    width: 190px;
    padding: 10px;
    background: #2b2b2b;
    margin-right: 5px;
    font-size: 1.3em;
    color: white; }
@media screen and (max-width: 480px) {
    .main-footer .footer .newsletter form input[type=email] {
        width: 150px; } }
.main-footer .footer .newsletter form input[type=submit] {
    padding: 10px 15px;
    color: white;
    background-color: #313131;
    background-image: -webkit-linear-gradient(#313131, #1d1d1d);
    background-image: linear-gradient(#313131, #1d1d1d);
    font-size: 1.3em;
    font-weight: bold;
    line-height: 14px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.main-footer .footer .newsletter form input[type=submit]:hover {
    background: #313131; }
.main-footer .discover {
    padding: 0 20px 50px; }
.main-footer .discover:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .main-footer .discover {
        padding: 0 10px 50px; } }
.main-footer .discover label {
    display: block;
    float: left;
    width: 200px;
    font-size: 1.6em;
    color: #e7e7e7; }
@media screen and (max-width: 1024px) {
    .main-footer .discover label {
        float: none;
        margin-bottom: 25px;
        width: 100%; } }
.main-footer .discover .items {
    margin-left: 220px; }
@media screen and (max-width: 1024px) {
    .main-footer .discover .items {
        margin: 0; } }
.main-footer .discover .items .item {
    width: 33%;
    padding-right: 10px;
    float: left; }
.main-footer .discover .items .item .image {
    float: left; }
@media screen and (max-width: 480px) {
    .main-footer .discover .items .item .image {
        float: none; } }
.main-footer .discover .items .item .info {
    margin-left: 70px; }
@media screen and (max-width: 480px) {
    .main-footer .discover .items .item .info {
        margin: 0; } }
.main-footer .discover .items .item .info .title a {
    font-size: 1.1em;
    font-weight: bold;
    color: white;
    margin-bottom: 2px; }
.main-footer .discover .items .item .info .price {
    font-weight: bold;
    font-size: 1.2em;
    color: #d33b38;
    margin-bottom: 10px; }
.main-footer .discover .items .item .info .store a {
    margin-right: 8px; }

.copyright {
    background: white; }
.copyright .container {
    padding: 20px; }
@media screen and (max-width: 480px) {
    .copyright .container {
        padding: 20px 10px;
        text-align: center; } }
.copyright .nav {
    float: left; }
@media screen and (max-width: 480px) {
    .copyright .nav {
        float: none;
        display: inline-block;
        margin-bottom: 30px; } }
.copyright .nav li {
    margin-right: 20px; }
.copyright .nav li:last-child {
    margin: 0; }
.copyright .nav a {
    color: #656566;
    font-size: 1.1em;
    font-weight: bold; }
.copyright .fidesio {
    color: #525050;
    font-family: Georgia;
    font-style: italic;
    font-weight: bold;
    float: right; }
@media screen and (max-width: 480px) {
    .copyright .fidesio {
        float: none; } }
.copyright .fidesio a {
    color: #525050; }

.favorite-area .items {
    clear: both; }
.favorite-area .items .item {
    border-bottom: 1px solid #d4d4d4;
    padding-top: 20px;
    padding-bottom: 20px; }
.favorite-area .items .item:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .favorite-area .items .item {
        border: none; } }
.favorite-area .left-panel {
    max-width: 50%;
    float: left; }
@media screen and (max-width: 480px) {
    .favorite-area .left-panel {
        width: 45px; } }
.favorite-area .left-panel .info {
    padding-top: 3px;
    float: left; }
.favorite-area .left-panel .info .type {
    margin-bottom: 50px; }
.favorite-area .left-panel .image {
    margin-left: 45px;
    text-align: center;
    padding: 10px;
    background: #f4f4f4; }
@media screen and (max-width: 480px) {
    .favorite-area .left-panel .image {
        display: none; } }
.favorite-area .left-panel .image a, .favorite-area .left-panel .image img {
    max-width: 100%; }
.favorite-area .right-panel {
    width: 50%;
    float: left;
    padding-left: 20px; }
@media screen and (max-width: 480px) {
    .favorite-area .right-panel {
        margin-left: 45px;
        float: none;
        padding-left: 0;
        width: auto; } }
.favorite-area .right-panel .header .title {
    margin-bottom: 5px; }
.favorite-area .right-panel .header .title a {
    font-size: 20px;
    font-weight: bold;
    color: black;
    line-height: 20px; }
.favorite-area .right-panel .header .title a:hover {
    color: #d33b38; }
.favorite-area .right-panel .header .meta {
    color: black;
    font-size: 1.6rem;
    margin-bottom: 10px; }
.favorite-area .right-panel .header .meta .year {
    font-weight: bold; }
.favorite-area .right-panel .info .meta {
    margin-bottom: 10px; }
.favorite-area .right-panel .info .meta .year {
    font-weight: bold;
    font-size: 1.4em;
    color: #d33b38;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -2px; }
.favorite-area .right-panel .info .sub, .favorite-area .right-panel .info .content, .favorite-area .right-panel .info .notice {
    color: #666666;
    font-size: 1.3em;
    margin-bottom: 10px; }
.favorite-area .right-panel .info .content a, .favorite-area .right-panel .info .notice a {
    color: #666666; }
.favorite-area .right-panel .info .content a:hover, .favorite-area .right-panel .info .notice a:hover {
    color: #d33b38; }
.favorite-area .right-panel .info .content {
    font-weight: bold; }
.favorite-area .right-panel .notice .type {
    display: inline-block;
    margin-left: 15px;
    font-size: 13px;
    font-weight: bold; }
.favorite-area .right-panel .notice .type a {
    color: #666666; }
.favorite-area .right-panel .notice .type a:hover {
    color: #d33b38; }
.favorite-area .right-panel .footer {
    text-align: right;
    margin-top: 10px; }
@media screen and (max-width: 480px) {
    .favorite-area .right-panel .footer {
        text-align: left; } }
.favorite-area .right-panel .footer .price {
    font-size: 1.2em;
    font-weight: bold;
    color: #666666;
    margin-right: 10px;
    margin-bottom: 10px;
    display: block; }
.favorite-area .right-panel .footer .action {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.favorite-area .right-panel .footer .action:hover {
    background: #d94247;
    box-shadow: none; }
.favorite-area .right-panel .footer .action.small {
    padding: 5px 10px; }
.favorite-area.premium-page .result-area .result-header .filter-area {
    float: left;
    text-align: left;
    margin-bottom: 5px; }
@media screen and (max-width: 480px) {
    .favorite-area.premium-page .result-area .result-header .filter-area {
        width: 100%; } }
@media screen and (min-width: 481px) {
    .favorite-area.premium-page .left-panel {
        max-width: 40%; }
    .favorite-area.premium-page .right-panel {
        width: 60%; } }
.favorite-area.premium-page .item:last-child {
    border: none; }
.favorite-area.premium-page .notice {
    margin-bottom: 5px; }

.block-toggleable {
    display: none; }
.block-toggleable.active {
    display: block; }

.block-action {
    margin-bottom: 20px;
    clear: both; }
.block-action .action-inner {
    position: relative;
    border-bottom: 1px solid #d4d4d4; }
.block-action a {
    position: absolute;
    padding-left: 10px;
    background: white;
    top: -10px;
    right: 0;
    font-weight: normal;
    font-style: italic;
    font-size: 1.3em;
    color: black;
    text-decoration: underline;
}
.espace-type {
    margin-top: 40px;
}
.info .title a:hover {
    cursor: pointer;
}
span.search_term {
    font-size: 20px;
    padding: 0 20px;
}
.block-action a:hover {
    color: #d33b38; }
.block-action.arrow a {
    padding-left: 40px; }
.block-action.arrow a:before {
    position: absolute;
    top: 7px;
    left: 20px;
    content: '';
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black; }
.block-action.arrow a.active:before {
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid black;
    border-top: none; }

.mobile-slider-full {
    position: relative; }
@media screen and (max-width: 480px) {
    .mobile-slider-full {
        padding-bottom: 20px; } }
@media screen and (max-width: 480px) {
    .mobile-slider-full .slides .item:nth-child(2n+1) {
        clear: none; } }
.mobile-slider-full .slides .item .info {
    border-bottom: none; }
.mobile-slider-full .flex-control-nav {
    left: 0;
    bottom: 0; }
.mobile-slider-full .flex-control-nav a {
    background: black;
    width: 10px;
    height: 10px;
    border: 2px solid black;
    box-shadow: none; }
.mobile-slider-full .flex-control-nav a.flex-active {
    background: white; }

.theme-area {
    color: #333333; }
.theme-area .description {
    font-size: 16px;
    margin-bottom: 15px; }
.theme-area .sub-title {
    font-size: 20px;
    color: black;
    margin-bottom: 10px; }
.theme-area .main-image img {
    width: 100%; }
.theme-area .block-action {
    margin-top: 20px; }
.theme-area .panel {
    float: left;
    width: 50%; }
.theme-area .panel.left-panel {
    padding-right: 10px;
    border-right: 1px solid #d4d4d4; }
.theme-area .panel.right-panel {
    padding-left: 10px; }
@media screen and (max-width: 480px) {
    .theme-area .panel {
        width: 100%; }
    .theme-area .panel.left-panel, .theme-area .panel.right-panel {
        padding: 0;
        border: none; }
    .theme-area .panel.left-panel {
        margin-bottom: 25px; } }
.theme-area .items {
    margin-top: 10px;
    border-top: 1px solid #d4d4d4;
    padding-top: 10px; }
@media screen and (max-width: 480px) {
    .theme-area .items {
        border: none; } }
.theme-area .items .item {
    padding-bottom: 10px;
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 10px;
    position: relative; }
@media screen and (max-width: 480px) {
    .theme-area .items .item {
        border: none; } }
.theme-area .items .item:last-child {
    border-bottom: none;
    margin-bottom: 0; }
.theme-area .items .left-panel {
    margin-right: 30px; }
.theme-area .items .left-panel .header .title {
    margin-bottom: 5px; }
.theme-area .items .left-panel .header .title a {
    font-size: 1.7em;
    font-weight: bold;
    color: black;
    line-height: 20px; }
.theme-area .items .left-panel .header .title a:hover {
    color: #d33b38; }
.theme-area .items .left-panel .header .meta {
    color: black;
    font-size: 1.4em; }
.theme-area .items .left-panel .header .meta .year {
    font-weight: bold; }
.theme-area .items .left-panel .info .meta {
    margin-bottom: 10px;
    padding-top: 10px;
    display: inline-block;
    width: 180px; }
.theme-area .items .left-panel .info .meta .year {
    font-weight: bold;
    font-size: 1.4em;
    color: #d33b38;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -2px; }
.theme-area .items .left-panel .info .sub, .theme-area .items .left-panel .info .content, .theme-area .items .left-panel .info .notice {
    color: #666666;
    font-size: 1.3em;
    margin-bottom: 10px; }
.theme-area .items .left-panel .info .content a, .theme-area .items .left-panel .info .notice a {
    color: #666666; }
.theme-area .items .left-panel .info .content a:hover, .theme-area .items .left-panel .info .notice a:hover {
    color: #d33b38; }
.theme-area .items .left-panel .info .notice {
    display: inline-block;
    position: relative;
    top: -3px; }
@media screen and (max-width: 480px) {
    .theme-area .items .left-panel .info .notice {
        display: block; } }
.theme-area .items .left-panel .info .content {
    font-weight: bold; }
.theme-area .items .left-panel .type {
    display: inline-block;
    margin-left: 15px;
    font-weight: bold; }
.theme-area .items .left-panel .type a {
    color: #666666; }
.theme-area .items .left-panel .type a:hover {
    color: #d33b38; }
.theme-area .items .right-panel {
    float: right;
    width: 30px;
    text-align: center; }
.theme-area .items .right-panel .info {
    padding-top: 3px; }
.theme-area .items .right-panel .info .type {
    margin-bottom: 15px; }
.theme-area .items .footer {
    margin-top: 10px; }
@media screen and (min-width: 481px) {
    .theme-area .items .footer {
        text-align: right; } }
@media screen and (max-width: 480px) {
    .theme-area .items .footer {
        text-align: left; } }
.theme-area .items .footer .price {
    font-size: 1.2em;
    font-weight: bold;
    color: #666666;
    margin-right: 10px;
    display: inline-block; }
@media screen and (max-width: 480px) {
    .theme-area .items .footer .price {
        display: block;
        margin-bottom: 10px; } }
.theme-area .items .footer .action {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 5px 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.theme-area .items .footer .action:hover {
    background: #d94247;
    box-shadow: none; }

.history-area {
    margin-bottom: 20px;
    margin-top: -20px; }
@media screen and (max-width: 1024px) {
    .history-area {
        padding: 0 20px; } }
@media screen and (max-width: 480px) {
    .history-area {
        padding: 0 10px;
        margin-top: 20px; } }
.history-area .items:after {
    content: "";
    display: table;
    clear: both; }
.history-area .items .item {
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #d4d4d4; }
@media screen and (min-width: 481px) {
    .history-area .items .item {
        width: 32.66%;
        margin-right: 1%;
        float: left; }
    .history-area .items .item:nth-child(3n) {
        margin-right: 0; }
    .history-area .items .item:nth-child(3n+1) {
        clear: both; } }
.history-area .items .item .image a, .history-area .items .item .image img {
    width: 100%;
    display: block; }
.history-area .items .item .title {
    padding: 15px;
    font-size: 1.6em;
    min-height: 70px;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
.history-area .items .item .title a {
    color: #454545;
    font-weight: bold;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.history-area .items .item .title a:hover {
    color: #d33b38; }

.history-area-full {
    margin-top: 40px; }
.history-area-full .item {
    padding: 15px 0;
    border-top: 1px solid #d4d4d4; }
.history-area-full .item:after {
    content: "";
    display: table;
    clear: both; }
.history-area-full .item .image {
    width: 160px;
    float: left; }
@media screen and (max-width: 480px) {
    .history-area-full .item .image {
        width: 90px; } }
.history-area-full .item .image img {
    width: 100%; }
.history-area-full .item .info {
    margin-left: 170px;
    padding-top: 5px; }
@media screen and (max-width: 480px) {
    .history-area-full .item .info {
        margin-left: 100px; } }
.history-area-full .item .info .name a {
    color: #282222;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 8px; }
.history-area-full .item .info .name a:hover {
    color: #d33b38; }
.history-area-full .item .info .title a {
    color: #252424;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 1.8em;
    margin-bottom: 5px; }
.history-area-full .item .info .title a:hover {
    color: #d33b38; }
.history-area-full .item .info .description {
    color: #666666;
    font-size: 1.4em;
    margin-bottom: 10px; }
.history-area-full .item .info .action {
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic;
    color: #282222; }
.history-area-full .item .info .action:hover {
    color: #d33b38; }

.article-area a {
    color: #282222; }
.article-area a:hover {
    color: #d33b38; }
.article-area em {
    font-weight: bold; }
.article-area .main-title {
    margin-bottom: 0; }
.article-area .publish-date {
    color: #282222;
    font-size: 1.8em;
    margin-bottom: 20px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.article-area .nav {
    margin-bottom: 20px; }
.article-area .nav:after {
    content: "";
    display: table;
    clear: both; }
.article-area .nav .back {
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic;
    float: left;
    padding-top: 12px; }
.article-area .nav .back .icon {
    margin-right: 10px;
    top: 1px; }
.article-area .nav .favorite, .article-area .nav .share {
    font-weight: bold;
    font-size: 1.1em;
    margin-right: 10px;
    float: right;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    height: 38px; }
.article-area .nav .favorite {
    padding: 3px 10px 0; }
.article-area .nav .favorite .icon {
    top: 5px;
    margin-right: 10px; }
.article-area .nav .share {
    padding: 5px 10px 0; }
@media screen and (max-width: 480px) {
    .article-area .nav .share {
        margin-right: 0; } }
.article-area .nav .share .icon {
    top: 4px;
    margin-right: 10px; }
.article-area .nav .print {
    float: right;
    padding-left: 10px;
    padding-top: 6px;
    border-left: 1px solid black;
    height: 30px;
    margin-top: 4px; }
@media screen and (max-width: 480px) {
    .article-area .nav .print {
        display: none; } }
.article-area .image {
    margin-bottom: 20px;
    position: relative; }
.article-area .image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
@media screen and (max-width: 480px) {
    .article-area .image .slider-image-wrapper {
        background: #1f1f1f;
        padding: 5px 32px; } }
.article-area .image .slider-image {
    height: 295px;
    width: 55px;
    position: absolute;
    top: 30px;
    right: 5px; }
@media screen and (max-width: 480px) {
    .article-area .image .slider-image {
        height: auto;
        width: 235px;
        position: relative;
        top: auto;
        right: auto; } }
.article-area .image .slider-image .slides li {
    width: 55px;
    height: 295px;
    display: none; }
@media screen and (max-width: 480px) {
    .article-area .image .slider-image .slides li {
        width: 55px;
        margin-right: 5px;
        height: auto; } }
.article-area .image .slider-image .slides li a {
    height: 60px;
    padding-bottom: 5px;
    display: block; }
@media screen and (max-width: 480px) {
    .article-area .image .slider-image .slides li a {
        width: 55px;
        height: 55px;
        padding-bottom: 0; } }
.article-area .image .slider-image .slides li img {
    height: 100%;
    display: block; }
.article-area .image .flex-prev, .article-area .image .flex-next {
    width: 21px;
    height: 10px;
    left: 15px;
    top: auto;
    bottom: auto;
    overflow: hidden;
    opacity: 1;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s; }
.article-area .image .flex-prev:before, .article-area .image .flex-next:before {
    content: "";
    display: block;
    width: 0;
    height: 100%; }
.article-area .image .flex-prev {
    background: url("../../public/img/sprites.png");
    top: 0;
    background-position: -86px -268px; }
@media screen and (max-width: 480px) {
    .article-area .image .flex-prev {
        top: 40px;
        left: -15px;
        width: 9px;
        height: 20px;
        background-position: -120px -108px; } }
.article-area .image .flex-next {
    background: url("../../public/img/sprites.png");
    bottom: -20px;
    background-position: -86px -282px; }
@media screen and (max-width: 480px) {
    .article-area .image .flex-next {
        bottom: auto;
        left: auto;
        top: 40px;
        right: -15px;
        width: 10px;
        height: 20px;
        background-position: -120px -189px; } }
.article-area .image-title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.8em;
    color: white;
    background: #252424;
    padding: 10px 20px; }
@media screen and (max-width: 480px) {
    .article-area .image-title {
        font-size: 1.4em; } }
.article-area .summary {
    font-size: 2em;
    margin-bottom: 10px; }
@media screen and (max-width: 480px) {
    .article-area .summary {
        font-size: 1.6em; } }
.article-area .content {
    font-size: 1.5em;
    margin-bottom: 10px; }
@media screen and (max-width: 480px) {
    .article-area .content {
        font-size: 1.4em; } }
.article-area .content .heading {
    font-weight: bold;
    color: #d33b38;
    font-size: 1.1em;
    margin-bottom: 5px;
    display: inline-block; }
.article-area .content .block-right {
    float: right;
    margin: 2px 0 20px 20px;
    padding: 20px;
    width: 220px;
    background: #f4f4f4; }
@media screen and (max-width: 480px) {
    .article-area .content .block-right {
        width: 100%;
        margin: 10px 0; } }
.article-area .author {
    font-weight: bold;
    font-size: 1.3em;
    color: #333333;
    margin-bottom: 20px; }
.article-area .social {
    padding: 20px 0;
    margin-bottom: 20px;
    border-top: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4; }
.article-area .social:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .article-area .social {
        padding: 15px 0; } }
.article-area .social .label {
    font-size: 1.7em;
    color: #282222;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    float: left;
    position: relative;
    top: 8px; }
@media screen and (max-width: 480px) {
    .article-area .social .label {
        top: 0;
        margin-bottom: 10px;
        float: none; } }
.article-area .social .addthis {
    float: right; }
@media screen and (max-width: 480px) {
    .article-area .social .addthis {
        float: none; } }

.comment-area .title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 1.7em;
    margin-bottom: 20px; }
.comment-area .title strong {
    font-size: 1.5em; }
.comment-area .comment-form {
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    background: #f4f4f4;
    padding: 20px; }
.comment-area .comment-form:after {
    content: "";
    display: table;
    clear: both; }
.comment-area .comment-form label {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    color: #666666;
    margin-bottom: 5px;
    display: inline-block; }
.comment-area .comment-form .vote {
    margin-bottom: 10px; }
.comment-area .comment-form .vote .star {
    width: 18px;
    height: 18px;
    background: #dde1ea;
    border-radius: 100%; }
.comment-area .comment-form .vote .star.active, .comment-area .comment-form .vote .star.hover {
    background: #4b4b4b; }
.comment-area .comment-form .comment {
    margin-bottom: 10px; }
.comment-area .comment-form .comment textarea {
    width: 100%;
    height: 100px;
    font-size: 1.8em;
    color: #454545;
    border: none;
    border-radius: 3px;
    padding: 10px; }
@media screen and (max-width: 480px) {
    .comment-area .comment-form .comment textarea {
        font-size: 1.5em; } }
.comment-area .comment-form .action label {
    cursor: pointer; }
.comment-area .comment-form .action .social {
    float: left;
    padding-top: 10px; }
.comment-area .comment-form .action .social .label {
    font-size: 1.4em;
    color: #454545;
    margin-right: 10px; }
@media screen and (max-width: 480px) {
    .comment-area .comment-form .action .social .label {
        display: none; } }
.comment-area .comment-form .action .social .checkbox {
    margin-right: 15px;
    display: inline-block; }
.comment-area .comment-form .action .social .checkbox:last-child {
    margin: 0; }
.comment-area .comment-form .action .submit {
    float: right; }
.comment-area .comment-form .action .submit input {
    font-size: 1.1em;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.comment-area .comment-form .action .submit input:hover {
    background: #323232;
    box-shadow: none; }
.comment-area .comments .comment {
    padding: 20px;
    margin-bottom: 15px;
    background: #f4f4f4;
    border-radius: 3px;
    color: #454545;
    font-size: 1.4em; }
.comment-area .comments .comment .notice {
    color: #666666;
    margin-bottom: 10px; }

.comment-area .comments .notice .rating {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.comment-form .vote .stars {
    font-size: 0;
    vertical-align: middle;
}
.comment-form .vote .stars .icon-circle {
    color: #dde1ea;
    cursor: pointer;
    font-size: 11px;
    width: auto; height: auto;
    margin: 0 2px;
}
.comment-form .vote .stars .icon-circle:before {
    margin: 0;
    width: auto; height: auto;
}
.comment-form .vote .stars .icon-circle.icon-black {
    color: #000;
}

#bloc-comments-list .notice .rating {
    font-size: 0;
    top: 0 !important;
}
#bloc-comments-list .notice .rating .icon-circle {
    color: #dde1ea;
    font-size: 11px;
    width: auto; height: auto;
    margin: 0 2px;
}
#bloc-comments-list .notice .rating .icon-circle.icon-black {
    color: #000;
}
#bloc-comments-list .notice .rating .icon-circle:before {
    margin: 0;
    width: auto; height: auto;
}
#bloc-comments-list .notice .icon-info {
    background: none;
    font-size: 18px;
    line-height: 100%;

}
#bloc-comments-list .notice .icon-info:before {
    margin: 0;
}

.comment-area .comments .comment .meta {
    font-weight: bold;
    margin-bottom: 10px; }
.comment-area .comments .comment .content {
    margin: 0;
    padding: 0;
    min-height: initial; }

.pagination {
    border-top: 1px solid #d4d4d4;
    padding-top: 20px;
    margin-bottom: 20px;
    text-align: right; }
@media screen and (max-width: 1024px) {
    .pagination {
        text-align: center; } }
@media screen and (max-width: 480px) {
    .pagination {
        margin-left: -10px;
        margin-right: -10px;
        padding-top: 0; } }
.pagination ul {
    background: #f5f5f5;
    display: inline-block; }
@media screen and (max-width: 480px) {
    .pagination ul {
        display: block;
        text-align: center; } }
.pagination .active a, .pagination a:hover {
    color: #252424; }
.pagination a {
    padding: 10px 5px;
    color: #a9a9a9;
    font-weight: bold;
    font-size: 1.2em; }
.pagination .icon {
    margin: 0 10px; }

.theme-area.product-page .block-action a {
    padding-left: 40px; }
.theme-area.product-page .block-action a:before {
    position: absolute;
    top: 7px;
    left: 20px;
    content: '';
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black; }
.theme-area.product-page .block-action a.active:before {
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid black;
    border-top: none; }
.theme-area.product-page .left-panel {
    margin-left: 120px; }
@media screen and (max-width: 480px) {
    .theme-area.product-page .left-panel {
        margin-left: 45px; } }
.theme-area.product-page .wine-type {
    float: left;
    width: 120px;
    padding: 5px 20px 0;
    text-align: center;
    font-size: 1.3em;
    color: #999999; }
@media screen and (max-width: 480px) {
    .theme-area.product-page .wine-type {
        width: 45px;
        padding: 5px 0 0;
        text-align: left; } }
.theme-area.product-page .wine-type .text {
    margin-top: 10px; }
@media screen and (max-width: 480px) {
    .theme-area.product-page .wine-type .text {
        display: none; } }
@media screen and (min-width: 481px) {
    .theme-area.product-page .footer {
        position: absolute;
        bottom: 20px;
        right: 0; } }
@media screen and (max-width: 480px) {
    .theme-area.product-page .footer {
        margin-left: 45px; } }

.info-area .form-title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    color: #282222;
    font-size: 1.7em;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d4d4d4; }
.info-area .form {
    margin-bottom: 50px; }
.info-area .form-row {
    margin-bottom: 10px; }
.info-area .form-row:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .info-area .form-row {
        margin-bottom: 25px; } }
@media screen and (min-width: 481px) {
    .info-area .form-row.date-row {
        margin: 40px 0; }
    .info-area .form-row.date-row .heading {
        padding-top: 20px; } }
.info-area .form-row .heading {
    color: #aaaaaa;
    font-weight: bold;
    font-size: 1.2em;
    width: 200px;
    float: left; }
@media screen and (max-width: 480px) {
    .info-area .form-row .heading {
        width: 100%;
        margin-bottom: 10px; } }
.info-area .form-row .value {
    margin-left: 200px;
    font-size: 1.4em;
    color: #333333; }
@media screen and (max-width: 480px) {
    .info-area .form-row .value {
        margin-left: 0; } }
.info-area .form-row .value input {
    position: relative;
    top: 2px; }
.info-area .form-row .value label {
    display: inline-block;
    margin-right: 20px; }
.info-area .form-row .time-row {
    margin-bottom: 5px; }
.info-area .form-row .time-row .name {
    width: 100px;
    float: left; }
.info-area .form-row .time-row .time {
    margin-left: 100px; }
.info-area .map {
    height: 200px; }
.info-area .map img {
    width: 100%; }

.bn-half-area {
    font-weight: bold;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.8em; }
.bn-half-area:after {
    content: "";
    display: table;
    clear: both; }
.bn-half-area, .bn-half-area a {
    color: white; }
.bn-half-area .items {
    margin: 0 -10px; }
@media screen and (max-width: 480px) {
    .bn-half-area .items {
        margin: 0; } }
.bn-half-area .item {
    width: 50%;
    float: left;
    padding: 0 10px;
    border-radius: 5px;
    position: relative;
    min-width: 300px; }
@media screen and (max-width: 480px) {
    .bn-half-area .item {
        width: 100%;
        padding: 0; } }
.bn-half-area .item img {
    width: 100%; }
.bn-half-area .item .info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 25px;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
.bn-half-area .item .info .i {
    width: 60px;
    float: left; }
.bn-half-area .item .info .text {
    margin-left: 60px; }

.fiche-area .header {
    margin-bottom: 50px; }
.fiche-area .header:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .fiche-area .header {
        margin-bottom: 25px; } }
.fiche-area .header .image {
    width: 120px;
    float: left;
    text-align: center;
    padding: 5px 20px;
    color: #999999;
    font-size: 1.6em; }
@media screen and (max-width: 480px) {
    .fiche-area .header .image {
        width: 30px;
        padding: 5px 0 0; } }
.fiche-area .header .image img {
    max-width: 100%;
    display: inline-block;
    margin-bottom: 15px; }
.fiche-area .header .info {
    width: 520px;
    float: right;
    color: #282222; }
@media screen and (max-width: 1024px) {
    .fiche-area .header .info {
        width: 600px; } }
@media screen and (max-width: 480px) {
    .fiche-area .header .info {
        width: 270px;
        padding-left: 10px; } }
.fiche-area .header .info .main-title {
    margin-bottom: 0; }
.fiche-area .header .info .date {
    margin-bottom: 40px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600; }
@media screen and (max-width: 480px) {
    .fiche-area .header .info .date {
        margin-bottom: 10px; } }
.fiche-area .header .info .date .year {
    font-size: 2.2em; }
@media screen and (max-width: 480px) {
    .fiche-area .header .info .date .year {
        font-size: 1.8em; } }
.fiche-area .header .info .date .type {
    font-size: 1.8em; }
.fiche-area .header .info .meta {
    width: 370px;
    float: left; }
@media screen and (max-width: 480px) {
    .fiche-area .header .info .meta {
        width: 100%;
        float: none;
        margin-bottom: 15px; } }
.fiche-area .header .info .meta span {
    display: inline-block; }
.fiche-area .header .info .meta .year {
    font-weight: bold;
    font-size: 1.8em;
    color: #d33b38;
    margin-right: 15px; }
.fiche-area .header .info .meta .rating {
    margin-right: 15px; }
.fiche-area .header .info .meta .hint {
    font-size: 1.7em;
    color: #666666; }
.fiche-area .header .info .action {
    width: 140px;
    float: right;
    text-align: right;
    position: relative;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: -20px; }
@media screen and (max-width: 480px) {
    .fiche-area .header .info .action {
        width: 100%;
        float: none;
        text-align: left;
        margin-top: 0; } }
.fiche-area .header .info .action a {
    color: #d33b38; }
@media screen and (max-width: 480px) {
    .fiche-area .header .info .action .icon {
        width: 23px;
        height: 21px;
        background-position: -32px -179px; } }
.fiche-area .table-content {
    text-align: center;
    border-top: 1px solid #d4d4d4;
    margin-bottom: 20px; }
@media screen and (max-width: 480px) {
    .fiche-area .table-content {
        margin-left: -10px;
        margin-right: -10px; } }
.fiche-area .table-content > table {
    width: 100%; }
@media screen and (max-width: 480px) {
    .fiche-area .table-content > table.table-desktop {
        display: none; } }
.fiche-area .table-content > table.table-phone {
    display: none; }
@media screen and (max-width: 480px) {
    .fiche-area .table-content > table.table-phone {
        display: table; } }
.fiche-area .table-content tr {
    background: #f4f4f4;
    border-bottom: 1px solid #d4d4d4; }
.fiche-area .table-content tr.actions {
    background: white; }
.fiche-area .table-content td {
    padding: 10px 0;
    vertical-align: middle;
    border-right: 1px solid #d4d4d4; }
.fiche-area .table-content td:last-child {
    border: none; }
@media screen and (max-width: 480px) {
    .fiche-area .table-content td {
        width: 50%; } }
.fiche-area .table-content a {
    color: #333333; }
.fiche-area .table-content a:hover {
    color: #d33b38; }
.fiche-area .table-content > table table {
    width: 100%; }
.fiche-area .table-content > table table td, .fiche-area .table-content > table table tr {
    border: none;
    width: auto; }
.fiche-area .table-content .heading {
    font-size: 1.2em;
    font-weight: bold;
    color: #666666; }
.fiche-area .table-content .name {
    font-size: 2.5em;
    font-weight: bold;
    color: #333333; }
@media screen and (max-width: 480px) {
    .fiche-area .table-content .name {
        font-size: 2.2em; } }
.fiche-area .table-content .sub {
    font-size: 1.5em;
    font-weight: bold;
    color: #333333; }
.fiche-area .table-content .action {
    color: #454545;
    font-weight: bold;
    font-size: 1.2em; }
.fiche-area .table-content .action:hover {
    color: #d33b38; }
.fiche-area .table-content .action .icon {
    margin-right: 5px; }
.fiche-area .extra:after {
    content: "";
    display: table;
    clear: both; }
.fiche-area .extra .right-panel {
    width: 170px;
    float: right;
    text-align: right;
    margin-bottom: 20px; }
@media screen and (max-width: 480px) {
    .fiche-area .extra .right-panel {
        width: 100%; } }
.fiche-area .extra .right-panel .action {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 5px; }
.fiche-area .extra .right-panel .action:hover {
    background: #d94247;
    box-shadow: none; }
@media screen and (max-width: 480px) {
    .fiche-area .extra .right-panel .action {
        width: 100%;
        text-align: center; } }
.fiche-area .extra .right-panel .hint {
    font-size: 1.2em;
    color: #747474; }
.fiche-area .extra .left-panel .type {
    color: #666666;
    font-size: 1.4em;
    margin: 10px 0;
    display: inline-block; }
.fiche-area .extra .left-panel .type a {
    font-weight: bold;
    color: #666666; }
.fiche-area .extra .left-panel .type a:hover {
    color: #d33b38; }
.fiche-area .extra .left-panel .action {
    color: #454545;
    font-weight: bold;
    font-size: 1.2em; }
.fiche-area .extra .left-panel .action:hover {
    color: #d33b38; }
.fiche-area .extra .left-panel .action .icon {
    margin-right: 5px; }
.fiche-area .social {
    margin-bottom: 20px; }
.fiche-area .social:after {
    content: "";
    display: table;
    clear: both; }
.fiche-area .social .favorite, .fiche-area .social .add {
    padding: 10px 15px 10px 43px;
    font-weight: bold;
    font-size: 1.1em;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    color: #282222;
    position: relative; }
.fiche-area .social .favorite:hover, .fiche-area .social .add:hover {
    color: #d33b38; }
@media screen and (max-width: 480px) {
    .fiche-area .social .favorite, .fiche-area .social .add {
        margin-bottom: 10px; } }
.fiche-area .social .favorite .icon, .fiche-area .social .add .icon {
    position: absolute;
    top: 8px;
    left: 10px; }
.fiche-area .social .add {
    margin-right: 0; }
.fiche-area .social .left-panel {
    width: 430px;
    float: left;
    margin-bottom: 10px; }
@media screen and (max-width: 480px) {
    .fiche-area .social .left-panel {
        width: 100%; } }
.fiche-area .social .right-panel {
    width: 210px;
    float: right;
    text-align: right; }
@media screen and (max-width: 480px) {
    .fiche-area .social .right-panel {
        width: 100%;
        text-align: left;
        margin-bottom: 30px; } }
.fiche-area .comment {
    background: #d33b38;
    padding: 15px;
    color: white; }
.fiche-area .comment a {
    color: white;
    font-style: italic;
    font-weight: bold; }
.fiche-area .comment .title {
    font-weight: bold;
    font-size: 2em; }
@media screen and (max-width: 480px) {
    .fiche-area .comment .title {
        font-size: 1.8em; } }
.fiche-area .comment .content {
    font-size: 1.4em; }
.fiche-area .comment .action {
    font-size: 1.5em; }

.detail-area {
    border-bottom: 1px solid #d4d4d4; }
.detail-area .image {
    width: 230px;
    float: left;
    margin-bottom: 15px; }
@media screen and (max-width: 480px) {
    .detail-area .image {
        width: 100%; } }
.detail-area .image img {
    max-width: 100%; }
.detail-area .info {
    width: 400px;
    float: left;
    padding-bottom: 30px;
    margin-left: 10px; }
@media screen and (max-width: 480px) {
    .detail-area .info {
        width: 100%;
        margin: 0; } }
.detail-area .info .row {
    clear: both;
    margin-bottom: 5px; }
.detail-area .info .name {
    display: inline-block;
    width: 50%;
    font-weight: bold;
    font-size: 1.2em;
    color: #aaaaaa;
    padding-right: 30px; }
@media screen and (max-width: 480px) {
    .detail-area .info .name {
        width: auto;
        padding-right: 5px; } }
.detail-area .info .value {
    display: inline-block;
    width: 45%;
    font-size: 1.4em;
    color: #333333; }
@media screen and (max-width: 480px) {
    .detail-area .info .value {
        width: auto; } }

.detail-area2 {
    border-bottom: 1px solid #d4d4d4; }
.detail-area2 .info {
    padding-bottom: 30px; }
.detail-area2 .info .row {
    clear: both;
    margin-bottom: 5px; }
.detail-area2 .info .row:after {
    content: "";
    display: table;
    clear: both; }
.detail-area2 .info .name {
    width: 230px;
    float: left;
    font-weight: bold;
    font-size: 1.2em;
    color: #aaaaaa;
    padding-right: 5px; }
@media screen and (max-width: 480px) {
    .detail-area2 .info .name {
        display: inline-block;
        width: auto;
        margin-bottom: 10px; } }
.detail-area2 .info .name img {
    width: 100%;
    display: block;
    margin-top: 5px; }
.detail-area2 .info .value {
    width: 400px;
    float: left;
    font-size: 1.4em;
    color: #333333;
    margin-left: 10px; }
@media screen and (max-width: 480px) {
    .detail-area2 .info .value {
        display: inline-block;
        width: auto;
        margin-left: 0; } }
.detail-area2 .info .value address {
    font-weight: bold;
    font-style: normal; }
.detail-area2 .info .phone {
    display: inline-block;
    margin-bottom: 10px;
    border: 1px solid #d4d4d4;
    color: #252424;
    border-radius: 3px;
    padding: 10px 10px 10px 25px;
    font-size: 11px;
    position: relative;
    font-weight: bold;
    min-width: 180px;
    text-align: center; }
@media screen and (max-width: 480px) {
    .detail-area2 .info .phone {
        width: 100%;
        text-align: center;
        padding: 10px; } }
.detail-area2 .info .phone a {
    color: #252424; }
.detail-area2 .info .phone .icon {
    position: absolute;
    top: 10px;
    left: 5px; }
@media screen and (max-width: 480px) {
    .detail-area2 .info .phone .icon {
        position: static; } }
.detail-area2 .info .action {
    font-size: 11px;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.detail-area2 .info .action:hover {
    background: #323232;
    box-shadow: none; }
@media screen and (max-width: 480px) {
    .detail-area2 .info .action {
        width: 100%;
        text-align: center; } }
.detail-area2 .map {
    margin-bottom: 15px; }
.detail-area2 .map img {
    width: 100%; }
.detail-area2.detail-area2-map {
    border: none; }
@media screen and (max-width: 480px) {
    .detail-area2.detail-area2-map .info .name, .detail-area2.detail-area2-map .info .value {
        width: 100%; } }
@media screen and (max-width: 480px) {
    .detail-area2.detail-area2-map .info .value {
        margin-bottom: 10px; } }

.search-sidebar {
    width: 240px;
    float: left;
    padding: 0 20px;
    color: #282222; }
@media screen and (max-width: 1024px) {
    .search-sidebar {
        width: 270px; } }
.search-sidebar .tags .title {
    font-size: 1.8em;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 15px; }
.search-sidebar .tags .item {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 10px;
    padding: 5px 10px 5px 5px;
    background: white;
    font-size: 1.3em;
    position: relative;
    border: 1px solid #d9d9d9}
.search-sidebar .tags .item:after {
    position: absolute;
    content: '';
    top: -2px;
    right: 2px;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.1em;
    padding: 5px; }
.search-sidebar .filters {
    background: #f4f4f4; }
.search-sidebar .filters .title {
    font-size: 1.6em;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    padding: 15px 12px; }
.search-sidebar .filter {
    border-bottom: 1px solid #e3e3e3;
    color: #333333; }
.search-sidebar .filter.active .filter-title .plus, .search-sidebar .filter.active .filter-title-type .plus {
    display: none; }
.search-sidebar .filter.active .filter-title .minus, .search-sidebar .filter.active .filter-title-type .minus {
    display: block; }
.search-sidebar .filter .hr {
    border-bottom: 1px solid #e3e3e3;
    height: 1px;
    margin: 10px -15px; }
.search-sidebar .filter .filter-title, .search-sidebar .filter .filter-title-type {
    padding: 10px 45px;
    background: white;
    position: relative;
    color: #d33b38;
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer; }
.search-sidebar .filter .filter-title .title-icon, .search-sidebar .filter .filter-title-type .title-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%); }
.search-sidebar .filter .filter-title .hint, .search-sidebar .filter .filter-title-type .hint {
    position: absolute;
    top: 6px;
    right: 15px; }
.search-sidebar .filter .filter-title .hint .icon-plus-dark, .search-sidebar .filter .filter-title-type .hint .icon-plus-dark {
    top: 5px; }
.search-sidebar .filter .filter-title .minus, .search-sidebar .filter .filter-title-type .minus {
    display: none; }
.search-sidebar .filter .filter-content-wrapper {
    padding: 15px; }
.search-sidebar .filter .filter-search {
    position: relative;
    margin-bottom: 15px; }
.search-sidebar .filter .filter-search input {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    padding: 8px 40px 5px 10px;
    border: 1px solid #cccccc;
    color: #666666;
    font-size: 1.3em; }
.search-sidebar .filter .filter-search button {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px; }
.search-sidebar .filter .filter-slide .slider-header {
    position: relative;
    height: 25px;
    left: -12px; }
.search-sidebar .filter .filter-slide .slider-header .value {
    color: #4b4b4b;
    font-weight: bold;
    font-size: 1.1em;
    position: absolute;
    top: 0;
    padding-bottom: 10px;
    width: 9%;
    text-align: center; }
.search-sidebar .filter .filter-slide .slider-header .value:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 5px;
    background: black;
    opacity: .2; }
.search-sidebar .filter .filter-slide .hint {
    font-size: 1.4em; }
@media screen and (max-width: 1024px) {
    .search-sidebar .filter .filter-slide .hint {
        font-size: 1.1em; } }
.search-sidebar .filter .filter-slide .price-slider {
    margin-bottom: 15px; }
.search-sidebar .filter .filter-content {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden; }
.search-sidebar .filter .filter-content label {
    display: block;
    margin-bottom: 3px;
    font-size: 1.3em;
    padding-left: 25px;
    position: relative; }
@media screen and (max-width: 1024px) {
    .search-sidebar .filter .filter-content label {
        padding-left: 20px; } }
.search-sidebar .filter .filter-content label .input {
    display: inline-block;
    width: 25px;
    position: absolute;
    top: 2px;
    left: 0; }
.search-sidebar .footer-search {
    padding: 15px;
    background: #dde1ea;
    color: #666666;
    font-size: 1.3em; }
.search-sidebar .footer-search input {
    height: 40px;
    border-radius: 3px;
    padding: 8px 10px;
    width: 215px;
    border: none;
    display: block;
    float: left; }
@media screen and (max-width: 1024px) {
    .search-sidebar .footer-search input {
        width: 150px;
        border-radius: 3px 0 0 3px; } }
.search-sidebar .footer-search button {
    width: 45px;
    text-align: center;
    height: 40px;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    padding-top: 5px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    display: block;
    float: left;
    margin-left: 10px; }
.search-sidebar .footer-search button:hover {
    background: #323232;
    box-shadow: none; }
@media screen and (max-width: 1024px) {
    .search-sidebar .footer-search button {
        border-radius: 0 3px 3px 0;
        margin: 0; } }

.result-area {
    width: 640px;
    float: left; }
@media screen and (max-width: 1024px) {
    .result-area {
        width: 480px; } }
@media screen and (max-width: 480px) {
    .result-area {
        width: 100%;
        padding: 0 10px; } }
.result-area .result-header {
    margin-bottom: 10px; }
.result-area .result-header:after {
    content: "";
    display: table;
    clear: both; }
.result-area .result-header .number {
    font-size: 1.5em;
    color: #333;
    width: 50%;
    float: left;
    padding-top: 10px; }
@media screen and (max-width: 480px) {
    .result-area .result-header .number {
        width: 100%;
        margin-bottom: 10px; } }
.result-area .result-header .number strong {
    color: black; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .result-area .result-header .number .extra {
        display: none; } }
.result-area .result-header .tags {
    margin-bottom: 15px; }
.result-area .result-header .tags .item {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 10px;
    padding: 5px 25px 5px 5px;
    background: #f4f4f4;
    font-size: 1.3em;
    position: relative; }
.result-area .result-header .tags .item:after {
    position: absolute;
    content: 'x';
    top: 2px;
    right: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.1em; }
.result-area .filter-area {
    width: 45%;
    float: right;
    text-align: right;
    margin-bottom: 0; }
@media screen and (max-width: 480px) {
    .result-area .filter-area {
        width: 100%;
        text-align: left; } }
.result-area .results .item {
    padding-bottom: 10px;
    border-top: 1px solid #d4d4d4;
    margin-bottom: 10px;
    padding-top: 10px;
    position: relative; }
.result-area .results .wine-type {
    float: left;
    width: 120px;
    padding: 5px 20px 0;
    text-align: center;
    font-size: 1.3em;
    color: #999999; }
@media screen and (max-width: 1024px) {
    .result-area .results .wine-type {
        width: 60px;
        padding: 5px 0 0; } }
@media screen and (max-width: 480px) {
    .result-area .results .wine-type {
        width: 45px;
        text-align: left; } }
.result-area .results .wine-type .text {
    margin-top: 10px; }
@media screen and (max-width: 480px) {
    .result-area .results .wine-type .text {
        display: none; } }
.result-area .results .right-panel {
    margin-left: 120px;
    padding-right: 110px; }
@media screen and (max-width: 1024px) {
    .result-area .results .right-panel {
        margin-left: 70px; } }
@media screen and (max-width: 480px) {
    .result-area .results .right-panel {
        margin-left: 45px;
        padding-right: 0; } }
.result-area .results .right-panel .header .title {
    margin-bottom: 5px; }
.result-area .results .right-panel .header .title a {
    font-size: 1.7em;
    font-weight: bold;
    color: black;
    line-height: 20px; }
.result-area .results .right-panel .header .title a:hover {
    color: #d33b38; }
.result-area .results .right-panel .header .meta {
    color: black;
    font-size: 1.4em; }
.result-area .results .right-panel .header .meta .year {
    font-weight: bold; }
.result-area .results .right-panel .info .meta {
    margin-bottom: 10px;
    padding-top: 10px;
    display: inline-block;
    width: 150px; }
.result-area .results .right-panel .info .meta .year {
    font-weight: bold;
    font-size: 1.4em;
    color: #d33b38;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -2px; }
.result-area .results .right-panel .info .sub, .result-area .results .right-panel .info .content, .result-area .results .right-panel .info .notice {
    color: #666666;
    font-size: 1.3em;
    margin-bottom: 10px; }
.result-area .results .right-panel .info .content a, .result-area .results .right-panel .info .notice a {
    color: #666666; }
.result-area .results .right-panel .info .content a:hover, .result-area .results .right-panel .info .notice a:hover {
    color: #d33b38; }
.result-area .results .right-panel .info .notice {
    display: inline-block;
    position: relative;
    top: -3px; }
@media screen and (max-width: 480px) {
    .result-area .results .right-panel .info .notice {
        display: block; } }
.result-area .results .right-panel .info .content {
    font-weight: bold;
    margin-bottom: 0; }
.result-area .results .right-panel .type {
    display: inline-block;
    margin-left: 15px;
    font-weight: bold; }
@media screen and (min-width: 1025px) {
    .result-area .results .right-panel .type {
        margin-right: 15px; } }
.result-area .results .right-panel .type a {
    color: #666666; }
.result-area .results .right-panel .type a:hover {
    color: #d33b38; }
.result-area .results .footer {
    text-align: right;
    margin-top: 10px;
    width: 110px;
    min-height: 50px;
    position: absolute;
    right: 0;
    bottom: 10px; }
@media screen and (max-width: 480px) {
    .result-area .results .footer {
        text-align: left;
        position: static;
        margin-left: 45px;
        min-height: initial; } }
.result-area .results .footer .price {
    font-size: 1.2em;
    font-weight: bold;
    color: #666666;
    margin-right: 10px;
    margin-bottom: 5px; }
.result-area .results .footer .action {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 5px 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.result-area .results .footer .action:hover {
    background: #d94247;
    box-shadow: none; }
.result-area .result-footer {
    margin-bottom: 40px; }
.result-area .result-footer .action {
    float: left; }
@media screen and (max-width: 480px) {
    .result-area .result-footer .action {
        padding: 0 10px;
        margin-top: 10px;
        float: none; } }
.result-area .result-footer .action a {
    font-size: 1.1em;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.result-area .result-footer .action a:hover {
    background: #323232;
    box-shadow: none; }
@media screen and (max-width: 480px) {
    .result-area .result-footer .action a {
        width: 100%;
        text-align: center;
        font-size: 1.6em; } }
@media screen and (max-width: 1024px) {
    .result-area .result-footer .pagination {
        text-align: right; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .result-area .bn-half-area img {
        min-height: 100px; } }

.filter-area {
    color: #4b4b4b;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px; }
@media screen and (max-width: 480px) {
    .filter-area {
        width: 100%;
        text-align: left; } }
.filter-area label {
    color: #747474; }
.filter-area .sort {
    position: relative;
    min-width: 180px;
    display: inline-block;
    text-align: left;
    margin-left: 10px; }
@media screen and (max-width: 481px) {
    .filter-area .sort {
        min-width: 187px; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .filter-area .sort {
        min-width: 187px; } }
.filter-area .sort.full {
    width: 100%;
    margin-left: 0; }
.filter-area .sort.full .sort-popup {
    width: 100%; }
.filter-area .sort .current {
    width: 100%;
    position: relative;
    padding: 10px 30px 10px 15px;
    border: 1px solid #ccc;
    background: #f4f4f4;
    border-radius: 3px; }
.filter-area .sort .current .icon {
    position: absolute;
    top: 18px;
    right: 10px; }
.filter-area .sort .current.active .icon {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
.filter-area .sort .sort-popup {
    position: absolute;
    top: 100%;
    left: -1px;
    width: 100%;
    z-index: 2;
    background: #f4f4f4;
    border: 1px solid #ccc;
    border-top: none; }
.filter-area .sort .sort-popup li, .filter-area .sort .sort-popup a {
    display: block; }
.filter-area .sort .sort-popup a {
    color: #4b4b4b;
    padding: 10px 15px;
    border-bottom: 1px solid #ccc; }

.page-full {
    clear: both;
    padding: 20px; }
.page-full:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 480px) {
    .page-full {
        padding: 20px 10px; } }
.page-full .breadcrumbs {
    position: relative; }
@media screen and (min-width: 1025px) {
    .page-full .breadcrumbs:before {
        position: absolute;
        content: '';
        top: -20px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #d3d3d3; }
    .page-full .breadcrumbs.no-border:before {
        display: none; } }

.salon-main-area .description {
    font-size: 1.6em;
    color: #333;
    margin-bottom: 20px; }
.salon-main-area .featured-image .image {
    position: relative; }
.salon-main-area .featured-image .popup {
    position: absolute;
    top: 35%;
    right: 0;
    background: white;
    padding: 30px;
    width: 300px; }
@media screen and (max-width: 480px) {
    .salon-main-area .featured-image .popup {
        display: none; } }
.salon-main-area .featured-image .popup .icon {
    float: left;
    margin-right: 15px;
    margin-top: 10px; }
.salon-main-area .featured-image .popup .title {
    float: left;
    width: 190px;
    border-left: 1px solid black;
    padding-left: 15px;
    position: relative;
    color: #282222;
    font-size: 2em;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.salon-main-area .featured-image .popup .title:after {
    content: '';
    position: absolute;
    top: 45%;
    right: -20px;
    background: url("../../public/img/sprites.png");
    width: 12px;
    height: 7px;
    background-position: -26px -293px; }
.salon-main-area .featured-image .popup .title strong {
    font-weight: bold; }
.salon-main-area .featured-image .image-title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.8em;
    color: white;
    background: #252424;
    padding: 10px 20px; }
@media screen and (max-width: 480px) {
    .salon-main-area .featured-image .image-title {
        font-size: 1.4em; } }
.salon-main-area.cuisine-page .description {
    margin-bottom: 0; }

.salon-slider-area {
    padding: 0 12px 40px; }
@media screen and (max-width: 1024px) {
    .salon-slider-area {
        padding: 0 0 40px; } }
.salon-slider-area .description {
    font-size: 2em;
    margin-bottom: 15px; }
@media screen and (max-width: 480px) {
    .salon-slider-area .description {
        font-size: 1.6em; } }
.salon-slider-area .salon-slider-list .slide-wrapper {
    padding: 0 2px; }
.salon-slider-area .salon-slider-list .items {
    margin: 0;
    width: 313px; }
.salon-slider-area .salon-slider-list .items .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2; }
.salon-slider-area .salon-slider-list .items .image:hover .icon {
    background-position: -46px -421px; }
.salon-slider-area .salon-slider-list .items .image:hover .title {
    display: none; }
.salon-slider-area .salon-slider-list .items .image .overlay a {
    font-size: 1.8em; }
.salon-slider-area .flex-prev, .salon-slider-area .flex-next {
    width: 9px;
    height: 20px;
    top: auto;
    bottom: -40px;
    opacity: 1;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
    background: url("../../public/img/sprites.png") no-repeat; }
.salon-slider-area .flex-prev {
    left: 2px;
    background-position: -130px -98px; }
.salon-slider-area .flex-next {
    right: 2px;
    background-position: -79px -179px; }
.salon-slider-area .flex-control-nav {
    left: 0;
    bottom: -40px; }
.salon-slider-area .flex-control-nav a {
    background: black;
    width: 10px;
    height: 10px;
    border: 2px solid black;
    box-shadow: none; }
.salon-slider-area .flex-control-nav a.flex-active {
    background: white; }

.domain-area .description {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 1.7em;
    color: #282222;
    margin-bottom: 20px; }
.domain-area .items {
    padding-top: 20px;
    border-top: 1px solid #e3e3e3; }
.domain-area .items:after {
    content: "";
    display: table;
    clear: both; }
.domain-area .item {
    width: 220px;
    float: left;
    margin: 0 15px 15px 0; }
@media screen and (max-width: 480px) {
    .domain-area .item {
        width: 100%;
        margin: 0 0 15px; } }
.domain-area .item .image-responsive {
    margin-bottom: 10px;
    padding: 10px;
    background: #f4f4f4;
    text-align: center; }
.domain-area .item .title a {
    color: black;
    font-weight: bold;
    font-size: 1.6em; }
.domain-area .item .title a:hover {
    color: #d33b38; }
.domain-area .domain-toggleable {
    display: none; }
.domain-area .domain-toggleable.active {
    display: block; }

.guide-area .content {
    border-top: 1px solid #e3e3e3; }
.guide-area .content:after {
    content: "";
    display: table;
    clear: both; }
.guide-area .main {
    width: 650px;
    float: left;
    color: #333333;
    font-size: 1.4em; }
@media screen and (max-width: 1024px) {
    .guide-area .main {
        width: 500px; } }
@media screen and (max-width: 480px) {
    .guide-area .main {
        width: 100%; } }
.guide-area .main strong {
    color: #d33b38; }
.guide-area .sidebar {
    width: 300px;
    float: right;
    padding: 20px 40px; }
@media screen and (max-width: 1024px) {
    .guide-area .sidebar {
        width: 220px;
        padding: 20px 0; } }
@media screen and (max-width: 480px) {
    .guide-area .sidebar {
        width: 100%; } }
.guide-area .sidebar .domain-area {
    padding-bottom: 40px; }
.guide-area .sidebar .domain-area .items {
    border: none;
    padding: 0; }
.guide-area .sidebar .domain-area .items .item {
    margin: 0; }
.guide-area .sidebar .domain-area .items .image-responsive a, .guide-area .sidebar .domain-area .items .image-responsive img {
    display: inline;
    width: auto;
    max-width: 100%; }
.guide-area .sidebar .flex-prev, .guide-area .sidebar .flex-next {
    width: 9px;
    height: 20px;
    top: auto;
    bottom: -40px;
    opacity: 1;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
    background: url("../../public/img/sprites.png") no-repeat; }
.guide-area .sidebar .flex-prev {
    left: 0;
    background-position: -130px -98px; }
.guide-area .sidebar .flex-next {
    right: 0;
    background-position: -79px -179px; }
.guide-area .sidebar .flex-control-nav {
    left: 0;
    bottom: -40px; }
.guide-area .sidebar .flex-control-nav a {
    background: black;
    width: 10px;
    height: 10px;
    border: 2px solid black;
    box-shadow: none; }
.guide-area .sidebar .flex-control-nav a.flex-active {
    background: white; }

.cuisine-beside-slider {
    width: 220px;
    float: right;
    background: #f4f4f4;
    padding: 8px 0; }
@media screen and (max-width: 1024px) {
    .cuisine-beside-slider {
        width: 310px; } }
@media screen and (max-width: 480px) {
    .cuisine-beside-slider {
        width: 100%; } }
.cuisine-beside-slider .title {
    color: #4b4b4b;
    font-size: 3em;
    font-family: "Grand Hotel", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    text-align: center; }
.cuisine-beside-slider .image-responsive {
    position: relative; }
.cuisine-beside-slider .image-responsive .input {
    padding: 0 10px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px; }
.cuisine-beside-slider .image-responsive input {
    height: 35px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    font-size: 1.4em;
    width: 100%; }
.cuisine-beside-slider .action {
    text-align: center; }
.cuisine-beside-slider .btn-submit {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 1.1em;
    padding: 10px 25px;
    border: none;
    border-radius: 3px; }
.cuisine-beside-slider .btn-submit:hover {
    background: #323232;
    box-shadow: none; }

.recipe-area .result {
    font-size: 1.5em;
    color: #333333;
    margin-bottom: 30px; }
.recipe-area .items {
    margin-bottom: 15px; }
.recipe-area .item {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 15px; }
.recipe-area .item .image-responsive {
    width: 160px;
    float: left; }
@media screen and (max-width: 480px) {
    .recipe-area .item .image-responsive {
        width: 100%; } }
.recipe-area .item .image-responsive a {
    display: block; }
.recipe-area .item .content {
    margin-left: 160px;
    background: #f4f4f4;
    padding: 8px 10px 47px;
    position: relative;
    min-height: 108px; }
@media screen and (max-width: 480px) {
    .recipe-area .item .content {
        width: 100%;
        margin: 0;
        clear: both;
        padding: 10px;
        background: white; } }
.recipe-area .item .content .title {
    font-weight: bold;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    margin-bottom: 5px;
    font-size: 1.1em; }
.recipe-area .item .content .title a {
    color: #282222; }
.recipe-area .item .content .title a:hover {
    color: #d33b38; }
.recipe-area .item .content .name {
    font-weight: 600;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    color: #252424;
    font-size: 1.6em; }
@media screen and (max-width: 480px) {
    .recipe-area .item .content .name {
        margin-bottom: 10px; } }
.recipe-area .item .content .name a {
    color: #252424; }
.recipe-area .item .content .name a:hover {
    color: #d33b38; }
.recipe-area .item .meta {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 10px;
    width: 100%; }
@media screen and (min-width: 481px) {
    .recipe-area .item .meta {
        background: white;
        padding-top: 5px; } }
@media screen and (max-width: 480px) {
    .recipe-area .item .meta {
        position: static;
        padding: 0; } }
.recipe-area .item .meta .rating, .recipe-area .item .meta .people, .recipe-area .item .meta .difficulty {
    color: #808080;
    font-weight: bold;
    font-size: 1.2em;
    float: left;
    margin-right: 20px;
    position: relative; }
@media screen and (min-width: 481px) {
    .recipe-area .item .meta .rating, .recipe-area .item .meta .people, .recipe-area .item .meta .difficulty {
        margin-top: 3px; } }
@media screen and (max-width: 480px) {
    .recipe-area .item .meta .rating, .recipe-area .item .meta .people, .recipe-area .item .meta .difficulty {
        width: 100%;
        margin: 0 0 10px; } }
.recipe-area .item .meta .rating .icon {
    top: 1px; }
.recipe-area .item .meta .people {
    top: -3px; }
.recipe-area .item .meta .people .icon {
    top: 2px; }
.recipe-area .item .meta .difficulty {
    top: -3px; }
@media screen and (max-width: 480px) {
    .recipe-area .item .meta .difficulty {
        top: 0; } }
.recipe-area .item .meta .action {
    float: right;
    position: relative;
    top: -5px;
    padding-right: 2px; }
@media screen and (max-width: 480px) {
    .recipe-area .item .meta .action {
        float: none;
        text-align: center; } }
.recipe-area .item .meta .action a {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 1.1em;
    padding: 5px 15px;
    border-radius: 3px; }
.recipe-area .item .meta .action a:hover {
    background: #323232;
    box-shadow: none; }
.recipe-area .footer {
    clear: both;
    margin-bottom: 10px;
    min-height: 1px; }
.recipe-area .footer a {
    border-radius: 3px;
    border: 1px solid #c5c7c7;
    padding: 8px 12px 8px 40px;
    position: relative;
    color: #282222;
    font-weight: bold;
    font-size: 1.1em;
    margin-right: 5px;
    margin-bottom: 10px; }
@media screen and (max-width: 480px) {
    .recipe-area .footer a {
        padding: 9px 8px 8px 25px;
        font-size: 1.0em;
        margin-right: 0; } }
.recipe-area .footer a:hover {
    color: #d33b38; }
.recipe-area .footer a .icon {
    position: absolute;
    top: 3px;
    left: 12px; }
@media screen and (max-width: 480px) {
    .recipe-area .footer a .icon {
        left: 5px; } }
.recipe-area .footer a .icon-3-bottle-red {
    width: 7px; }

.name-area .item {
    padding: 15px 10px 10px;
    background: #f4f4f4;
    overflow: hidden;
    margin-bottom: 3px; }
@media screen and (max-width: 480px) {
    .name-area .item {
        padding: 18px 10px 5px; } }
.name-area .item .title {
    font-weight: bold;
    font-size: 1.7em;
    margin-right: 10px;
    float: left; }
@media screen and (max-width: 480px) {
    .name-area .item .title {
        margin-bottom: 15px;
        font-size: 1.2em; } }
.name-area .item .title a {
    color: #333333; }
.name-area .item .title a:hover {
    color: #d33b38; }
.name-area .item .sub {
    font-size: 1.3em;
    font-weight: bold;
    color: #666666;
    margin-right: 15px;
    float: left;
    position: relative;
    top: 4px; }
@media screen and (max-width: 480px) {
    .name-area .item .sub {
        font-size: 1.2em;
        line-height: 10px; } }
.name-area .item .sub .icons {
    top: -13px;
    left: 100%;
    position: absolute;
    padding-left: 10px;
    white-space: nowrap; }
.name-area .item .sub .icons .icon {
    margin-left: 5px; }
.name-area .item .action, .name-area .item .offre {
    float: right;
    position: relative;
    top: -3px; }
@media screen and (max-width: 480px) {
    .name-area .item .action {
        display: none; } }
.name-area .item .action a, .name-area .item .offre a {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 1.1em;
    padding: 5px 15px;
    border-radius: 3px; }
.name-area .item .offre a {
    background-color: #b90604;
    background-image: -webkit-linear-gradient(#b90604, #b90604); }
.name-area .item .action a:hover {
    background: #323232;
    box-shadow: none; }

.ingredient-area {
    overflow: hidden;
    border-bottom: 1px solid #d4d4d4; }
.ingredient-area .title {
    color: #aaaaaa;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 15px; }
.ingredient-area ul {
    list-style: none;
    margin: 0;
    padding: 0; }
.ingredient-area .ingredient {
    width: 240px;
    float: left; }
@media screen and (max-width: 480px) {
    .ingredient-area .ingredient {
        width: 100%;
        margin-bottom: 20px; } }
.ingredient-area .ingredient ul {
    background: #f4f4f4;
    padding: 15px;
    padding-bottom: 9999px;
    margin-bottom: -9999px; }
@media screen and (max-width: 480px) {
    .ingredient-area .ingredient ul {
        padding-bottom: 0;
        margin-bottom: 0; } }
.ingredient-area .ingredient li {
    font-weight: bold;
    font-size: 1.4em;
    color: #333333;
    padding-bottom: 5px; }
.ingredient-area .ingredient li strong {
    color: #d33b38; }
.ingredient-area .preparation {
    margin-left: 250px; }
@media screen and (max-width: 480px) {
    .ingredient-area .preparation {
        margin: 0; } }
.ingredient-area .preparation li {
    padding: 0 0 10px 25px;
    position: relative; }
.ingredient-area .preparation li .number {
    font-size: 1.4em;
    font-weight: bold;
    color: white;
    background: #d33b38;
    padding-top: 1px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 20px;
    height: 20px; }
.ingredient-area .preparation li .text {
    font-size: 1.4em;
    color: #333333; }

.tab-area {
    color: #282222; }
.tab-area.fiche-page .nav li {
    width: 25%; }
@media screen and (max-width: 480px) {
    .tab-area.fiche-page .nav li {
        width: 100%; } }
.tab-area.fiche-page .tab-content {
    padding: 0;
    border-top: none; }
.tab-area.fiche-page .tab-content .description {
    padding: 20px;
    font-size: 1.4em; }
@media screen and (max-width: 480px) {
    .tab-area.fiche-page .tab-content .description {
        padding: 20px 0; } }
.tab-area.fiche-page .product-area .bottom {
    margin-bottom: 0; }
.tab-area .nav:after {
    content: "";
    display: table;
    clear: both; }
.tab-area .nav li {
    width: 20%;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    padding: 15px 0;
    background: #f4f4f4;
    border-right: 1px solid #d4d4d4;
    border-top: 1px solid #d4d4d4;
    position: relative;
    cursor: pointer;
    display: block;
    float: left; }
@media screen and (max-width: 480px) {
    .tab-area .nav li {
        width: 100%;
        border-left: 1px solid #d4d4d4; } }
.tab-area .nav li:first-child {
    border-left: 1px solid #d4d4d4; }
.tab-area .nav li:hover {
    color: #d33b38; }
.tab-area .nav li.active {
    background: #d33b38;
    color: white; }
.tab-area .nav li.active:before {
    position: absolute;
    top: -5px;
    left: 0;
    content: '';
    background: #d33b38;
    width: 100%;
    height: 5px; }
.tab-area .nav li.active .icon-user-dark {
    background-position: -44px -515px; }
.tab-area .nav li.long {
    padding: 8px 0px; }
.tab-area .nav li .icon-user-dark {
    position: absolute;
    top: 10px;
    left: 20px; }
.tab-area .nav-phone {
    margin-bottom: 10px;
    width: 100%; }
.tab-area .nav-phone select {
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    background: #eaeaea;
    width: 100%;
    font-weight: bold;
    font-size: 1.5em;
    color: #4b4b4b;
    width: 100%; }
.tab-area .nav-phone select option {
    padding: 5px; }
.tab-area .tab-content {
    border: 1px solid #d4d4d4;
    padding: 20px;
    display: none; }
.tab-area .tab-content:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 1024px) {
    .tab-area .tab-content {
        padding: 20px 10px; } }
@media screen and (max-width: 480px) {
    .tab-area .tab-content {
        padding: 10px 0;
        border: none; } }
.tab-area .tab-content.active {
    display: block; }
.tab-area .tab-content p {
    font-size: 14px; }
.tab-area .tab-content ul {
    padding-left: 18px; }
.tab-area .tab-content .list {
    font-size: 14px; }
.tab-area .tab-content .half {
    float: left; }
.tab-area .tab-content .title {
    font-size: 1.8em;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 20px; }
.tab-area .tab-content .quote {
    font-size: 1.4em;
    color: #333333;
    font-style: italic;
    padding-left: 10px; }
@media screen and (max-width: 480px) {
    .tab-area .tab-content .quote {
        margin-top: 10px;
        padding-left: 0; } }

.product-area .bottom {
    padding: 15px 30px;
    background: #f4f4f4;
    font-weight: bold;
    border-bottom: 1px solid #d4d4d4;
    text-align: center;
    margin-bottom: 30px; }
@media screen and (max-width: 480px) {
    .product-area .bottom {
        padding: 15px; } }
.product-area .bottom a {
    color: #333333; }
.product-area .bottom a:hover {
    color: #d33b38; }
.product-area .bottom .title {
    color: #666666;
    font-size: 1.2em;
    margin-bottom: 5px; }
.product-area .bottom .tags {
    color: #333333;
    font-size: 1.8em; }
.product-area .top {
    background: #f4f4f4;
    border-top: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
    text-align: center;
    font-weight: bold;
    padding: 0; }
.product-area .top:after {
    content: "";
    display: table;
    clear: both; }
.product-area .top .action {
    color: #454545;
    font-weight: bold;
    font-size: 1.2em;
    padding: 10px;
    min-height: 50px; }
@media screen and (max-width: 480px) {
    .product-area .top .action {
        min-height: initial;
        border-bottom: 1px solid #d4d4d4;
        width: 100%; } }
.product-area .top .action:hover {
    color: #d33b38; }
.product-area .top .action .icon {
    margin-right: 5px; }
.product-area .top .half {
    float: left; }
@media screen and (min-width: 481px) {
    .product-area .top .half {
        border-right: 1px solid #d4d4d4; } }
.product-area .top .image {
    width: 70%;
    float: left; }
.product-area .top .image.cuisine-page {
    width: 60%; }
@media screen and (max-width: 480px) {
    .product-area .top .image.cuisine-page {
        width: 100%; } }
@media screen and (max-width: 480px) {
    .product-area .top .image {
        width: 100%; } }
.product-area .top .image img {
    width: 100%;
    display: block; }
.product-area .top .info {
    width: 30%;
    float: right;
    color: #303030; }
@media screen and (max-width: 480px) {
    .product-area .top .info {
        width: 100%; } }
.product-area .top .info a {
    color: #454545; }
.product-area .top .info a:hover {
    color: #d33b38; }
.product-area .top .info .meta {
    padding: 15px;
    border-bottom: 1px solid #d4d4d4;
    color: #454545; }
.product-area .top .info .meta.last {
    border-bottom: none; }
.product-area .top .info .meta a {
    color: #454545; 
    text-decoration: underline; }
.product-area .top .info .meta a:hover {
    color: #d33b38; }
.product-area .top .info .meta .title {
    font-size: 1.8em;
    color: #d33b38;
    margin-bottom: 15px; }
.product-area .top .info .meta .fiche-title {
    font-size: 1.2em;
    color: #666666;
    font-weight: bold;
    margin-bottom: 10px; }
.product-area .top .info .meta .address {
    font-size: 1.4em; }
.product-area .top .info .meta .phone {
    width: 130px;
    background: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    padding: 10px;
    font-size: 1.1em;
    margin: 15px 0;
    display: inline-block;
    cursor: pointer; }
.product-area .top .info .meta .action {
    font-size: 1.2em; }
.product-area .top .info .meta .map {
    margin-top: 20px; }
.product-area .top .info .location {
    padding: 15px; }
.product-area .top .info .location .title {
    color: #666666;
    font-size: 1.2em; }
.product-area .top .info .location .name {
    font-size: 2.2em; }
@media screen and (max-width: 480px) {
    .product-area .top .info .mobile-half {
        border-bottom: 1px solid #d4d4d4;
        display: -webkit-box;
        display: -moz-box;
        display: box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        box-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        align-items: center;
        -ms-flex-align: center; }
    .product-area .top .info .mobile-half:after {
        content: "";
        display: table;
        clear: both; }
    .product-area .top .info .mobile-half .meta {
        width: 50%;
        float: left;
        border-bottom: none; }
    .product-area .top .info .mobile-half .meta:first-child {
        border-right: 1px solid #d4d4d4; } }
.product-area .top .recipe-info {
    width: 40%; }
@media screen and (max-width: 480px) {
    .product-area .top .recipe-info {
        width: 100%; } }
.product-area .top .recipe-info .people, .product-area .top .recipe-info .half, .product-area .top .recipe-info .action {
    padding: 15px 0;
    text-align: center; }
.product-area .top .recipe-info .columns {
    overflow: hidden;
    border-top: 1px solid #d4d4d4; 
    display: grid;
    grid-template-columns: 50% 50%;}
.product-area .top .recipe-info .half {
    float: left;
    width: initial;
    border-bottom: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4; }
.product-area .top .recipe-info .half:nth-child(2n) {
    border-right: none; }
.product-area .top .recipe-info .title {
    color: #666666;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px; }
.product-area .top .recipe-info .number {
    color: #333333;
    font-size: 2em;
    font-weight: bold; }
.product-area .top.appellation-page {
    border-top: none;
    background: white; }
@media screen and (min-width: 481px) {
    .product-area .top.appellation-page .action {
        display: -webkit-box;
        display: -moz-box;
        display: box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        box-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        align-items: center;
        -ms-flex-align: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        box-pack: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        -o-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center; }
    .product-area .top.appellation-page .action a {
        padding-left: 15px;
        position: relative; }
    .product-area .top.appellation-page .action .icon {
        position: absolute;
        top: 4px;
        left: 0; } }
.product-area .top.appellation-page .info .action {
    border-bottom: none; }
.product-area .social {
    margin-bottom: 20px; }
.product-area .social:after {
    content: "";
    display: table;
    clear: both; }
.product-area .social .favorite {
    padding: 3px 10px 0;
    font-weight: bold;
    font-size: 1.1em;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    height: 38px;
    float: left;
    color: #282222; }
.product-area .social .favorite:hover {
    color: #d33b38; }
@media screen and (max-width: 480px) {
    .product-area .social .favorite {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 10px; } }
.product-area .social .favorite .icon {
    top: 5px;
    margin-right: 10px; }
.product-area .social .addthis {
    float: right; }
@media screen and (max-width: 480px) {
    .product-area .social .addthis {
        float: none; } }
.product-area .content {
    border-bottom: 1px solid #d4d4d4;
    color: #333333; }
.product-area .description {
    font-size: 1.4em; }

.calendar-area {
    clear: both; }
.calendar-area:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (max-width: 1024px) {
    .calendar-area {
        padding: 0 20px 20px; } }
@media screen and (max-width: 480px) {
    .calendar-area {
        padding: 0 10px 10px; } }
.calendar-area .items .calendar {
    width: 33.3333%;
    float: left;
    padding: 0 10px; }
@media screen and (max-width: 480px) {
    .calendar-area .items .calendar {
        width: 100%;
        padding: 0; } }
.calendar-area .items .calendar:nth-child(3n) {
    margin-right: 0; }
.calendar-area .items .calendar:nth-child(3n+1) {
    clear: both; }
.calendar-area .items .calendar.active .date {
    background: #d33b38;
    color: white; }
.calendar-area .items .calendar.active .date .number {
    color: white; }

.product-sidebar {
    margin-bottom: 15px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .product-sidebar {
        width: 100%; } }
.product-sidebar .top {
    border: none; }
.product-sidebar .top .info {
    width: 100%; }
.product-sidebar .top .info .link {
    width: 100%;
    text-align: center;
    color: #d33b38;
    font-size: 1.5em; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .product-sidebar .top .info .meta {
        border-bottom: none;
        border-right: 1px solid #d4d4d4;
        width: 50%;
        float: left; } }
.product-sidebar .top .info .meta .phone {
    width: 100%;
    padding: 20px 10px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .product-sidebar .top .info .location {
        width: 50%;
        float: left;
        padding: 20px 40px; }
    .product-sidebar .top .info .location hr {
        border-bottom: none;
        margin: 20px 0; } }

.info-sidebar {
    background: #f4f4f4;
    padding: 15px;
    margin-bottom: 15px; }
.info-sidebar .block-title {
    font-size: 1.5em; }
.info-sidebar .form-title {
    font-size: 1.3em; }
.info-sidebar .form {
    margin-bottom: 0; }
.info-sidebar .form-row .heading {
    width: 135px; }
.info-sidebar .form-row .value {
    margin-left: 130px;
    text-align: right; }
.info-sidebar .form-row .value label {
    margin: 0 0 0 20px; }
.info-sidebar .form-row.date-row {
    margin: 20px 0; }
.info-sidebar .form-row.date-row .value {
    margin: 0;
    clear: both;
    padding-top: 10px;
    text-align: left; }
.info-sidebar .form-row.date-row .value .time {
    text-align: right; }
.info-sidebar .action {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    margin-top: 20px;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 10px 20px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.info-sidebar .action:hover {
    background: #d94247;
    box-shadow: none; }
.info-sidebar .info-toggle {
    display: none; }
.info-sidebar .info-toggle.active {
    display: block; }
.info-sidebar .block-action .action-inner a {
    top: 10px;
    background: transparent; }

.selection-sidebar {
    margin-top: -25px; }
@media screen and (max-width: 1024px) {
    .selection-sidebar {
        width: 100%;
        margin-bottom: 40px; } }
.selection-sidebar .header {
    width: 265px;
    height: 120px;
    margin: auto;
    background: url("../../public/img/img57.png") no-repeat;
    position: relative;
    text-align: center;
    color: white;
    top: 25px;
    z-index: 1; }
.selection-sidebar .header .price, .selection-sidebar .header .sub {
    font-weight: bold;
    position: absolute;
    width: 100%; }
.selection-sidebar .header .price {
    font-size: 2.2em;
    top: 55px; }
.selection-sidebar .header .sub {
    font-size: 1.2em;
    top: 100px; }
.selection-sidebar .selection-items {
    padding: 35px 0 0;
    background: #f4f4f4;
    border: 1px solid #be930e; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .selection-sidebar .selection-items {
        position: relative; }
    .selection-sidebar .selection-items:after {
        content: "";
        display: table;
        clear: both; }
    .selection-sidebar .selection-items .top, .selection-sidebar .selection-items .bottom {
        width: 60%;
        float: left;
        border-right: 1px solid #d4d4d4; }
    .selection-sidebar .selection-items .top {
        margin-bottom: 0;
        padding-bottom: 20px; }
    .selection-sidebar .selection-items .bottom {
        margin-bottom: 20px; }
    .selection-sidebar .selection-items .footer {
        width: 40%;
        position: absolute;
        top: -10px;
        right: 0; }
    .selection-sidebar .selection-items .footer .title {
        border-top: none; } }
.selection-sidebar .footer {
    color: #282222;
    padding: 20px; }
.selection-sidebar .footer .title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    font-size: 1.8em;
    margin-bottom: 15px;
    border-top: 1px solid #d4d4d4;
    padding-top: 25px; }
.selection-sidebar .footer .description {
    font-style: italic;
    font-size: 1.4em; }

.modal-open {
    overflow: hidden; }

.overlay-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 45px;
    z-index: 200;
    background: rgba(0, 0, 0, 0.7);
    overflow-x: hidden;
    overflow-y: auto; }
.overlay-box .inner {
    background: #f4f4f4;
    width: 320px;
    margin: 0 auto 30px;
    padding: 20px;
    position: relative; }
@media screen and (max-width: 480px) {
    .overlay-box .inner,
    .overlay-box .inner.big {
        width: 300px !important; } }
.overlay-box .close {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;}
.overlay-box .title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 1.4em;
    color: #d33b38;
    margin-bottom: 15px; }
.overlay-box form input[type="email"], .overlay-box form input[type="number"], .overlay-box form input[type="password"], .overlay-box form input[type="search"], .overlay-box form input[type="tel"], .overlay-box form input[type="text"], .overlay-box form input[type="url"], .overlay-box form input[type="color"], .overlay-box form input[type="date"], .overlay-box form input[type="datetime"], .overlay-box form input[type="datetime-local"], .overlay-box form input[type="month"], .overlay-box form input[type="time"], .overlay-box form input[type="week"] {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    height: 40px;
    padding: 10px;
    color: #666666;
    font-size: 1.3em; }
.overlay-box form input.zip {
    width: 100px; }
.overlay-box form hr {
    border-color: #dcdcdc;
    margin: 0 0 10px;
    border-bottom: none;
    opacity: .4; }
.overlay-box form .radio {
    font-size: 1.3em;
    margin-right: 15px;
    display: inline-block; }
.overlay-box form .radio input {
    margin-right: 5px;
    position: relative;
    top: 2px; }
.overlay-box form .form-row {
    margin-bottom: 10px;
    position: relative;
    clear: both; }
.overlay-box form .form-row .hint {
    position: absolute;
    top: -45px;
    left: 0;
    width: 100%;
    min-height:40px;
    padding: 5px 15px 5px;
    background: #2c2c2c;
    color: #a4a8b1;
    font-size: 1.3em;
    display: none;
    z-index: 2; }
.overlay-box form .form-row .hint:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 49%;
    height: 0;
    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2c2c2c; }
.overlay-box form .form-row .hint img {
    position: relative;
    top: 2px;
    margin-right: 10px;
    display: inline-block; }
.overlay-box form .form-row.error input {
    border-color: #d33b38; }
.overlay-box form .form-row.error .hint {
    display: block; }
.overlay-box form .footer .submit {
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: bold;
    font-size: 1.3em;
    padding: 10px;
    border: none; }
.overlay-box form .footer .submit:hover {
    background: #323232;
    box-shadow: none; }
.overlay-box form .footer .hint {
    float: right;
    font-size: 1.4em;
    padding-top: 10px; }
.overlay-box form .footer .hint strong {
    font-weight: bold;
    color: #d33b38;
    font-style: italic; }

.overlay-application.inner .item {
    clear: both;
    padding: 10px 20px;
    margin: 0 -20px; }
.overlay-application.inner .item:after {
    content: "";
    display: table;
    clear: both; }
.overlay-application.inner .item:hover {
    background: #dbdbdb; }
.overlay-application.inner .item .image {
    float: left; }
.overlay-application.inner .item .info {
    margin-left: 70px; }
.overlay-application.inner .item .info .name {
    display: inline-block;
    margin-right: 10px; }
.overlay-application.inner .item .info .name a {
    font-size: 1.1em;
    font-weight: bold;
    color: #2c2c2c; }
.overlay-application.inner .item .info .price {
    font-weight: bold;
    font-size: 1.2em;
    color: #d33b38;
    display: inline-block; }
.overlay-application.inner .item .info .store {
    margin-top: 15px; }
.overlay-application.inner .item .info .store a {
    margin-right: 8px; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border-radius: 100%;
    background: white; }

.ui-slider .ui-slider-range {
    background: #4a4a4a; }

.description {
    font-size: 1.6em;
    color: #333;
    margin-bottom: 20px; }
.description.content {
    font-size: 1.4em;
    margin-bottom: 10px; }
.description img {
    max-width: 100%; }
.description .left {
    margin: 0 15px 15px 0; }
.description .right {
    margin: 0 0 15px 15px; }
@media screen and (max-width: 480px) {
    .description .left, .description .right {
        width: 100%;
        margin: 15px 0; } }
.description .full {
    width: 100%; }

.lightbox {
    position: relative; }
.lightbox:hover .overlay {
    display: block; }
.lightbox .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    margin-top: -48px;
    margin-left: -48px; }

.fiche-region-area {
    color: #282222; }
.fiche-region-area .title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    font-size: 1.7em;
    margin-bottom: 10px; }
.fiche-region-area .item {
    margin-bottom: 10px; }
.fiche-region-area .item:after {
    content: "";
    display: table;
    clear: both; }
.fiche-region-area .item .image {
    width: 250px;
    float: left; }
@media screen and (max-width: 480px) {
    .fiche-region-area .item .image {
        width: 100%;
        margin-bottom: 10px; } }
.fiche-region-area .item .image img {
    width: 100%; }
.fiche-region-area .item .info {
    margin-left: 270px; }
@media screen and (max-width: 480px) {
    .fiche-region-area .item .info {
        margin: 0; } }
.fiche-region-area .item .heading {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 1.4em;
    margin-bottom: 5px; }
.fiche-region-area .item ul {
    margin: 0 0 20px;
    padding-left: 15px;
    list-style: none; }
.fiche-region-area .item ul:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (min-width: 481px) {
    .fiche-region-area .item ul.columns li {
        width: 50%;
        float: left; } }
.fiche-region-area .item ul li {
    position: relative; }
.fiche-region-area .item ul li:before {
    content: '\2022';
    position: absolute;
    top: 5px;
    left: -15px; }
.fiche-region-area .item ul li a {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    font-size: 1.4em;
    color: #333333;
    margin: 2px 0; }
.fiche-region-area .item ul li a:hover {
    color: #d33b38; }

.article-filter-area .filter-alphabet {
    border-top: 2px solid #252424;
    width: 100%;
    text-align: center;
    margin-bottom: 10px; }
.article-filter-area .filter-alphabet td {
    width: 4%; }
.article-filter-area .filter-alphabet td a {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    color: #282222;
    font-size: 1.8em;
    padding: 15px 0;
    position: relative;
    width: 100%; }
.article-filter-area .filter-alphabet td a:hover, .article-filter-area .filter-alphabet td a.active {
    background: #dce0e1; }
.article-filter-area .filter-alphabet td a.active:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 0;
    width: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #252424;
    margin-left: -7px; }
.article-filter-area .filter-alphabet td a.disable {
    color: #e2e2e2;
    background: none; }
.article-filter-area .item {
    padding: 10px 0;
    border-bottom: 1px solid #d4d4d4; }
.article-filter-area .item .title {
    margin-bottom: 10px; }
.article-filter-area .item .title img {
    margin-right: 10px; }
.article-filter-area .item .title a {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.8em;
    font-weight: 600;
    color: #252424; }
.article-filter-area .item .title a:hover {
    color: #d33b38; }
.article-filter-area .item .action a {
    font-weight: bold;
    font-style: italic;
    color: black;
    font-size: 1.2em; }
.article-filter-area .item .action a:hover {
    color: #d33b38; }

.hovered {
    display: none; }

.fiche-2-area .main-title {
    margin-bottom: 5px; }
.fiche-2-area .sub-title {
    font-weight: 600;
    font-size: 2.4em;
    margin-bottom: 15px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.fiche-2-area .sub-title-h1 {
    margin-top: 5px;
    font-size: 24px;
    font-family: "Raleway","Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    color: black; }
@media screen and (max-width: 480px) {
    .fiche-2-area .sub-title {
        font-size: 2em; } }
.fiche-2-area .sub-title .small {
    font-size: 18px; }
.fiche-2-area .top {
    margin-bottom: 30px; }
.fiche-2-area .top .location {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px; }
.fiche-2-area .top .location a {
    color: #666666; }
.fiche-2-area .top .location a:hover {
    color: #d33b38; }
.fiche-2-area .top .location .icon {
    top: 2px;
    margin-right: 15px; }
.fiche-2-area .top .image img {
    width: 85px; }
.fiche-2-area .top .image .overlay .icon {
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    -moz-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    -o-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
.fiche-2-area .tags {
    margin-bottom: 10px; }
.fiche-2-area .tags a {
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    padding: 5px 10px;
    background: #afb3bb;
    margin: 0 2px 5px 0; }
.fiche-2-area .tags a.yellow {
    background: #bcb34e; }
.fiche-2-area .tags a.orange {
    background: #ec7658; }
.fiche-2-area .tags a.red {
    background: #981a03; }
.fiche-2-area .tags .icon {
    margin-left: 10px;
    top: 3px; }
.fiche-2-area .meta {
    color: #d33b38;
    font-weight: bold; }
.fiche-2-area .meta:after {
    content: "";
    display: table;
    clear: both; }
.fiche-2-area .meta .hint {
    color: #666666;
    font-size: 13px;
    font-weight: normal; }
@media screen and (max-width: 480px) {
    .fiche-2-area .meta .rating {
        width: 100%; } }
.fiche-2-area .meta .rating .icon {
    margin-left: 5px; }
@media screen and (min-width: 481px) {
    .fiche-2-area .meta .like {
        position: relative;
        top: -5px; } }
@media screen and (max-width: 480px) {
    .fiche-2-area .meta .like {
        width: 100%;
        margin-bottom: 10px; } }
.fiche-2-area .meta .like .icon {
    top: 5px;
    margin-right: 5px; }
@media screen and (min-width: 481px) {
    .fiche-2-area .meta .note {
        position: relative;
        top: 3px; } }
@media screen and (max-width: 480px) {
    .fiche-2-area .meta .note {
        width: 100%;
        margin-bottom: 10px; } }
.fiche-2-area .middle {
    background: #f4f4f4;
    padding: 20px; }
.fiche-2-area .middle .meta {
    margin-bottom: 20px; }
.fiche-2-area .middle .meta .rating {
    font-size: 1.8em;
    margin-right: 30px;
    position: relative; }
@media screen and (min-width: 1025px) {
    .fiche-2-area .middle .meta .hint {
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid #d4d4d4;
        z-index: 1;
        margin-left: 10px;
        position: absolute;
        top: -4px;
        left: 100%;
        white-space: nowrap;
        background: white; }
    .fiche-2-area .middle .meta .hint:before {
        position: absolute;
        content: '';
        top: 6px;
        left: -7px;
        height: 0;
        width: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 7px solid #d4d4d4; }
    .fiche-2-area .middle .meta .hint:after {
        position: absolute;
        content: '';
        top: 7px;
        left: -6px;
        height: 0;
        width: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid white; } }
@media screen and (max-width: 1024px) {
    .fiche-2-area .middle .meta .hint {
        display: inline-block !important;
        position: relative;
        top: -2px; } }
.fiche-2-area .middle .meta .like {
    font-size: 1.2em; }
.fiche-2-area .middle .meta .note {
    font-size: 1.3em;
    color: #666666; }
.fiche-2-area .middle .meta .note a {
    color: #666666; }
.fiche-2-area .middle .meta .note a:hover {
    color: #d33b38; }
.fiche-2-area .middle .meta .note .small {
    font-size: 11px; }
.fiche-2-area .middle .main .left {
    margin: 0 20px 20px 0; }
@media screen and (max-width: 480px) {
    .fiche-2-area .middle .main .left {
        margin: 15px 0; } }
.fiche-2-area .middle .main .right {
    margin: 0 0 20px 20px; }
@media screen and (max-width: 480px) {
    .fiche-2-area .middle .main .right {
        margin: 15px 0; } }
.fiche-2-area .middle .main .social-block {
    padding: 15px;
    background: #dde1ea;
    width: 230px;
    font-weight: bold; }
@media screen and (max-width: 480px) {
    .fiche-2-area .middle .main .social-block {
        width: 100%; } }
.fiche-2-area .middle .main .social-block .btn-main {
    border-radius: 3px;
    color: white;
    background: #d33b38;
    padding: 8px 0;
    text-align: center;
    font-size: 13px;
    width: 100%; }
.fiche-2-area .middle .main .social-block .btn-normal {
    border-radius: 3px;
    color: #282222;
    background: white;
    padding: 8px 0;
    text-align: center;
    font-size: 11px;
    border: 1px solid #d4d4d4;
    margin-top: 10px;
    width: 100%;
    padding-left: 25px;
    position: relative; }
.fiche-2-area .middle .main .social-block .btn-normal .icon {
    position: absolute;
    top: 6px;
    left: 8px; }
.fiche-2-area .middle .main .social-block .addthis .text {
    font-weight: normal;
    font-size: 14px;
    color: #454545;
    margin-bottom: 5px; }
.fiche-2-area .middle .main .title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 2em;
    margin-bottom: 15px; }
@media screen and (max-width: 480px) {
    .fiche-2-area .middle .main .title {
        font-size: 1.8em; } }
.fiche-2-area .middle .main .content {
    font-size: 15px;
    color: #454545; }
.fiche-2-area .posts {
    background: #f4f4f4;
    padding: 20px; }
.fiche-2-area .posts .heading {
    font-size: 1.8em;
    color: #4b4b4b;
    font-weight: bold;
    margin-bottom: 20px; }
.fiche-2-area .posts .title {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 5px; }
.fiche-2-area .posts .title a {
    color: black; }
.fiche-2-area .posts .title a:hover {
    color: #d33b38; }
.fiche-2-area .posts .rating {
    font-size: 1.4em;
    white-space: nowrap;
    margin-right: 10px; }
.fiche-2-area .posts .rating .hint {
    display: inline-block;
    position: relative;
    top: -1px; }
.fiche-2-area .posts .like {
    font-size: 1.1em; }
.fiche-2-area .posts .main {
    margin: 10px 0 20px;
    font-size: 1.4em; }
.fiche-2-area .block-action {
    background: #f4f4f4;
    padding: 0 20px 20px;
    display: block; }
@media screen and (max-width: 480px) {
    .fiche-2-area .block-action {
        padding: 0 5px 20px; } }
.fiche-2-area .block-action a {
    background: #f4f4f4;
    padding-left: 40px; }
.fiche-2-area .block-action a:before {
    position: absolute;
    top: 7px;
    left: 20px;
    content: '';
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black; }
.fiche-2-area .bottom .left {
    width: 100%; }
@media screen and (min-width: 481px) {
    .fiche-2-area .bottom .left {
        padding-right: 10px;
        width: 50%; } }
.fiche-2-area .bottom .right {
    width: 100%; }
@media screen and (min-width: 481px) {
    .fiche-2-area .bottom .right {
        padding-left: 10px;
        width: 50%; } }
.fiche-2-area .bottom table {
    width: 100%;
    font-weight: bold;
    background: #f4f4f4;
    margin-bottom: 10px; }
@media screen and (min-width: 481px) {
    .fiche-2-area .bottom table {
        min-height: 430px; } }
.fiche-2-area .bottom tr {
    border-top: 1px solid #e9e9e9; }
.fiche-2-area .bottom td {
    padding: 20px 0;
    text-align: center; }
.fiche-2-area .bottom td:nth-child(2) {
    border-left: 1px solid #e9e9e9; }
.fiche-2-area .bottom .half {
    width: 50%; }
.fiche-2-area .bottom .heading {
    font-size: 1.8em;
    color: black;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
.fiche-2-area .bottom .sub {
    font-size: 1.4em;
    color: #666666; }
.fiche-2-area .bottom .title {
    font-size: 1.2em;
    color: #666666;
    margin-bottom: 5px; }
.fiche-2-area .bottom .value {
    font-size: 1.4em;
    color: #333333; }
.fiche-2-area .bottom .i {
    margin-bottom: 5px;
    min-height: 35px;
    font-size: 2.4em;
    color: #303030; }
.fiche-2-area .bottom .links {
    padding-left: 30px;
    padding-right: 30px; }
.fiche-2-area .bottom .left-icon {
    position: relative;
    padding-left: 60px;
    display: inline-block; }
.fiche-2-area .bottom .left-icon .icon {
    position: absolute;
    top: 0;
    left: 0; }
.fiche-2-area .bottom .left-icon .title {
    float: left;
    clear: both;
    margin-bottom: 10px; }
.fiche-2-area .bottom .left-icon .value {
    float: left;
    margin-left: 10px; }
.fiche-2-area .bottom a:hover {
    color: #d33b38; }
.fiche-2-area .bottom .action {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 1.1em;
    padding: 10px 15px;
    border-radius: 3px; }
.fiche-2-area .bottom .action:hover {
    background: #323232;
    box-shadow: none;
    color: white; }

.pairing-area .item {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 20px;
    background: #f4f4f4;
    text-align: center;
    float: left; }
.pairing-area .item:nth-child(3n) {
    margin-right: 0; }
.pairing-area .item:nth-child(3n+1) {
    clear: both; }
@media screen and (max-width: 480px) {
    .pairing-area .item {
        width: 100%;
        margin: 0 0 10px; } }
.pairing-area .item .featured-image {
    width: 60px;
    float: left; }
.pairing-area .item .title {
    margin: 12px 0 0 60px;
    padding: 10px;
    font-weight: bold; }
.pairing-area .item .title a {
    font-size: 1.3em;
    color: black; }
.pairing-area .item .title a:hover {
    color: #d33b38; }
.pairing-area .badge {
    margin-top: 10px;
    background: #4d4c4c;
    padding: 15px 20px;
    color: white;
    font-weight: bold;
    font-size: 1.4em;
    text-align: center;
    position: relative; }
@media screen and (min-width: 481px) {
    .pairing-area .badge {
        display: inline-block;
        padding: 15px 50px 15px 20px; }
    .pairing-area .badge:before {
        right: 0;
        border: 25px solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-right-color: white;
        top: 50%;
        margin-top: -25px; } }
@media screen and (max-width: 480px) {
    .pairing-area .badge .title {
        display: block; } }

.detail-area2-map .info .left {
    margin-bottom: 10px;
    height: 180px; }
@media screen and (min-width: 481px) {
    .detail-area2-map .info .left {
        width: 50%; } }
@media screen and (max-width: 480px) {
    .detail-area2-map .info .left {
        width: 100%; } }
.detail-area2-map .info .right {
    margin: 0; }
@media screen and (min-width: 481px) {
    .detail-area2-map .info .right {
        width: 50%;
        padding-left: 20px; } }
.detail-area2-map img {
    max-width: 100%; }

.boutique-area .featured-image:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (min-width: 1025px) {
    .boutique-area .featured-image {
        width: 50%;
        float: left;
        padding-right: 10px; } }
@media screen and (max-width: 1024px) {
    .boutique-area .featured-image {
        margin-bottom: 20px; } }
.boutique-area .featured-image img {
    max-width: 100%;
    max-height: 100%; }
.boutique-area .featured-image .images {
    margin-bottom: 10px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .boutique-area .featured-image .images {
        width: 605px;
        float: left; } }
.boutique-area .featured-image .images .image {
    text-align: center;
    padding: 20px;
    background: #f4f4f4;
    display: none; }
.boutique-area .featured-image .images .image.active {
    display: block; }
.boutique-area .featured-image .thumbs:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .boutique-area .featured-image .thumbs {
        width: 120px;
        float: right; } }
.boutique-area .featured-image .thumbs .item {
    width: 32%;
    margin-right: 2%;
    height: 100px;
    cursor: pointer;
    float: left;
    background: #f4f4f4;
    text-align: center;
    padding: 10px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .boutique-area .featured-image .thumbs .item {
        width: 100%;
        margin: 0 0 5px; } }
.boutique-area .featured-image .thumbs .item:nth-child(3n) {
    margin: 0; }
@media screen and (min-width: 1025px) {
    .boutique-area .info {
        width: 50%;
        float: right;
        padding-left: 10px; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .boutique-area .info .meta {
        width: 500px;
        float: left; } }
.boutique-area .info .description {
    margin-bottom: 20px;
    font-size: 1.6em;
    color: #666666; }
.boutique-area .info .description ul {
    padding-left: 18px; }
.boutique-area .info .description label {
    font-style: italic; }
.boutique-area .info .note {
    margin-bottom: 20px;
    font-weight: bold;
    color: #666666; }
.boutique-area .info .note label {
    font-size: 1.3em;
    margin-right: 10px; }
.boutique-area .info .note .value {
    font-size: 1.1em; }
.boutique-area .info .sell {
    padding: 20px;
    background: #f4f4f4;
    font-weight: bold; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .boutique-area .info .sell {
        width: 220px;
        float: right;
        padding: 10px; } }
.boutique-area .info .sell .item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #c3c3c3; }
.boutique-area .info .sell .item:after {
    content: "";
    display: table;
    clear: both; }
.boutique-area .info .sell .left {
    width: 50%;
    padding-right: 20px;
    border-right: 1px solid #c3c3c3;
    float: left; }
@media screen and (max-width: 1024px) {
    .boutique-area .info .sell .left {
        padding-right: 10px; } }
.boutique-area .info .sell .left .price {
    font-size: 1.6em;
    color: #424242; }
@media screen and (min-width: 1025px) {
    .boutique-area .info .sell .left .price {
        position: relative;
        top: 5px; } }
.boutique-area .info .sell .left .action {
    border-radius: 3px;
    color: white;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    float: right;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 8px 20px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
@media screen and (max-width: 1024px) {
    .boutique-area .info .sell .left .action {
        padding: 8px 5px;
        float: none;
        margin-top: 10px; } }
.boutique-area .info .sell .left .action:hover {
    background: #d94247;
    box-shadow: none; }
.boutique-area .info .sell .right {
    width: 50%;
    padding-left: 20px;
    float: left; }
@media screen and (max-width: 1024px) {
    .boutique-area .info .sell .right {
        padding-left: 10px; } }
.boutique-area .info .sell .right .name {
    float: left; }
@media screen and (max-width: 1024px) {
    .boutique-area .info .sell .right .name {
        float: none; } }
.boutique-area .info .sell .right .icons {
    float: right; }
@media screen and (max-width: 1024px) {
    .boutique-area .info .sell .right .icons {
        float: none; } }
.boutique-area .info .sell .right .stock {
    color: #7dc012;
    font-size: 1.4em; }
.boutique-area .info .sell .right a {
    color: #424242;
    font-size: 1.2em; }
.boutique-area .info .sell .right a:hover {
    color: #d33b38; }
.boutique-area .info .bottom {
    font-size: 1.4em;
    color: #454545;
    font-weight: normal; }
.boutique-area .info .bottom label {
    margin-right: 20px;
    position: relative;
    top: -10px; }
.boutique-area .info .bottom .social {
    display: inline-block; }

.tab-area.boutique-page .nav li {
    width: 230px; }
@media screen and (max-width: 480px) {
    .tab-area.boutique-page .nav li:nth-child(1) {
        width: 195px; }
    .tab-area.boutique-page .nav li:nth-child(2) {
        width: 105px; } }

.selection-items.boutique-page .wrap {
    padding: 0 5px; }
.selection-items.boutique-page .image {
    padding: 15px;
    text-align: center;
    background: #f4f4f4;
    margin-bottom: 20px; }
.selection-items.boutique-page .image img {
    max-width: 100%; }
.selection-items.boutique-page .title {
    margin-bottom: 20px; }
.selection-items.boutique-page .title a {
    color: #252424;
    font-size: 2em; }
.selection-items.boutique-page .title a:hover {
    color: #d33b38; }
.selection-items.boutique-page .bottom {
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 15px; }
.selection-items.boutique-page .bottom .price {
    font-size: 1.6em;
    color: #424242;
    float: left; }
@media screen and (min-width: 1025px) {
    .selection-items.boutique-page .bottom .price {
        position: relative;
        top: 5px; } }
.selection-items.boutique-page .bottom .action {
    padding: 5px 15px;
    float: right; }

.boutique-content:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (min-width: 1025px) {
    .boutique-content {
        width: 630px;
        float: left; } }

.boutique-sidebar {
    background: #f4f4f4;
    padding: 15px; }
.boutique-sidebar:after {
    content: "";
    display: table;
    clear: both; }
@media screen and (min-width: 1025px) {
    .boutique-sidebar {
        width: 270px;
        float: right; } }
@media screen and (max-width: 1024px) {
    .boutique-sidebar {
        margin-top: 20px;
        padding: 10px; } }
@media screen and (max-width: 480px) {
    .boutique-sidebar {
        text-align: center; } }
.boutique-sidebar .action {
    padding: 12px 10px 0 40px;
    font-weight: bold;
    font-size: 11px;
    border-radius: 3px;
    background: white;
    border: 1px solid #d4d4d4;
    height: 38px;
    color: #282222;
    width: 100%;
    margin-bottom: 10px;
    position: relative; }
.boutique-sidebar .action:hover {
    color: #d33b38; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .boutique-sidebar .action {
        width: 210px;
        margin-right: 10px;
        float: left;
        margin-bottom: 0; } }
.boutique-sidebar .action .icon {
    position: absolute;
    top: 10px;
    left: 10px; }
.boutique-sidebar .bottom {
    font-size: 1.4em;
    color: #454545;
    font-weight: normal;
    padding-top: 15px;
    border-top: 1px solid #c3c3c3;
    margin-top: 5px;
    line-height: 10px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .boutique-sidebar .bottom {
        float: left;
        padding: 0 0 0 10px;
        border-top: none;
        border-left: 1px solid #c3c3c3; } }
.boutique-sidebar .bottom label {
    margin-bottom: 10px;
    display: block; }

@media screen and (min-width: 1025px) {
    .favorite-area .boutique-sidebar {
        min-height: 253px;
        margin-bottom: -20px; } }

.recipe-area.boutique-page .item {
    border-bottom: none; }
@media screen and (min-width: 481px) {
    .recipe-area.boutique-page .item {
        border-top: 1px solid #d4d4d4; } }
.recipe-area.boutique-page .item .boutique-content {
    border-bottom: 1px solid #d4d4d4;
    padding-top: 15px; }
@media screen and (max-width: 480px) {
    .recipe-area.boutique-page .item .boutique-content {
        border-top: 1px solid #d4d4d4;
        border-bottom: none; } }

.recipe-area.boutique-page.article .item .boutique-content {
    border-bottom: none; }
.recipe-area.boutique-page.article .item .content {
    background: white;
    padding-bottom: 10px; }
.recipe-area.boutique-page.article .item .content .excerpt {
    font-size: 1.2em;
    color: #666666;
    margin: 5px 0; }
.recipe-area.boutique-page.article .item .content .action {
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic;
    color: #3d3d3d; }
.recipe-area.boutique-page.article .item .content .action:hover {
    color: #d33b38; }

.calendar-area.boutique-page {
    padding: 0; }
.calendar-area.boutique-page .items {
    margin: 0; }
@media screen and (min-width: 481px) {
    .calendar-area.boutique-page .items {
        border-top: 1px solid #d4d4d4; } }
.calendar-area.boutique-page .items .boutique-content {
    padding-top: 15px; }
.calendar-area.boutique-page .items .calendar {
    width: 100%;
    padding: 0; }
.calendar-area.boutique-page .items .calendar .event {
    border-bottom: none; }
.calendar-area.boutique-page .items .title {
    margin-bottom: 0; }
@media screen and (min-width: 1025px) {
    .calendar-area.boutique-page .boutique-sidebar {
        min-height: 185px; } }

@media screen and (max-width: 1024px) {
    .boutique-page .block-action {
        margin-top: 20px; } }
@media screen and (min-width: 481px) {
    .boutique-page .block-action a {
        right: 40%;
        padding-right: 10px; } }

.offer-area ul {
    font-size: inherit; }
.offer-area .tab-content {
    display: none; }
.offer-area .tab-content.active {
    display: block; }
@media screen and (min-width: 1025px) {
    .offer-area .tab-content {
        width: 730px;
        float: left; } }
.offer-area .selection-items .slides li {
    width: 100%; }
.offer-area .selection-items .flex-prev {
    left: 15px; }
.offer-area .selection-items .flex-next {
    right: 15px; }
.offer-area .selection-items .flex-control-nav {
    display: none; }
@media screen and (min-width: 481px) {
    .offer-area .selection-items .left-panel {
        width: 30%; } }
@media screen and (min-width: 481px) {
    .offer-area .selection-items .right-panel {
        width: 60%; } }
.offer-area .selection-items .header {
    width: 185px;
    height: 94px;
    margin: auto;
    background: url("../../public/img/img/img77.png") no-repeat;
    position: relative;
    text-align: center;
    color: white;
    z-index: 1; }
.offer-area .selection-items .header .price, .offer-area .selection-items .header .sub {
    font-weight: bold;
    position: absolute;
    width: 100%; }
.offer-area .selection-items .header .price {
    font-size: 2.2em;
    top: 25px; }
.offer-area .selection-items .header .sub {
    font-size: 1.2em;
    top: 70px; }
.offer-area .selection-items .header.header-phone {
    /* background: url("../../public/img/img83.png") no-repeat; */
    height: 64px; }
.offer-area .selection-items .header.header-phone .price {
    font-size: 1.6em;
    top: 23px;
    left: 8px;
    width: 50px; }
.offer-area .selection-items .header.header-phone .sub {
    font-size: 1.2em;
    top: 26px;
    left: 50px;
    width: 120px; }
@media screen and (max-width: 480px) {
    .offer-area .selection-items .top .left-panel {
        min-height: 100px; } }
@media screen and (min-width: 481px) {
    .offer-area .selection-items .top .right-panel {
        padding-top: 20px; } }
.offer-area .selection-items .bottom .sub {
    display: inline-block;
    margin-right: 15px; }
.offer-area .selection-items .bottom .notice {
    display: inline-block; }
.offer-area .selection-items .bottom .meta .like {
    margin-left: 30px; }
.offer-area .selection-items .bottom .footer {
    padding: 10px 0;
    border-top: 1px solid #d4d4d4; }
@media screen and (max-width: 480px) {
    .offer-area .selection-items .bottom .footer {
        margin-top: 20px; } }
.offer-area .selection-items .bottom .footer .text {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 5px; }
.offer-area .selection-items .bottom .footer .em {
    font-size: 1.4em;
    font-weight: bold;
    font-style: italic; }
@media screen and (min-width: 1025px) {
    .offer-area .nav {
        width: 230px;
        float: right; } }
.offer-area .nav .slides li {
    background: #dde1ea;
    color: #333333;
    cursor: pointer;
    position: relative;
    padding: 15px 20px;
    min-height: 127px; }
.offer-area .nav .slides li.active {
    background: #313131;
    color: white; }
@media screen and (min-width: 1025px) {
    .offer-area .nav .slides li.active:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        height: 0;
        width: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 7px solid #313131;
        margin-top: -7px; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .offer-area .nav .slides li.active:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 100%;
        height: 0;
        width: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #313131;
        margin-left: -7px; } }
@media screen and (max-width: 480px) {
    .offer-area .nav .slides li.active {
        background: white; }
    .offer-area .nav .slides li.active .sub {
        background: #313131;
        position: relative; }
    .offer-area .nav .slides li.active .sub:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 100%;
        height: 0;
        width: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #313131;
        margin-left: -7px; }
    .offer-area .nav .slides li.active .title {
        background: #313131; } }
@media screen and (min-width: 1025px) {
    .offer-area .nav .slides li {
        width: 100%;
        border-bottom: 1px solid white; }
    .offer-area .nav .slides li:last-child {
        border: none; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .offer-area .nav .slides li {
        width: 33.33%;
        border-right: 1px solid white;
        float: left; }
    .offer-area .nav .slides li:last-child {
        border: none; } }
@media screen and (max-width: 480px) {
    .offer-area .nav .slides li {
        padding: 15px 0;
        text-align: center;
        background: white;
        color: white; }
    .offer-area .nav .slides li .sub {
        background: #313131;
        position: relative; }
    .offer-area .nav .slides li .sub:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 100%;
        height: 0;
        width: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #313131;
        margin-left: -7px; }
    .offer-area .nav .slides li .title {
        background: #313131; } }
.offer-area .nav .slides li .sub {
    font-size: 1.4em;
    margin-bottom: 5px; }
@media screen and (max-width: 480px) {
    .offer-area .nav .slides li .sub {
        margin: 0;
        padding: 20px 30px 5px; } }
.offer-area .nav .slides li .title {
    font-size: 1.8em;
    font-weight: bold; }
@media screen and (max-width: 480px) {
    .offer-area .nav .slides li .title {
        padding: 0 30px 10px;
        min-height: 60px; } }
.offer-area .nav .flex-control-nav {
    display: none; }
.offer-area .nav .flex-direction-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1;
    height: 0; }
.offer-area .nav .flex-prev, .offer-area .nav .flex-next {
    width: 9px;
    height: 20px;
    top: 10px;
    opacity: 1;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
    background: url("../../public/img/sprites.png") no-repeat; }
.offer-area .nav .flex-prev {
    left: 15px;
    background-position: -120px -108px; }
.offer-area .nav .flex-next {
    right: 15px;
    background-position: -120px -189px; }
.offer-area .bottom-footer {
    clear: both;
    padding: 15px 20px;
    font-weight: bold;
    font-size: 1.8em;
    color: white;
    background: #313131; }

.club-area .main-title {
    font-size: 2.8em; }
@media screen and (max-width: 1024px) {
    .club-area .main-title {
        font-size: 2.4em; } }
.club-area .sub {
    font-size: 1.8em;
    font-weight: bold;
    color: #d33b38;
    margin-bottom: 20px; }
.club-area .description {
    margin-bottom: 20px;
    padding-left: 50px;
    position: relative; }
.club-area .description .icon {
    position: absolute;
    top: 5px;
    left: 5px; }
.club-area .items {
    margin: 0 -10px; }
.club-area .item-1 .inner {
    background: #f4f4f4 url("../../public/img/img/img78.jpg"); }
.club-area .item-2 .inner {
    background: #f4f4f4 url("../../public/img/img/img79.jpg"); }
.club-area .item-3 .inner {
    background: #f4f4f4 url("../../public/img/img/img80.jpg"); }
.club-area .item-4 .inner {
    background: #f4f4f4 url("../../public/img/img/img81.jpg"); }
.club-area .item {
    padding: 10px;
    width: 50%;
    float: left; }
@media screen and (max-width: 480px) {
    .club-area .item {
        width: 100%; } }
.club-area .item .icons {
    position: relative; }
.club-area .item .icons:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    border-top: 60px solid white;
    border-right: 60px solid transparent; }
.club-area .item .icons .icon {
    margin: 5px 0 0 10px; }
.club-area .item .inner {
    min-height: 200px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    background-position: 260px 0;
    background-repeat: no-repeat; }
@media screen and (max-width: 1024px) {
    .club-area .item .inner {
        background-position: 220px 0; } }
.club-area .item .main-title {
    width: 260px;
    padding: 10px 20px;
    margin: 0;
    cursor: pointer; }
@media screen and (max-width: 1024px) {
    .club-area .item .main-title {
        width: 220px; } }
.club-area .item .description {
    width: 260px;
    padding: 0 20px 10px 20px;
    margin: 0; }
@media screen and (max-width: 1024px) {
    .club-area .item .description {
        width: 220px; } }

.form-default input[type="email"], .form-default input[type="number"], .form-default input[type="password"], .form-default input[type="search"], .form-default input[type="tel"], .form-default input[type="text"], .form-default input[type="url"], .form-default input[type="color"], .form-default input[type="date"], .form-default input[type="datetime"], .form-default input[type="datetime-local"], .form-default input[type="month"], .form-default input[type="time"], .form-default input[type="week"], .form-default textarea {
    height: 35px;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    width: 100%; }
.form-default textarea {
    height: 100px;
    padding: 10px; }
.form-default input[type=submit] {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 1.1em;
    padding: 10px 20px;
    border-radius: 3px;
    border: none; }
.form-default input[type=submit]:hover {
    background: #323232;
    box-shadow: none; }
.form-default .hint {
    color: #666666;
    font-size: 14px; }
.form-default .hint a {
    color: #333333;
    font-size: 12px;
    font-style: italic;
    font-weight: bold; }
.form-default .hint a:hover {
    color: #d33b38; }
.form-default .row {
    clear: both;
    margin-bottom: 10px; }
.form-default .row:after {
    content: "";
    display: table;
    clear: both; }
.form-default .radio {
    color: #aaaaaa;
    font-size: 12px; }
.form-default .radio input {
    position: relative;
    top: 2px;
    margin-right: 5px; }
.form-default .radio label {
    margin-right: 20px; }
.form-default .legend {
    color: #d33b38;
    font-size: 1.1em;
    font-style: italic;
    font-weight: bold; }

.profile-area .block-title {
    padding-bottom: 15px;
    border-bottom: 1px solid #d4d4d4; }
.profile-area .block-title.no-border {
    border: none;
    padding-bottom: 0; }
.profile-area .profile {
    border-top: 1px solid #d4d4d4;
    padding-top: 15px; }
.profile-area .profile .title {
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: normal; }
.profile-area .profile .left-panel {
    width: 100%;
    float: left;
    margin-bottom: 30px; }
@media screen and (min-width: 1025px) {
    .profile-area .profile .left-panel {
        width: 60%;
        padding-right: 30px;
        border-right: 1px solid #d4d4d4; }
    .profile-area .profile .left-panel input[type="email"], .profile-area .profile .left-panel input[type="number"], .profile-area .profile .left-panel input[type="password"], .profile-area .profile .left-panel input[type="search"], .profile-area .profile .left-panel input[type="tel"], .profile-area .profile .left-panel input[type="text"], .profile-area .profile .left-panel input[type="url"], .profile-area .profile .left-panel input[type="color"], .profile-area .profile .left-panel input[type="date"], .profile-area .profile .left-panel input[type="datetime"], .profile-area .profile .left-panel input[type="datetime-local"], .profile-area .profile .left-panel input[type="month"], .profile-area .profile .left-panel input[type="time"], .profile-area .profile .left-panel input[type="week"] {
        max-width: 50%;
        float: left; }
    .profile-area .profile .left-panel .hint {
        width: 50%;
        float: left;
        padding-left: 15px;
        padding-top: 15px; }
    .profile-area .profile .left-panel .date .hint {
        padding-top: 0; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .profile-area .profile .left-panel {
        width: 50%;
        padding-right: 50px;
        border-right: 1px solid #d4d4d4; } }
@media screen and (max-width: 1024px) {
    .profile-area .profile .left-panel .hint {
        text-align: right; } }
@media screen and (max-width: 480px) {
    .profile-area .profile .left-panel {
        border-bottom: 1px solid #d4d4d4;
        padding-bottom: 20px;
        margin-bottom: 20px; } }
.profile-area .profile .left-panel .zip {
    width: 100px; }
.profile-area .profile .right-panel {
    width: 100%;
    float: left;
    margin-bottom: 30px; }
@media screen and (min-width: 1025px) {
    .profile-area .profile .right-panel {
        width: 40%;
        padding-left: 20px; } }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .profile-area .profile .right-panel {
        width: 50%;
        padding-left: 20px; } }
.profile-area .profile .right-panel .action {
    color: #333333;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    margin-top: 10px; }
.profile-area .profile .right-panel .action:hover {
    color: #d33b38; }
.profile-area .profile .right-panel .top {
    background: #f4f4f4;
    padding: 10px; }
.profile-area .profile .right-panel .top:after {
    content: "";
    display: table;
    clear: both; }
.profile-area .profile .right-panel .top .image {
    width: 50%;
    float: left;
    padding-right: 10px;
    text-align: center; }
.profile-area .profile .right-panel .top .image img {
    width: 100%; }
.profile-area .profile .right-panel .top .meta {
    width: 50%;
    float: left;
    padding-left: 10px;
    padding-top: 30px; }
.profile-area .profile .right-panel .top .meta .name {
    font-weight: bold;
    font-size: 1.6em;
    color: #333333; }
.profile-area .profile .right-panel .comment {
    background: #f4f4f4;
    padding: 10px; }
.profile-area .profile .right-panel .comment .text {
    font-size: 1.2em;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: bold;
    color: #666666;
    margin-bottom: 15px; }
.profile-area .profile .right-panel .comment textarea {
    height: 220px; }
.profile-area .profile .right-panel .comment .submit {
    margin-top: 30px; }
.profile-area .profile .submit {
    margin-top: 20px; }
.profile-area .profile .submit .legend {
    display: inline-block;
    margin-left: 20px; }
.profile-area .activity .item {
    margin-bottom: 15px; }
.profile-area .activity .item .name {
    font-size: 1.6em;
    font-weight: bold; }
.profile-area .activity .item .name a {
    color: #333333; }
.profile-area .activity .item .name a:hover {
    color: #d33b38; }
.profile-area .activity .item .date {
    color: #d33b38;
    font-size: 1.3em;
    font-weight: bold; }
.profile-area .activity .item .text {
    color: #333333;
    font-size: 1.5em; }
.profile-area .activity .item .text a {
    color: #d33b38; }
.profile-area .activity .has-image:after {
    content: "";
    display: table;
    clear: both; }
.profile-area .activity .has-image .image {
    width: 80px;
    float: left; }
.profile-area .activity .has-image .image img {
    width: 100%; }
.profile-area .activity .has-image .content {
    margin-left: 100px; }
.profile-area .newsletter:after {
    content: "";
    display: table;
    clear: both; }
.profile-area .newsletter .image {
    width: 150px;
    float: left;
    margin: 0 15px 15px 0; }
.profile-area .newsletter .image img {
    width: 100%; }
.profile-area .newsletter .text {
    font-size: 1.6em;
    color: #333333;
    margin-bottom: 10px; }
.profile-area .newsletter .price {
    font-size: 1.3em;
    color: #282222;
    font-weight: bold; }
.profile-area .newsletter .action {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 1.1em;
    padding: 10px 20px;
    border-radius: 3px;
    border: none;
    margin-right: 20px;
    margin-bottom: 10px; }
.profile-area .newsletter .action:hover {
    background: #323232;
    box-shadow: none; }
.profile-area .newsletter .action.disabled {
    color: #adadad;
    background: #efefef; }
.profile-area .newsletter .hint .text {
    margin: 0; }
.profile-area .newsletter .hint a {
    color: #333333;
    font-size: 12px;
    font-style: italic;
    font-weight: bold; }
.profile-area .newsletter .hint a:hover {
    color: #d33b38; }
@media screen and (min-width: 481px) {
    .profile-area .newsletter.top .hint {
        display: inline-block;
        position: relative;
        top: 10px; } }
.profile-area .filter .block-title {
    margin-bottom: 40px; }
.profile-area .filter .row {
    padding: 15px 0; }
.profile-area .filter .row:after {
    content: "";
    display: table;
    clear: both; }
.profile-area .filter .row .label {
    width: 150px;
    float: left;
    font-weight: bold;
    font-size: 1.5em;
    color: #747474; }
.profile-area .filter .row .value {
    margin-left: 150px;
    padding: 0; }
.profile-area .filter .row .value:after {
    content: "";
    display: table;
    clear: both; }
.profile-area .filter .row .value .item {
    color: #454545; }
.profile-area .filter .row .value .item.has-icon {
    position: relative; }
@media screen and (max-width: 480px) {
    .profile-area .filter .row .value .item.has-icon {
        margin-bottom: 30px; }
    .profile-area .filter .row .value .item.has-icon:last-child {
        margin-bottom: 0; } }
.profile-area .filter .row .value .item.has-icon input {
    margin-right: 35px; }
.profile-area .filter .row .value .item.has-icon .icon {
    position: absolute;
    left: 20px;
    bottom: 2px; }
.profile-area .filter .checkbox .value .item {
    width: 25%;
    float: left;
    font-size: 1.4em; }
@media screen and (max-width: 480px) {
    .profile-area .filter .checkbox .value .item {
        width: 100%;
        margin-bottom: 10px; } }
.profile-area .filter .select .value .item {
    width: 30%;
    float: left;
    position: relative;
    margin-right: 15px;
    top: -15px; }
@media screen and (max-width: 480px) {
    .profile-area .filter .select .value .item {
        width: 100%;
        margin: 0 0 10px; } }
.profile-area .filter .select .current {
    color: #4b4b4b;
    position: relative;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    background: white;
    border: 1px solid #cccccc;
    font-size: 1.5em;
    font-weight: bold; }
.profile-area .filter .select .current:after {
    content: '';
    width: 15px;
    height: 10px;
    background: url("../../public/img/sprites.png") -22px -141px no-repeat;
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 1;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }
.profile-area .filter .select .current.active:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
.profile-area .filter .select .search-popup {
    list-style: none;
    margin: 0;
    padding: 0;
    background: white;
    border: 1px solid #d4d4d4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2; }
.profile-area .filter .select .search-popup li:last-child a {
    border: none; }
.profile-area .filter .select .search-popup a {
    color: #333333;
    font-size: 13px;
    padding: 10px;
    border-bottom: 1px solid #ededed;
    width: 100%; }

.cave-area .main-title {
    font-size: 2.8em; }
@media screen and (max-width: 1024px) {
    .cave-area .main-title {
        font-size: 2.4em; } }
.cave-area .sub {
    font-size: 1.8em;
    font-weight: bold;
    color: #d33b38;
    margin-bottom: 20px; }
.cave-area .description {
    margin-bottom: 20px;
    padding-left: 50px;
    position: relative; }
.cave-area .description .icon {
    position: absolute;
    top: 5px;
    left: 5px; }
.cave-area .description a {
    font-size: 12px;
    color: #282222;
    font-weight: bold;
    font-style: italic; }
.cave-area .description a:hover {
    color: #d33b38; }
@media screen and (min-width: 1025px) {
    .cave-area .add .left-panel {
        width: 58%;
        float: left; } }
@media screen and (max-width: 1024px) {
    .cave-area .add .left-panel {
        margin-bottom: 20px; } }
@media screen and (min-width: 1025px) {
    .cave-area .add .right-panel {
        width: 40%;
        float: right; } }
.cave-area .add .form {
    position: relative;
    padding: 15px 145px 15px 15px;
    background: #f4f4f4; }
.cave-area .add .form input[type=text] {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    color: #454545;
    font-size: 1.4em;
    border: none; }
.cave-area .add .form input[type=submit] {
    font-size: 1.3em;
    font-weight: bold;
    color: white;
    padding: 7px 20px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 15px;
    right: 15px; }
.cave-area .add .form input[type=submit]:hover {
    background: #323232;
    box-shadow: none; }
.cave-area .summary .items {
    background: #f4f4f4; }
.cave-area .summary .item {
    width: 25%;
    float: left;
    border-top: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    text-align: center;
    padding: 15px; }
.cave-area .summary .item:last-child {
    border-right: 0; }
@media screen and (max-width: 480px) {
    .cave-area .summary .item {
        width: 50%;
        padding: 15px 20px; }
    .cave-area .summary .item:nth-child(2n) {
        border-right: none; }
    .cave-area .summary .item:nth-child(2n+1) {
        clear: both; } }
.cave-area .summary .item .name {
    font-size: 1.2em;
    font-weight: bold;
    color: #666666;
    margin-bottom: 5px; }
@media screen and (max-width: 1024px) {
    .cave-area .summary .item .name {
        font-size: 1em; } }
.cave-area .summary .item .icon {
    margin-bottom: 5px; }
.cave-area .summary .item .value {
    font-size: 1.5em;
    font-weight: bold;
    color: #333333; }
.cave-area .dashboard .form {
    position: relative;
    padding: 15px 120px 15px 15px;
    background: #f4f4f4;
    margin-bottom: 20px; }
@media screen and (min-width: 481px) {
    .cave-area .dashboard .form {
        width: 350px; } }
.cave-area .dashboard .form input[type=text] {
    width: 100%;
    height: 28px;
    padding: 5px 10px;
    color: #454545;
    font-size: 1.4em;
    border: none; }
.cave-area .dashboard .form input[type=submit] {
    font-size: 1.2em;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 15px;
    right: 15px; }
.cave-area .dashboard .form input[type=submit]:hover {
    background: #323232;
    box-shadow: none; }
.cave-area .dashboard .filter {
    margin-bottom: 10px; }
.cave-area .dashboard .filter .label {
    font-size: 1.3em;
    font-weight: bold;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    color: #282222;
    width: 90px;
    float: left;
    margin-bottom: 10px;
    position: relative;
    top: 6px; }
@media screen and (max-width: 480px) {
    .cave-area .dashboard .filter .label {
        top: 0; } }
.cave-area .dashboard .filter .value {
    margin-left: 90px; }
@media screen and (max-width: 480px) {
    .cave-area .dashboard .filter .value {
        clear: both;
        margin: 0; } }
.cave-area .dashboard .filter .item {
    width: 140px;
    float: left;
    margin-right: 2px;
    margin-bottom: 10px;
    position: relative; }
.cave-area .dashboard .filter .item:last-child {
    margin-right: 0; }
.cave-area .dashboard .filter .search-area {
    padding: 0; }
.cave-area .dashboard .filter .current {
    color: #4b4b4b;
    position: relative;
    width: 100%;
    padding: 5px 15px 5px 5px;
    border-radius: 3px;
    background: white;
    border: 1px solid #cccccc;
    font-size: 1.2em;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
.cave-area .dashboard .filter .current:after {
    content: '';
    width: 15px;
    height: 10px;
    background: url("../../public/img/sprites.png") -22px -141px no-repeat;
    position: absolute;
    top: 8px;
    right: 2px;
    z-index: 1;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
    -moz-transform: scale3d(0.7, 0.7, 0.7);
    -ms-transform: scale3d(0.7, 0.7, 0.7);
    -o-transform: scale3d(0.7, 0.7, 0.7);
    transform: scale3d(0.7, 0.7, 0.7); }
.cave-area .dashboard .filter .current.active:after {
    -webkit-transform: rotate(180deg) scale3d(0.7, 0.7, 0.7);
    -moz-transform: rotate(180deg) scale3d(0.7, 0.7, 0.7);
    -ms-transform: rotate(180deg) scale3d(0.7, 0.7, 0.7);
    -o-transform: rotate(180deg) scale3d(0.7, 0.7, 0.7);
    transform: rotate(180deg) scale3d(0.7, 0.7, 0.7); }
.cave-area .dashboard .filter .search-popup {
    list-style: none;
    margin: 0;
    padding: 0;
    background: white;
    border: 1px solid #d4d4d4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2; }
.cave-area .dashboard .filter .search-popup li:last-child a {
    border: none; }
.cave-area .dashboard .filter .search-popup a {
    color: #333333;
    font-size: 12px;
    padding: 10px 5px;
    border-bottom: 1px solid #ededed;
    width: 100%; }
.cave-area .dashboard .tags {
    padding: 10px 15px 5px;
    background: #dde1ea;
    margin-bottom: 20px; }
.cave-area .dashboard .tags .label {
    font-size: 1.3em;
    font-weight: bold;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    color: #282222;
    margin-right: 5px;
    float: left;
    margin-bottom: 10px;
    position: relative;
    top: 5px; }
@media screen and (max-width: 480px) {
    .cave-area .dashboard .tags .label {
        top: 0; } }
@media screen and (max-width: 480px) {
    .cave-area .dashboard .tags .value {
        clear: both; } }
.cave-area .dashboard .tags .item {
    padding: 3px 25px 3px 5px;
    background: white;
    margin: 0 5px 5px 0;
    float: left;
    position: relative; }
.cave-area .dashboard .tags .item .text {
    font-size: 1.3em;
    color: #282222; }
.cave-area .dashboard .tags .item .action {
    cursor: pointer;
    position: absolute;
    top: 1px;
    right: 5px;
    font-size: 1.4em;
    font-weight: bold;
    width:20px;
    height:20px;
    margin-right: -10px;}
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table thead {
        float: left;
        width: 110px; }
    .cave-area .dashboard .table thead th {
        display: block;
        height: 70px; }
    .cave-area .dashboard .table tbody {
        float: left;
        width: 595px;
        text-align: center; }
    .cave-area .dashboard .table tbody tr {
        display: block;
        width: 25%;
        float: left; }
    .cave-area .dashboard .table tbody tr:nth-child(5), .cave-area .dashboard .table tbody tr:nth-child(6) {
        display: none; }
    .cave-area .dashboard .table tbody td {
        display: block;
        height: 70px; } }
@media screen and (max-width: 480px) {
    .cave-area .dashboard .table tbody {
        width: 190px; }
    .cave-area .dashboard .table tbody tr {
        width: 50%; }
    .cave-area .dashboard .table tbody tr:nth-child(3), .cave-area .dashboard .table tbody tr:nth-child(4) {
        display: none; } }
.cave-area .dashboard .table table {
    width: 100%; }
.cave-area .dashboard .table th {
    padding: 15px 5px 20px;
    position: relative;
    color: white;
    font-size: 1.1em;
    font-weight: bold;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    background: #4d4c4c;
    text-align: center; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table th {
        padding: 25px 10px 0;
        text-align: left; } }
.cave-area .dashboard .table th .sort {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table th .sort {
        bottom: 45px;
        left: initial;
        right: 10px;
        width: 10px; } }
.cave-area .dashboard .table th .sort-down {
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid white;
    display: inline-block;
    cursor: pointer; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table th .sort-down {
        position: absolute;
        top: 10px; } }
.cave-area .dashboard .table th .sort-up {
    height: 0;
    width: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid white;
    display: inline-block;
    cursor: pointer; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table th .sort-up {
        position: absolute;
        top: 0; } }
.cave-area .dashboard .table td {
    padding: 5px;
    font-size: 1.2em; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table td {
        padding: 10px 5px 0; } }
.cave-area .dashboard .table tbody {
    border-left: 1px solid #e7e7e7; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table tbody {
        border-left: none;
        border-top: 1px solid #e7e7e7; } }
.cave-area .dashboard .table tbody tr {
    border-bottom: 1px solid #e7e7e7; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table tbody tr {
        border-bottom: none; } }
.cave-area .dashboard .table tbody tr:nth-child(2n) {
    background: #f4f4f4; }
.cave-area .dashboard .table tbody tr:hover {
    background: #dde1ea; }
.cave-area .dashboard .table tbody td {
    border-right: 1px solid #e7e7e7;
    color: #333333; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table tbody td {
        border-right: none;
        border-bottom: 1px solid #e7e7e7; } }
.cave-area .dashboard .table tbody a {
    color: #282222;
    font-weight: bold; }
.cave-area .dashboard .table tbody a:hover {
    color: #d33b38; }
.cave-area .dashboard .table tbody .status {
    position: relative; }
.cave-area .dashboard .table tbody .status .popup {
    position: absolute;
    left: 100%;
    top: 50%;
    background: white;
    padding: 5px;
    font-size: 13px;
    color: #282222;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    white-space: nowrap;
    margin-top: -15px;
    z-index: 1; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table tbody .status .popup {
        left: 60%;
        top: 10px;
        margin: 0; } }
.cave-area .dashboard .table tbody .status .popup:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -5px;
    height: 0;
    width: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #d4d4d4;
    margin-top: -5px; }
.cave-area .dashboard .table tbody .status .popup:after {
    content: '';
    position: absolute;
    top: 50%;
    left: -4px;
    height: 0;
    width: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid white;
    margin-top: -5px; }
.cave-area .dashboard .table tbody .year, .cave-area .dashboard .table tbody .color, .cave-area .dashboard .table tbody .guide, .cave-area .dashboard .table tbody .vote, .cave-area .dashboard .table tbody .like, .cave-area .dashboard .table tbody .price, .cave-area .dashboard .table tbody .stock, .cave-area .dashboard .table tbody .guard {
    text-align: center; }
.cave-area .dashboard .table tbody .color {
    font-size: 1em; }
.cave-area .dashboard .table tbody .color .icon {
    -webkit-transform: scale3d(0.6, 0.6, 0.6);
    -moz-transform: scale3d(0.6, 0.6, 0.6);
    -ms-transform: scale3d(0.6, 0.6, 0.6);
    -o-transform: scale3d(0.6, 0.6, 0.6);
    transform: scale3d(0.6, 0.6, 0.6);
    margin-bottom: -5px; }
.cave-area .dashboard .table tbody .guide {
    font-size: 1.1em;
    font-weight: bold;
    color: #d33b38; }
.cave-area .dashboard .table tbody .price {
    font-size: 1.4em; }
.cave-area .dashboard .table tbody .stock {
    font-size: 1.4em; }
.cave-area .dashboard .table tbody .stock .empty {
    display: inline-block;
    padding-top: 3px;
    background: #d33b38;
    color: white;
    border-radius: 100%;
    font-size: 11px;
    width: 20px;
    height: 20px; }
.cave-area .dashboard .table tbody .guard {
    font-size: 1.2em;
    line-height: 10px; }
.cave-area .dashboard .table .pagination {
    border: none;
    padding-top: 20px; }
@media screen and (max-width: 1024px) {
    .cave-area .dashboard .table .pagination {
        text-align: right; } }
.cave-area .dashboard .table .pagination ul {
    padding: 0; }
.cave-area .dashboard .table .pagination .action {
    float: left; }
@media screen and (max-width: 480px) {
    .cave-area .dashboard .table .pagination .action {
        padding: 0 10px;
        margin-top: 10px;
        float: none; } }
.cave-area .dashboard .table .pagination .action a {
    font-size: 1.1em;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.cave-area .dashboard .table .pagination .action a:hover {
    background: #323232;
    box-shadow: none; }
@media screen and (max-width: 480px) {
    .cave-area .dashboard .table .pagination .action a {
        width: 100%;
        text-align: center;
        font-size: 1.6em; } }
.cave-area .heading {
    margin-bottom: 25px; }
.cave-area .heading .social {
    margin-bottom: 20px; }
@media screen and (min-width: 1025px) {
    .cave-area .heading .social {
        float: right; } }
.cave-area .heading .info {
    margin-bottom: 20px; }
@media screen and (min-width: 1025px) {
    .cave-area .heading .info {
        float: left;
        width: 700px; } }
.cave-area .heading .info .image {
    width: 75px;
    text-align: center;
    float: left;
    font-size: 1.3em;
    color: #999999; }
.cave-area .heading .info .meta {
    margin-left: 75px; }
.cave-area .heading .info .meta .main-title {
    margin-bottom: 0; }
.cave-area .heading .info .meta .year, .cave-area .heading .info .meta .type {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600; }
.cave-area .heading .info .meta .year {
    font-size: 2.4em; }
@media screen and (max-width: 480px) {
    .cave-area .heading .info .meta .year {
        font-size: 2em; } }
.cave-area .heading .info .meta .type {
    font-size: 1.8em; }
.cave-area .heading .info .meta .region {
    font-size: 1.4em;
    font-weight: bold;
    margin-top: 15px; }
.cave-area .heading .info .meta .region .icon {
    top: 2px;
    margin-right: 5px; }
.cave-area .heading .info .meta .region a {
    color: #666666; }
.cave-area .heading .info .meta .region a:hover {
    color: #d33b38; }
.cave-area .note-content {
    background: #f4f4f4;
    padding: 20px; }
.cave-area .note-content .meta {
    margin-bottom: 20px;
    color: #d33b38;
    font-weight: bold; }
.cave-area .note-content .meta .hint {
    color: #666666;
    font-size: 13px;
    font-weight: normal; }
@media screen and (max-width: 480px) {
    .cave-area .note-content .meta .rating {
        width: 100%; } }
.cave-area .note-content .meta .rating .icon {
    margin-left: 5px; }
@media screen and (min-width: 481px) {
    .cave-area .note-content .meta .like {
        position: relative;
        top: -5px; } }
@media screen and (max-width: 480px) {
    .cave-area .note-content .meta .like {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0; } }
.cave-area .note-content .meta .like .icon {
    top: 5px;
    margin-right: 5px; }
@media screen and (min-width: 481px) {
    .cave-area .note-content .meta .note {
        position: relative;
        top: 3px; } }
@media screen and (max-width: 480px) {
    .cave-area .note-content .meta .note {
        width: 100%;
        margin-bottom: 10px; } }
.cave-area .note-content .meta .rating {
    font-size: 1.8em;
    margin-right: 30px;
    position: relative; }
.cave-area .note-content .meta .hint {
    position: absolute;
    top: -4px;
    left: 100%;
    white-space: nowrap;
    background: white;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    z-index: 1;
    margin-left: 10px; }
.cave-area .note-content .meta .hint:before {
    position: absolute;
    content: '';
    top: 6px;
    left: -7px;
    height: 0;
    width: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #d4d4d4; }
.cave-area .note-content .meta .hint:after {
    position: absolute;
    content: '';
    top: 7px;
    left: -6px;
    height: 0;
    width: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white; }
@media screen and (max-width: 480px) {
    .cave-area .note-content .meta .hint {
        display: none !important; } }
.cave-area .note-content .meta .like {
    font-size: 1.2em;
    margin-right: 50px; }
.cave-area .note-content .meta .note {
    font-size: 1.3em;
    color: #666666; }
.cave-area .note-content .meta .note a {
    color: #666666; }
.cave-area .note-content .meta .note a:hover {
    color: #d33b38; }
.cave-area .note-content .meta .note .small {
    font-size: 11px; }
.cave-area .note-content .content {
    font-size: 1.5em;
    color: #454545; }
.cave-area .note-content .content p {
    font-size: 15px; }
@media screen and (min-width: 1025px) {
    .cave-area .comment-area .comment-form {
        float: left;
        width: 640px; } }
@media screen and (min-width: 1025px) {
    .cave-area .comment-area .description {
        float: right;
        width: 260px; } }
.cave-area .comment-area .footer {
    margin-top: 20px; }
@media screen and (min-width: 481px) {
    .cave-area .comment-area .footer input {
        width: 280px;
        margin-left: 15px; } }
.cave-area .detail-form .block-title {
    margin-bottom: 15px;
    border-bottom: none;
    padding: 0; }
.cave-area .detail-form .left-panel, .cave-area .detail-form .right-panel {
    margin-bottom: 30px; }
@media screen and (min-width: 1025px) {
    .cave-area .detail-form .left-panel, .cave-area .detail-form .right-panel {
        float: left;
        width: 50%; } }
@media screen and (min-width: 1025px) {
    .cave-area .detail-form .left-panel {
        padding-right: 20px;
        border-right: 1px solid #f1f1f1; } }
@media screen and (min-width: 1025px) {
    .cave-area .detail-form .right-panel {
        padding-left: 20px; } }
.cave-area .detail-form .row {
    padding: 2px 0; }
.cave-area .detail-form .row .label {
    padding-top: 10px; }
@media screen and (max-width: 480px) {
    .cave-area .detail-form .row .label {
        width: 100%;
        float: none;
        padding: 0; } }
@media screen and (max-width: 480px) {
    .cave-area .detail-form .row .value {
        width: 100%;
        margin: 5px 0 0; } }
.cave-area .detail-form .row .value input[type="email"], .cave-area .detail-form .row .value input[type="number"], .cave-area .detail-form .row .value input[type="password"], .cave-area .detail-form .row .value input[type="search"], .cave-area .detail-form .row .value input[type="tel"], .cave-area .detail-form .row .value input[type="text"], .cave-area .detail-form .row .value input[type="url"], .cave-area .detail-form .row .value input[type="color"], .cave-area .detail-form .row .value input[type="date"], .cave-area .detail-form .row .value input[type="datetime"], .cave-area .detail-form .row .value input[type="datetime-local"], .cave-area .detail-form .row .value input[type="month"], .cave-area .detail-form .row .value input[type="time"], .cave-area .detail-form .row .value input[type="week"] {
    width: 150px; }
.cave-area .detail-form .row .value input.date {
    width: 50px;
    margin-right: 10px; }
.cave-area .detail-form .row .value input.year {
    width: 80px; }
.cave-area .detail-form .row .value input.full, .cave-area .detail-form .row .value .full {
    width: 100%; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .cave-area .detail-form .row .value input.full, .cave-area .detail-form .row .value .full {
        width: 280px; } }
.cave-area .detail-form .row .value .item {
    width: 100%;
    top: 0; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .cave-area .detail-form .row .value .item {
        width: 280px; } }
.cave-area .detail-form .empty {
    display: inline-block;
    padding-top: 3px;
    background: #d33b38;
    color: white;
    border-radius: 100%;
    font-size: 11px;
    width: 20px;
    height: 20px;
    text-align: center; }
.cave-area .detail-form .checkbox .value {
    position: relative;
    padding-top: 10px; }
@media screen and (max-width: 480px) {
    .cave-area .detail-form .checkbox .value {
        padding: 0; } }
.cave-area .detail-form .checkbox .value input {
    top: 15px;
    left: 0;
    position: absolute; }
@media screen and (max-width: 480px) {
    .cave-area .detail-form .checkbox .value input {
        top: 5px; } }
.cave-area .detail-form .checkbox .value .hint {
    padding-left: 35px; }
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .cave-area .detail-form .checkbox .value .hint {
        width: 280px; } }
.cave-area .detail-form .radio .value {
    padding-top: 10px; }
.cave-area .detail-form .radio label {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
    color: #282222; }
.cave-area .detail-form .hint .half {
    float: left;
    width: 50%; }
.cave-area .detail-form .submit {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.cave-area .detail-form .submit:hover {
    background: #d94247;
    box-shadow: none; }
.cave-area .detail-form .submit.small {
    padding: 5px;
    font-size: 11px; }
.cave-area .detail-form .action {
    color: white;
    font-weight: bold;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 12px;
    padding: 10px 20px;
    border-radius: 3px;
    border: none;
    margin-right: 20px;
    margin-bottom: 10px; }
.cave-area .detail-form .action:hover {
    background: #323232;
    box-shadow: none; }
.cave-area .detail-form .action.disabled {
    color: #adadad;
    background: #efefef; }
.cave-area .detail-form .footer {
    text-align: right;
    padding-top: 20px;
    border-top: 1px solid #f1f1f1; }

.favorite-area.boutique-page .items .item {
    border-top: 1px solid #d4d4d4;
    padding-top: 0; }
.favorite-area.boutique-page .boutique-content {
    padding-top: 15px; }

.toggleable-single {
    display: none; }
.toggleable-single.active {
    display: block; }

.btn-black {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 11px;
    padding: 10px 25px;
    border: none;
    border-radius: 3px;
    position: relative;
    top: -4px; }
.btn-black:hover {
    background: #323232;
    box-shadow: none;
    color: white; }

.profile-btn-submit {
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, #1d1d1d);
    background-image: linear-gradient(#323232, #1d1d1d);
    font-size: 11px;
    padding: 6px 15px;
    border: none;
    border-radius: 3px;
    position: relative;
    top: -4px;
    margin-left: 10px; }
.profile-btn-submit:hover {
    background: #323232;
    box-shadow: none;
    color: white; }

/*# sourceMappingURL=style.css.map */

/** ANDAFTER **/
.show                    { display:block; }
.hide                    { display:none !important; }
.w40                    { width:40%; }
.w47                    { width:47%; }
.w50                    { width:50% !important; }
.w25                    { width:25%; }
.w30                    { width:30%; }
.w33                    { width:33%; }
.w80                    { width:80%; }
.w85                    { width:85%; }
.w100                    { width: 100% !important; }
.mw100                    { max-width: 100%; }
.hackie6                { margin-right:-3px; }
.lh18                    { line-height:18px; }
.mt80                    { margin-top:80px; }
.mt-55                    { margin-top:-55px; }
.mr1                    { margin-right:1px; }
.mr5                    { margin-right:5px; }
.mr10                    { margin-right:10px; }
.mr30                    { margin-right:30px; }
.mr40                    { margin-right:40px; }
.mr80                    { margin-right:80px; }
.mb15                    { margin-bottom:15px; }
.mb10                    { margin-bottom:10px; }
.mb20                    { margin-bottom:30px; } /* modif */
.mb30                    { margin-bottom:30px; }
.mb40                    { margin-bottom:40px; }
.mb70                    { margin-bottom:70px; }
.mb5                    { margin-bottom:5px; }
.ml1                    { margin-left:1px; }
.ml10                    { margin-left:10px; }
.ml15                    { margin-left:15px; }
.ml20                    { margin-left:20px; }
.ml30                    { margin-left:30px; }
.ml40                    { margin-left:40px; }
.ml210                    { margin-left:210px; }
.p0                        { padding: 0 !important; }
.pb5                    { padding-bottom:5px; }
.pb15                    { padding-bottom:15px; }
.pt2                    { padding-top:2px; }
.pl25                    { padding-left:25px !important; }
.pl15                    { padding-left:15px !important; }
.pad20                    { padding: 20px; }
.padLR20                { padding: 0 20px; }
.pr50                    { padding-right:50px !important; }
.mt14                    { margin-top:14px !important;margin-top:0; }
.mt15                    { margin-top:15px !important;margin-top:0; }
.mt40                    { margin-top:40px !important;margin-top:0; }
.ml15                    { margin-left:15px !important;margin-right:0; }
.ml25                    { margin-left:25px !important;margin-right:0; }
.mr10                    { margin-right:10px !important;margin-right:0; }
.mr20                    { margin-right:20px !important;margin-right:0; }
.mr70                    { margin-right:70px !important;margin-right:0; }
.mt3                    { margin-top:3px !important;margin-top:0; }
.mt5                    { margin-top:5px !important;margin-top:0; }
.mt10                    { margin-top:10px !important;margin-top:0; }
.mt17                    { margin-top:17px !important;margin-top:0; }
.pt25                    { padding-top:25px !important;padding-top:25px; }
.mt25                    { margin-top:25px !important;margin-top:0; }
.mt30                    { margin-top:30px !important;margin-top:0; }
.mt40                    { margin-top:40px !important;margin-top:0; }
.mt3_real                { margin-top:3px !important;margin-top:3px }
.mt10_pc                { margin-top:10% !important;margin-top:0 }
.mt5_real                { margin-top:5px !important;margin-top:3px }
.mt6_real                { margin-top:6px !important;margin-top:6px }
.mt7_real                { margin-top:7px !important;margin-top:7px }
.mt8_real                { margin-top:8px !important;margin-top:8px }
.mt10_real                { margin-top:10px; }
.mt15_real                { margin-top:15px; }
.mt20_real                { margin-top:20px; }
.mt24_real                { margin-top:24px; }
.mt40_real                { margin-top:40px; }
.mt4_real                { margin-top:4px; }
.mt5_real                { margin-top:5px; }
.m0                        { margin: 0 !important; }
.textunderline            { text-decoration: underline; }
.textlower                { text-transform: lowercase; }
.textupper                { text-transform: uppercase; }
.textalignleft            { text-align: left; }
.textaligncenter        { text-align: center; }
.textalignright            { text-align: right; }
.valigntop                { vertical-align: top; }
.valignmiddle            { vertical-align: middle; }
.valignbottom            { vertical-align: bottom; }
.fs8                    { font-size: 8px; }
.fs11                    { font-size: 11px; }
.fs12                    { font-size: 12px; }
.fs14                    { font-size: 14px; }
.fs15                    { font-size: 15px; }
.fs16                    { font-size: 16px; }
.fs18                    { font-size: 18px; }
.absolute                { position: absolute; }
.absolute.left            { left: 0; }
.absolute.top            { top: 0; }
.absolute.bottom        { bottom: 0; }
.relative                { position: relative; }
.inline-block            { display: inline-block; }
.showPhone,
.showTablet{
    display: none;
}
.showDesktop{
    display: block;
}
.useCheckbox,
.useRadio {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    min-height: 22px;
    line-height: 22px;
}
.useCheckbox.notLH,
.useRadio.notLH {
    line-height: normal;
}
.useCheckbox input[type="checkbox"],
.useRadio input[type="radio"] {
    font: initial;
    position: absolute;
    z-index: 1;
    left: 0;
}
.useCheckbox label,
.useRadio label {
    cursor: pointer;
}
.useCheckbox i.icon,
.useRadio i.icon {
    position: absolute;
    left: 0;
    display: none;
    width: 23px;
    height: 22px;
}
.useCheckbox i.icon {
    background: url('../../public/img/form/checkbox.png') left top no-repeat;
    background-size:100%;
}
.useRadio i.icon {
    background: url('../../public/img/form/radio.png') left top no-repeat;
    background-size:100%;
}
.useCheckbox.active i.icon,
.useRadio.active i.icon {
    background-position: left bottom;
}
.useCheckbox.top i.icon,
.useRadio.top i.icon,
.useCheckbox.top input[type="checkbox"],
.useRadio.top input[type="radio"] {
    top: 0;
}
.useCheckbox.top input[type="checkbox"],
.useRadio.top input[type="radio"] {
    top: 3px;
}
/*.useRadio.top i.con-cb{
    top: 15px;
}*/
.useCheckbox.middle i.icon,
.useRadio.middle i.icon,
.useCheckbox.middle input[type="checkbox"],
.useRadio.middle input[type="radio"] {
    top: 50%;
    margin: -11px 0 0 0;
}
.useCheckbox.bottom i.icon,
.useRadio.bottom i.icon,
.useCheckbox.bottom input[type="checkbox"],
.useRadio.bottom input[type="radio"] {
    bottom: 0;
}
.useRadio.big {
    padding: 0;
}
.useRadio.big label {
    position: relative;
    display: block;
    padding: 20px;
    padding-left: 40px;
    background: #f4f4f4;
}
.useRadio.big label i.icon,
.useCheckbox.big input[type="checkbox"],
.useRadio.big input[type="radio"] {
    left: 10px;
}
.useRadio.big.top label i.icon,
.useCheckbox.big input[type="checkbox"],
.useRadio.big input[type="radio"] {
    top: 30px;
}
.red {
    color: #dc4145;
}
.green {
    color: #7dc012;
}
.black {
    color: #000000;
}
.grey {
    color: #666666;
    font-weight: bold;
}
.overlay-box .inner.big {
    width: 640px;
}
.bkg-grey {
    padding: 20px;
    background: #f4f4f4;
}
.ligne_partenaire {
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #c3c3c3;
}
.ligne_partenaire ul {
    width: 100%;
    display: table;
    margin: 0;
    padding: 0;
    list-style: none;
}
.ligne_partenaire ul li {
    display: table-cell;
    vertical-align: middle;
    width: 25%;
    height: 62px;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    padding: 0 2%;
    border-right: 1px solid #c3c3c3;
}
.ligne_partenaire ul li:last-child {
    padding-right: 0;
    border: none;
}
.ligne_partenaire a {
    color: #000;
}
.ligne_partenaire .addToBasket {
    border-radius: 3px;
    color: #FFF;
    font-weight: bold;
    font-size: 1.1em;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247, #d6150c);
    background-image: linear-gradient(#d94247, #d6150c);
    padding: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.ligne_partenaire .addToBasket:hover {
    background: #d94247;
    box-shadow: none;
}
label {
    color: #666;
    font-size: 1.3em;
}
textarea {
    min-height: 100px;
}
textarea[required] + label,
input[required] + label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
}
textarea[required] + label {
    height: 40px;
}

textarea[required] + label:after,
input[required] + label:after {
    content:'*';
    color: red;
}

/* show the placeholder when input has no content (no content = invalid) */
textarea[required]:invalid + label,
input[required]:invalid + label {
    display: inline-block;
}

/* hide the placeholder when input has some text typed in */
/*textarea[required]:valid + label,
input[required]:valid + label{
    display: none;
}*/
@media screen and (max-width: 1024px) {
    .showPhone,
    .showDesktop{
        display: none;
    }
    .showTablet{
        display: block;
    }
}
@media screen and (max-width: 480px) {
    .showTablet,
    .showDesktop{
        display: none;
    }
    .showPhone{
        display: block;
    }
    label {
        font-size: 1.2em;
    }
    .ligne_partenaire ul {
        display: block;
    }
    .ligne_partenaire ul li {
        display: block;
        width: 100%;
        height: auto;
        padding: 0;
        border: none;
    }
    .w30 {
        width: 29%;
    }
}

#contact-form .search-area {
    margin-top: -20px;}

@media screen and (max-width: 480px) {
    body {
        padding-top: 0px;
        background: none !important;
    }
    #contact-form .search-area {margin-top: 0px;}
    .article-area .nav .share .icon {
        top: 4px;
        margin-right: 3px; }
    .article-area .nav .favorite .icon {
        top: 5px;
        margin-right: 0px; }
    .article-area .nav .favorite {
        padding: 3px 10px 0;
        padding-left: 2px;
        padding-right: 2px;
        margin-right: 3px; }
    .article-area .nav .share {
        padding: 5px 10px 0;
        padding-left: 2px;
        padding-right: 2px;}
    .article-area .nav {
        min-width: 307px; }
}

.article-area .content .action {
    font-size: 11px;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid black;
    background-color: #323232;
    background-image: -webkit-linear-gradient(#323232, black);
    background-image: linear-gradient(#323232, black);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
.article-area .content .action:hover {
    background: #323232;
    box-shadow: none; }
@media screen and (max-width: 480px) {
    .article-area .content .action {
        width: 100%;
        text-align: center; } }

.main-sidebar .block-ventesPrivees {
    background: url('../../public/img/img/fond-vp-rb.jpg') top center;
    height: 469px;
    width: 305px;
    padding-top:70px;
}
.main-sidebar .block-ventesPrivees .titre-vp {
    position: absolute;
    top: -55px;
    left:40px;
    text-align:center;
    z-index:24;

}
.main-sidebar .block-ventesPrivees h2 {
    font-size: 18px;
    color:#ffffff;
    text-transform:uppercase;
}
.main-sidebar .block-ventesPrivees h3 {
    font-size: 13px;
    color:#ffffff;
}
.main-sidebar .block-ventesPrivees .selection-items .right-panel {
    width:165px;
}
.main-sidebar .block-ventesPrivees .selection-items .top .title a {
    font-size: 17px;
}
.main-sidebar .block-ventesPrivees .selection-items .flex-prev {
    top:35%;
    left: 10px;
    background-position: -246px 0px;
}
.main-sidebar .block-ventesPrivees .selection-items .flex-next {
    top:35%;
    right: 11px;
    background-position: -231px 0px;
}
.main-sidebar .block-ventesPrivees .selection-items .logo-classement {
    width:90px;
    height:50px;
    /*background-image:url('../../public/img/sprites.png');
    background-position: -165px 354px;*/
}
.main-sidebar .block-ventesPrivees .selection-items .image {
    margin-top:25px;
}
.main-sidebar .block-ventesPrivees .selection-items .notice, .main-sidebar .block-ventesPrivees .selection-items .flex-control-nav  {
    display:none;
}
.main-sidebar .block-ventesPrivees .selection-items .price {
    float:left;
    width: 85px;
    font-size: 23px;
    line-height:38px;
}
.main-sidebar .block-ventesPrivees .selection-items .contenance {
    float:left;
    width: 70px;
    font-size: 15px;
    line-height:38px;
}
.result-area .main-content .suggestion {
    font-size: 14px;
}

.result-area .main-content .suggestion-item  {
    font-size: 14px;
}

/* promo */

.selection-items .bottom .price .promo ,
.main-footer .discover .items .item .info .price .promo ,
.favorite-area .right-panel .footer .price .promo ,
.theme-area .items .footer .price .promo ,
.result-area .results .footer .price .promo ,
.overlay-application.inner .item .info .price .promo ,
.selection-sidebar .header .price .promo ,
.offer-area .selection-items .header .price .promo ,
.offer-area .selection-items .header.header-phone .price .promo ,
.cave-area .dashboard .table tbody .price .promo {
    color:#d33b38;
    font-size: 1.2em;
}

/* formulaire de recherche placemarket */

.search-area .search-criteria .items {
    width: 535px;
    float: left; }

.search-area .search-criteria .placemarket .action {
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 1.3em;
    border: 1px solid #b91e22;
    background-color: #d94247;
    background-image: -webkit-linear-gradient(#d94247,#d6150c);
    background-image: linear-gradient(#d94247,#d6150c);
    padding: 10px;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}
.search-area .search-criteria .placemarket .action:hover {
    background: #d94247;
    box-shadow: none;
}
.search-area .search-criteria .placemarket .chapeau {
    color: #4b4b4b;
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 10px;
    width: 195px;
}

.search-area .search-criteria .submit .action a {
    color:#4b4b4b;
    font-size:1.3em;
    font-weight: bold;
    font-style: italic;
    padding-top: 13px;
    width: 195px;
}

.search-area .search-criteria .submit {
    width: 190px;
    padding-top: 25px;
    text-align: center;
    float: left;
    margin-right: 16px; }

.search-area .search-criteria .placemarket {
    width: 190px;
    float: left;
    padding-top: 10px;
    padding-left: 10px;
    text-align: center;
    border-left: 1px solid #4b4b4b
}

@media screen and (max-width: 1024px) {
    .search-area .search-criteria .items {
        width: 380px; }

    .search-area .search-criteria .submit {
        width: 130px;
        margin-right: 0px;
        padding-top: 10px; }

    .search-area .search-criteria .placemarket {
        padding-left: 0px;
    }

    .search-area .search-criteria .submit .action a {
        width: auto;
    }
}


@media screen and (max-width: 480px) {
    .search-area .search-criteria .items {
        width: 100%;
        padding-top: 10px; }

    .search-area .search-criteria .submit {
        width: 100%;
        padding-top: 0;
        margin-bottom: 10px; }

    .search-area .search-criteria .placemarket {
        width: auto;
        padding-left: 0px;
        border-left: none;
        float: none;
    }
    .search-area .search-criteria .placemarket .chapeau {
        width: auto;
    }

    .search-area .search-criteria .submit .action a {
        width: auto;
    }
}

.main-header .menu-bar .main-menu a .nouveau {
    color:#da2e32;
    font-size:11px;
}

.main-footer .nav .title a .nouveau {
    color:#da2e32;
    font-size:11px;
}
/* fin - formulaire de recherche placemarket */

.main-sidebar .block-social a {
    color: #fff;
    background-color: black;
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    line-height: 64px;
    margin-right: 20px;
    border-radius: 30px;
}

.main-sidebar .block-social a i {
    font-size: 26px;
}

.main-sidebar .block-social{
    text-align: center;
}

@media screen and (max-width: 769px){
    .main-sidebar {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width:1024px){
    header .menulhg .minimal-menu ul li.megamenu.tout-vin .menu-wrapper{
        width: 90%;
    }
}

@media screen and (min-width:1025px){
    header .menulhg .minimal-menu ul li.megamenu.tout-vin .menu-wrapper{
        width: 37%;
    }
}

@media screen and (max-width:480px){
    .left-mobile {
        float: left;
    }
}
@media screen and (min-width:1024px){
    /*.vinResult .block .oder.type-2, .panels .block .oder.type-3 {
        padding: 0 15px;
    }*/
    .vinResult .block .oder.type-2 .price, .panels .block .oder.type-3 .price{
        width: 50%;
        display: inline-block;
        font-size: 14px;
        /*float: left;*/
        /*font-size: 14px !important;*/
        /*padding-top: 15px;*/
        overflow: hidden;
    }
    .vinResult .block .oder.type-2 .achat, .panels .block .oder.type-3 .achat{
        /*width: 50%;*/
        display: inline-block;
    }
    /* .vinResult .block .oder.type-2 .images{
        padding-top: 15px;
    } */
    .vinResult .block .type-3 .achat{
        /* padding-top: 10px;
        padding-bottom: 10px; */
    }
    .vinResult .block {
        height: 225px;
    }
}
@media screen and (min-width:768px) and (max-width:1023px){
    .vinResult .block .oder.type-2, .panels .block .oder.type-3 {
        padding: 0 10px;
    }
    .vinResult .block .oder.type-2 .price, .panels .block .oder.type-3 .price{
        display: inline-block;
        /* font-size: 14px !important; */
        overflow: hidden;
        /*padding-top: 3px;*/
    }
    .vinResult .block .oder.type-2 .achat, .panels .block .oder.type-3 .achat{
        display: inline-block;
    }
    /*.vinResult .block .oder.type-2 .images, .panels .block .oder.type-3 .images{
        padding-top: 5px;
    }*/
    /*.vinResult .block .type-3 .achat, .panels .block .type-2 .achat{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }*/
    .vinResult .block {
        height: 225px;
    }
    .vinResult .block .oder.type-2 .achat a, .vinResult .block .oder.type-3 .achat a{
        font-size:9px !important;
    }
}
@media screen and (min-width: 1024px){
    /*.vinResult .block .oder.type-2 .achat-block, .panels .block .oder.type-3 .achat-block{
        min-height: 102px;
    }*/
    .vinResult .block .oder.type-2 .achat a, .vinResult .block .oder.type-3 .achat a{
        /*font-size:10px !important;*/
        margin-top: 2px;
        min-width: 60px;
    }
    .vinResult .block .oder.type-2 .price, .panels .block .oder.type-3 .price {
        /*padding-top: 5px;*/
    }
}

/*.fiche .logo-btle a {
    width: 69%;
    display: inline-block !important;
    text-align: center;
}

.fiche .logo-btle .voir-offre {
    width: 29%;
    display: inline-block !important;
    vertical-align: top;
}

.fiche .logo-btle img{
    display: block;
    margin: auto !important;
}

.fiche .logo-btle .contenant{
    font-size: 13px;
    font-weight: bold;
    vertical-align: middle;
}

.logo-btle {
    padding-bottom: 15px;
}

.fiche .partenaires-cavistes{
    padding-bottom: 0px;
}

.partenaires-cavistes.block-2 .contenant, .partenaires-cavistes.block-3 .contenant{
    font-size: 18px;
    font-family: 'Raleway',sans-serif!important;
    font-weight: bold;
    vertical-align: bottom;
}

.partenaires-cavistes .partner__body {
    text-align: center;
}

.partenaires-cavistes .partner__body .button__white {
    width: auto;
}
*/
.bloc-right-panel {
	display: flex;
	margin-left: 45px;
}
@media screen and (max-width: 1024px){
    .bloc-right-panel {
        flex-direction: column;
    }
}
.col-btn {
	height: 100%;
	padding: 20px;
}

.meta-custom {
	display: flex;
	justify-content: space-between;
}
.spinner {
    position: absolute;
	width: 0px;
	z-index: 2000000000;
	left: 50%;
	top: 50%;
}